import { Box } from "@mui/material";
import { FaMapMarkerAlt } from "react-icons/fa";
import { Card, CardBody, CardHeader, CardText, CardTitle, Col, Row } from "reactstrap";
import DrZhangimg from "../FieldOffices/NRMEO/DRZhang1.jpg";
import NRMEOimg from "../FieldOffices/NRMEO/NRMEO.png";

export function NRMEO() {
  return (
    <Box
      sx={{
        mt: 3,
        mx: {
          xs: 2, // For mobile
          sm: 5, // For tablet
          md: 25, // For small desktop
          lg: 40, // For large desktop
        },
        display: 'flex',
        flexDirection: 'column',
        alightItems: 'center',
        TextAlign: 'center',
      }}
    >
      <br />

      <div>
        <h1 className="text-center"> Northern Regional Medical Examiner's Office</h1>
        <hr />
      </div>

      <div className="text-center">
        <img src={NRMEOimg} alt="NRMEO Building" style={{ maxWidth: '100%', height: 'auto' }} />
      </div>
      <br />

      <p>
        At the Northern Regional Medical Examiner's Offices, the men and women first on the scene from our staff are the
        members of the Medicolegal Death Investigations Section. The Medicolegal Death Investigators at the Northern
        RMEO work closely with local, county and state law enforcement officials. While at death scenes, the Medicolegal
        Death Investigator attempts to identify the recently deceased, examine their medical records and photograph both
        the scene and the deceased. The conclusions and findings of the Medicolegal Death Investigators are reviewed and
        shared with one of our Medical Examiners. It is then determined whether a case will be brought in for an
        external examination or complete autopsy.
        <br />
        <br />
        The Medicolegal Death Investigations Section of the Northern RMEO office responds to the counties of Essex,
        Hudson, Passaic, & Somerset. It operates 24 hours a day, 365 days per year. Our current staff of Medicolegal
        Death Investigators bring with them unique backgrounds and areas of expertise. Some our investigators'
        backgrounds include specialities in the areas of forensic anthropology, emergency medical services, mortuary
        services and nursing. Many of our investigators are also registered members with The American Board of
        Medicolegal Death Investigators (ABMDI).
      </p>

      <hr />
      <div>
        <Card className="faded-blue">
          <CardBody>
            <CardTitle className="text-center" tag="h4">
              <b>Guidelines for Reporting a Death to the Medical Examiner's Office:</b>
            </CardTitle>

            <CardText>
              <hr />
              <ul>
                <li>All Deaths occurring outside of a hospital or medical facility.</li>
                <li>
                  All Deaths occurring in a hospital or nursing home within 24 hours of entry to the facility and/or if
                  the death falls under any of the other reportable death categories.
                </li>
                <li>
                  Deaths by violent means, whether apparently homicidal, suicidal or accidental including thermal,
                  chemical, electrical and radiological, even if the deceased was hospitalized for more than 24 hours.
                </li>
                <li>Deaths determined to be suspicious or under unusual circumstances.</li>
                <li>
                  Patients who sustain a fall prior to or during hospitalization, subsequently resulting in death.
                </li>
                <li>Deaths that occur within 24 hours of a surgical or diagnostic procedure.</li>
                <li>Deaths of prison inmates/residents of a State or County institution.</li>
                <li>Deaths in police custody.</li>
                <li>Deaths that might constitute a threat to public health.</li>
                <li>Deaths related to disease resulting from employment or accidents at work.</li>
                <li>Deaths of infants and children that are sudden or unexpected.</li>
                <li>Fetal deaths occurring without medical attendance.</li>
                <li>All children under the age of 3.</li>
              </ul>
            </CardText>
          </CardBody>
          <CardTitle className="text-left" tag="h6">
            <b> &nbsp; &nbsp; &nbsp; Non-Reportable Deaths:</b>
          </CardTitle>

          <CardText>
            &nbsp; &nbsp; &nbsp; Deaths of patients that are terminally ill and are under the care of a hospice program
            - Unless they also meet any of the above requirements for reportable deaths.
          </CardText>
          <br />
        </Card>
        <br />
      </div>

      <Row>
        <Col>
          <Card className="text-center" style={{ width: "100%", margin: "auto", height: "100%" }}>
            <CardHeader className="dark-blue">Medical Examiner</CardHeader>
            <CardBody className="faded-blue">
              <img src={DrZhangimg} style={{ width: "80%", margin: "auto", height: "80%" }} alt="NRMEO Building" />

              <p>
                <b>Alex Zhang, M.D.</b> <br />
                Deputy Chief Medical Examiner
              </p>
            </CardBody>
          </Card>
        </Col>

        <Col>
          <Card className="text-center" style={{ width: "100%", margin: "auto", height: "100%" }}>
            <CardHeader className="dark-blue">Quick Links</CardHeader>
            <CardBody className="faded-blue">
              <a
                href="https://www.bing.com/maps/directions?rtp=adr.~pos.40.577430725097656_-74.3624496459961_08820%2c+Edison%2c+New+Jersey_08820%2c+Edison%2c+New+Jersey_~pos.40.7381208_-74.1878035_325+Norfolk+St%2c+Newark%2c+NJ+07103_325+Norfolk+St%2c+Newark%2c+NJ+07103_"
                target="_blank"
              >
                {" "}
                <FaMapMarkerAlt />
                Visit NRMEO
              </a>

              <br />

              {/*
                                <Link to="/AutopsyReportRequestForm"><FaMapMarkerAlt/>Request an Autopsy Report</Link>
                        
                                */}
            </CardBody>
          </Card>
        </Col>

        <Col>
          <Card className="text-center" style={{ width: "100%", margin: "auto", height: "100%" }}>
            <CardHeader className="dark-blue">Contact Us</CardHeader>
            <CardBody className="faded-blue">
              <p>
                862-350-5000 <br />
              </p>
              <br />
              <p>
                <u>24-hour line:</u> <br />
                (973) 648-4500 <br />
              </p>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Box>
  );
}

import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "top",
    },

    labels: {
      position: "top",
    },
    title: {
      display: false,
      text: "NJ 2015 Confirmed Drug Involved Overdose Deaths",
    },
  },
};

const labels = [
  "Cocaine",
  "Methadone",
  "Oxycodone",
  "Morphine",
  "Fentanyl",
  "Heroin",
];

const data = {
  labels,
  datasets: [
    {
      label: "2015",
      data: [414, 103, 302, 57, 417, 918],
      backgroundColor: "#01579B",
    },
    /*
        {
            label: 'Morphine',
            data: [43],
            backgroundColor: '#0288D1',
        },
        {
            label: 'Cocaine',
            data: [414],
            backgroundColor: '#03A9F4',
        },
        {
            label: 'Fentanyl',
            data: [417],
            backgroundColor: '#4FC3F7',
        },
        {
            label: 'Oxycodone',
            data: [302],
            backgroundColor: '#B3E5FC',
        },
        {
            label: 'Methadone',
            data: [103],
            backgroundColor: '#BBDEFB',
        },
        
        */
  ],
};

const StateDrugTotal2015 = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return (
      <div>
        <br />
        <p>Please wait, Reading Data...</p>
        <hr />
      </div>
    );
  }

  return (
    <div>
      <Bar options={options} data={data} />
    </div>
  );
};

export default StateDrugTotal2015;

import { Box } from "@mui/material";
import { Card, CardBody, CardHeader, CardText, CardTitle } from "reactstrap";
import { HomeCarousel } from "../components/HomeCarousel";

export function Home() {
  return (
    <Box>
      <HomeCarousel />
      <br />
      <Box
        sx={{
          mx: {
            xs: 2, // For mobile
            sm: 5, // For tablet
            md: 25, // For small desktop
            lg: 40, // For large desktop
          },
        }}
      >
        <i>
          The mission of the Office of the Chief State Medical Examiner (OCSME) is to oversee the medical examiner
          system in the State through the creation and enforcement of operating and performance standards for every
          medical examiner office in New Jersey. This will ensure the highest level of service possible in the
          investigation and documentation of suspicious, sudden, and unexpected deaths. The service provided shall be
          timely, compassionate, and based on unbiased scientific opinions while respecting cultural and religious
          traditions.
        </i>
        <br /> <br />
        <div>
          <Card className="faded-blue">
            <CardBody>
              <CardTitle className="text-center" tag="h4">
                <b>Guidelines for Reporting a Death to the Medical Examiner's Office:</b>
              </CardTitle>

              <CardText>
                <hr />
                <ul>
                  <li>All Deaths occurring outside of a hospital or medical facility.</li>
                  <li>
                    All Deaths occurring in a hospital or nursing home within 24 hours of entry to the facility and/or
                    if the death falls under any of the other reportable death categories.
                  </li>
                  <li>
                    Deaths by violent means, whether apparently homicidal, suicidal or accidental including thermal,
                    chemical, electrical and radiological, even if the deceased was hospitalized for more than 24 hours.
                  </li>
                  <li>Deaths determined to be suspicious or under unusual circumstances.</li>
                  <li>
                    Patients who sustain a fall prior to or during hospitalization, subsequently resulting in death.
                  </li>
                  <li>Deaths that occur within 24 hours of a surgical or diagnostic procedure.</li>
                  <li>Deaths of prison inmates/residents of a State or County institution.</li>
                  <li>Deaths in police custody.</li>
                  <li>Deaths that might constitute a threat to public health.</li>
                  <li>Deaths related to disease resulting from employment or accidents at work.</li>
                  <li>Deaths of infants and children that are sudden or unexpected.</li>
                  <li>Fetal deaths occurring without medical attendance.</li>
                  <li>All children under the age of 3.</li>
                </ul>
              </CardText>
            </CardBody>
            <CardTitle className="text-left" tag="h6">
              <b> &nbsp; &nbsp; &nbsp; Non-Reportable Deaths:</b>
            </CardTitle>

            <CardText>
              &nbsp; &nbsp; &nbsp; Deaths of patients that are terminally ill and are under the care of a hospice
              program - Unless they also meet any of the above requirements for reportable deaths.
            </CardText>
            <br />
          </Card>
          <br />
        </div>
        <div className="align-center">
          <Card className="mx-auto" style={{ width: "28rem" }}>
            <CardHeader className="dark-blue text-center">Need Support Now?</CardHeader>
            <CardBody className="faded-blue">
              <CardText className="text-center faded-blue">
                If you are having thoughts of suicide... <br /> <br />
                If you need mental health-related crisis support... <br /> <br />
                If you are worried about someone else... <br /> <br />
                Please call or text <b>988</b>
                <br />
                or visit the{" "}
                <a href="https://988lifeline.org/" target="_blank">
                  National Suicide Prevention Lifeline
                </a>{" "}
                chat to connect with a trained crisis counselor
              </CardText>
            </CardBody>
          </Card>
        </div>
      </Box>
    </Box>
  );
}

import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
} from "chart.js";

import { Line } from "react-chartjs-2";

const chartData = {
  labels: ["2015", "2016", "2017", "2018", "2019"],
  datasets: [
    {
      label: "Cocaine",
      data: [414, 637, 890, 1045, 963],
      fill: true,
      backgroundColor: "rgb(93,138,168,1",
      borderColor: "rgb(93,138,168,1)",
    },
    {
      label: "Methadone",
      data: [103, 126, 120, 131, 116],
      fill: true,
      backgroundColor: "rgb(153,102,204,1)",
      borderColor: "rgb(153,102,204,1)",
    },
    {
      label: "Oxycodone",
      data: [302, 349, 299, 215, 191],
      fill: true,
      backgroundColor: "rgb(205,149,117,1)",
      borderColor: "rgb(205,149,117,1)",
    },
    {
      label: "Morphine",
      data: [57, 45, 36, 17, 57],
      fill: true,
      backgroundColor: "rgb(255,191,0,1)",
      borderColor: "rgb(255,191,0,1)",
    },
    {
      label: "Fentanyl",
      data: [417, 818, 1429, 2225, 2248],
      fill: true,
      backgroundColor: "rgb(254,111,94,1)",
      borderColor: "rgb(254,111,94,1)",
    },
    {
      label: "Heroin",
      data: [918, 1347, 1613, 1532, 1076],
      fill: true,
      backgroundColor: "rgb(0,192,255,0.2)",
      borderColor: "rgb(0,192,255,0.2)",
    },
    {
      label: "Fentanyl Analog",
      data: [0, 164, 602, 679, 438],
      fill: true,
      backgroundColor: "rgb(0,192,255,1)",
      borderColor: "rgb(0,192,255,1)",
    },
    {
      label: "Ethanol",
      data: [0, 0, 499, 527, 589],
      fill: true,
      backgroundColor: "rgb(0,64,255,1)",
      borderColor: "rgb(0,64,255,1)",
    },
    {
      label: "Benzodiazepine",
      data: [0, 0, 738, 499, 457],
      fill: true,
      backgroundColor: "rgb(204,85,0,1)",
      borderColor: "rgb(204,85,0,1)",
    },
    {
      label: "Methamphetamine",
      data: [0, 0, 60, 95, 147],
      fill: true,
      backgroundColor: "rgb(0,204,153,1)",
      borderColor: "rgb(0,204,153,1)",
    },
  ],
};

// Removing temporarily while 2020 numbers are updated
//const chartData = {
//    labels: ["2015", "2016", "2017", "2018", "2019", "2020"],
//    datasets: [
//        {
//            label: "Cocaine",
//            data: [414, 637, 890, 1045, 963, 964],
//            fill: true,
//            backgroundColor: "rgb(93,138,168,1",
//            borderColor: "rgb(93,138,168,1)"
//        },
//        {
//            label: "Methadone",
//            data: [103, 126, 120, 131, 116, 146],
//            fill: true,
//            backgroundColor: "rgb(153,102,204,1)",
//            borderColor: "rgb(153,102,204,1)"
//        },
//        {
//            label: "Oxycodone",
//            data: [302, 349, 299, 215, 191, 198],
//            fill: true,
//            backgroundColor: "rgb(205,149,117,1)",
//            borderColor: "rgb(205,149,117,1)"
//        },
//        {
//            label: "Morphine",
//            data: [57, 45, 36, 17, 57, 31],
//            fill: true,
//            backgroundColor: "rgb(255,191,0,1)",
//            borderColor: "rgb(255,191,0,1)"
//        },
//        {
//            label: "Fentanyl",
//            data: [417, 818, 1429, 2225, 2248, 2336],
//            fill: true,
//            backgroundColor: "rgb(254,111,94,1)",
//            borderColor: "rgb(254,111,94,1)"

//        },
//        {
//            label: "Heroin",
//            data: [918, 1347, 1613, 1532, 1076, 822],
//            fill: true,
//            backgroundColor: "rgb(0,192,255,0.2)",
//            borderColor: "rgb(0,192,255,0.2)"
//        },
//        {
//            label: "Fentanyl Analog",
//            data: [0, 164, 602, 679, 438, 264],
//            fill: true,
//            backgroundColor: "rgb(0,192,255,1)",
//            borderColor: "rgb(0,192,255,1)"
//        },
//        {
//            label: "Ethanol",
//            data: [0, 0, 499, 527, 589, 596],
//            fill: true,
//            backgroundColor: "rgb(0,64,255,1)",
//            borderColor: "rgb(0,64,255,1)"
//        },
//        {
//            label: "Benzodiazepine",
//            data: [0, 0, 738, 499, 457, 407],
//            fill: true,
//            backgroundColor: "rgb(204,85,0,1)",
//            borderColor: "rgb(204,85,0,1)"
//        },
//        {
//            label: "Methamphetamine",
//            data: [0, 0, 60, 95, 147, 182],
//            fill: true,
//            backgroundColor: "rgb(0,204,153,1)",
//            borderColor: "rgb(0,204,153,1)"
//        }

//    ]
//}

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement
);

function DrugSubstances2015_2019_MultiLine() {
  const [isLoading, setIsLoading] = useState(true);
  const [chartDataState, setChartData] = useState(chartData);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  // Display temporary loading prompt
  if (isLoading) {
    return (
      <div className="text-center">
        <br />
        Please wait, Reading Data...
        <hr />
      </div>
    );
  }

  return (
    <div>
      <Line data={chartDataState} />
      <div className="text-center">
        <br />
        <i>
          *Note* Some of the data will display "0" due to data not being
          recorded that year for that specific substance. i.e{" "}
          <strong>Ethanol, Benzodiazepine and Methamphetamine</strong> overdoses
          were not recorded until 2017. This does not mean overdoses resulting
          in death due to these specific substances did not occur at that time,
          just the data was not collected.
        </i>
      </div>
    </div>
  );
}

export default DrugSubstances2015_2019_MultiLine;

import { Box } from "@mui/material";
import { Component } from "react";
import { AiOutlineFilePdf } from "react-icons/ai";
import { Nav, NavItem } from "reactstrap";

export class Forms extends Component {
  static displayName = Forms.name;

  render() {
    return (
      <Box
        sx={{
          mt: 3,
          mx: {
            xs: 2, // For mobile
            sm: 5, // For tablet
            md: 25, // For small desktop
            lg: 40, // For large desktop
          },
        }}
      >
        <h2 className="text-center">Forms & Downloads</h2>

        <hr />

        <strong>For Families / Next-of-Kin</strong>
        <Nav vertical>

        <NavItem>
            <a
              href="https://njgovhealth.govqa.us/WEBAPP/_rs/"
              target="_blank"
            >
              <AiOutlineFilePdf />
              Government Records Request (OPRA) Online
            </a>
          </NavItem>

          <NavItem>
            <a href="/PDF_FORMS/ME_OFFICE_FORMS/njdoh-34.pdf" target="_blank">
              <AiOutlineFilePdf />
              Government Records Request (OPRA)
            </a>
          </NavItem>
          
          <NavItem>
            <a href="https://vitalrecords.egov.com/CDC.VitalRecordsMVC.Web/Wizard/NJ/Municipality/SelectMunicipality">
              <AiOutlineFilePdf />
              Order a Death Certificate
            </a>
          </NavItem>

          <NavItem>
            <a href="/PDF_FORMS/MAiD_FORMS/patient_request_medication.pdf" target="_blank">
              <AiOutlineFilePdf />
              Request for Medication to End My Life in a Humane and Dignified Manner (MAiD)
            </a>
          </NavItem>
        </Nav>
        <hr />

        <strong> For Medical Examiners and Hospitals</strong>
        <Nav vertical>
          <NavItem>
            <a href="/PDF_FORMS/ME_OFFICE_FORMS/SUIDI_Fill_Under_508.pdf" target="_blank">
              <AiOutlineFilePdf />
              Sudden Unexpected Infant Death Investigation (SUIDI) Form
            </a>
          </NavItem>

          <NavItem>
            <a href="/PDF_FORMS/ME_OFFICE_FORMS/Guidelines_For_PMCT.pdf" target="_blank">
              <AiOutlineFilePdf />
              Guidelines for Postmortem Computed Tomography (PMCT)
            </a>
          </NavItem>

          {/* <NavItem>
                        <a href=""><AiOutlineFilePdf />Report of Investigation By Medical Examiner (RIME)</a>
                    </NavItem>  
                    <NavItem>
                        <a href=""><AiOutlineFilePdf />Toxicology Request Form</a>
                    </NavItem>*/}
        </Nav>
        <hr />
        <strong>For MAiD Providers</strong>
        <Nav vertical>
          <NavItem>
            <a href="/PDF_FORMS/MAiD_FORMS/attending_physician_compliance.pdf" target="_blank">
              <AiOutlineFilePdf />
              Attending Physician Compliance Form (MAiD){" "}
            </a>
          </NavItem>
          <NavItem>
            <a href="/PDF_FORMS/MAiD_FORMS/consulting_physician_compliance.pdf" target="_blank">
              <AiOutlineFilePdf />
              Consulting Physician Compliance Form (MAiD){" "}
            </a>
          </NavItem>
          <NavItem>
            <a href="/PDF_FORMS/MAiD_FORMS/mental_health_professional.pdf" target="_blank">
              <AiOutlineFilePdf />
              Mental Health Professional Compliance Form (MAiD){" "}
            </a>
          </NavItem>
          <NavItem>
            <a href="/PDF_FORMS/MAiD_FORMS/medication_dispensing_record.pdf" target="_blank">
              <AiOutlineFilePdf />
              Medication Dispensing Record (MAiD){" "}
            </a>
          </NavItem>
          <NavItem>
            <a href="/PDF_FORMS/MAiD_FORMS/attending_physician_followup.pdf" target="_blank\">
              <AiOutlineFilePdf />
              Attending Physician FollowUp Form (MAiD){" "}
            </a>
          </NavItem>
        </Nav>

        <hr />
      </Box>
    );
  }
}

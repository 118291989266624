import React, { Component } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
);

export const options = {
    responsive: true,

    //The 'indexAxis' option labels the axis which the bars should originate from
    indexAxis: 'y',

    //Using the y-axis scales will not work with a horizontal bar chart.  X-axis scales can be used instead
    //scales: {
    //    y: {
    //        type: 'linear',
    //        grace: '10%'
    //    }
    //},

    plugins: {
        legend: {
            position: 'top',
        },

        labels: {
            position: 'top',
        },
        title: {
            display: true,
        },
    },
};

var labels = [];

export var chartData = {
    labels,
    datasets: [
        {
            //The axis option show which axis the data should appear on (default x)
            axis: 'y',
            label: '# of Suspected Drug Deaths',
            data: [],
            backgroundColor: 'lightblue',
        },
    ],
};

export class WeekDrugDeathAgeBar extends Component {
    static displayName = WeekDrugDeathAgeBar.name;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
        };
    }

    async componentDidMount() {
        const fetchURL = process.env.REACT_APP_API_URL + 'GetLastWeekDrugDeathsAge';   
        const response = await fetch(fetchURL, {
            method: "GET"
        });

        const responseData = await response.json();

        var ChartLabels = [];
        var ValuesArray = [];
        var total = 0;

        const title = responseData.startDate + " through " + responseData.endDate;
        options.plugins.title.text = title;

        responseData.data.forEach(dataPoint => {
            ChartLabels.push(dataPoint.ageRange);
            ValuesArray.push(dataPoint.amount);
            total += dataPoint.amount;
        });

        chartData.labels = ChartLabels;
        chartData.datasets[0].data = ValuesArray;

        await this.setState({
            HomicideData: responseData,
            isLoading: false,
            chartData: chartData,
            total: total
        });
    }

    render() {
        //Display temporary loading prompt
        if (this.state.isLoading) {
            return (
                <div className="text-center">
                    <br />
                    Please wait, Reading Data...<hr />
                </div>
            )
        }

        return (
            <div>
                <Bar options={options} data={this.state.chartData} />
                <p className="text-center chart-total"><i>Total:</i> <b>{this.state.total}</b></p>
            </div>
        );
    }
}


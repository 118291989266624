import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },

    labels: {
      position: "top",
    },
    title: {
      display: false,
      text: "NJ Confirmed Drugs 2017",
    },
  },
};

const labels = [
  "Heroin",
  "Fentanyl",
  "Analog",
  "Morphine",
  "Cocaine",
  "Oxycodone",
  "Methadone",
];

const data = {
  labels,
  datasets: [
    {
      label: "Atlantic",
      data: [95, 86, 31, 4, 42, 13, 10],
      backgroundColor: "red",
    },
    {
      label: "Bergen",
      data: [68, 59, 40, 2, 35, 19, 4],
      backgroundColor: "pink",
    },
    {
      label: "Burlington",
      data: [87, 85, 30, 2, 49, 21, 11],
      backgroundColor: "blue",
    },
    {
      label: "Camden",
      data: [205, 170, 58, 3, 129, 40, 13],
      backgroundColor: "green",
    },
    {
      label: "Cape May",
      data: [34, 34, 0, 1, 14, 5, 2],
      backgroundColor: "purple",
    },
    {
      label: "Cumberland",
      data: [46, 50, 5, 0, 23, 4, 1],
      backgroundColor: "aqua",
    },
    {
      label: "Essex",
      data: [237, 161, 83, 0, 157, 33, 20],
      backgroundColor: "teal",
    },
    {
      label: "Gloucester",
      data: [73, 77, 27, 4, 44, 11, 4],
      backgroundColor: "lime",
    },
    {
      label: "Hudson",
      data: [89, 45, 28, 3, 43, 15, 5],
      backgroundColor: "yellow",
    },
    {
      label: "Hunterdon",
      data: [12, 16, 8, 0, 5, 2, 0],
      backgroundColor: "orange",
    },
    {
      label: "Mercer",
      data: [57, 59, 30, 0, 37, 10, 5],
      backgroundColor: "black",
    },
    {
      label: "Middlesex",
      data: [114, 137, 86, 3, 53, 28, 5],
      backgroundColor: "bluegray500",
    },
    {
      label: "Monmouth",
      data: [98, 94, 39, 2, 42, 18, 8],
      backgroundColor: "lightgreen",
    },
    {
      label: "Morris",
      data: [53, 42, 30, 1, 26, 10, 2],
      backgroundColor: "darkgoldenrod",
    },
    {
      label: "Ocean",
      data: [100, 93, 43, 7, 41, 24, 11],
      backgroundColor: "deeppink",
    },
    {
      label: "Passaic",
      data: [86, 68, 8, 0, 54, 11, 7],
      backgroundColor: "beige",
    },
    {
      label: "Salem",
      data: [12, 9, 4, 0, 6, 5, 0],
      backgroundColor: "mediumturquoise",
    },
    {
      label: "Somerset",
      data: [30, 21, 13, 0, 18, 3, 2],
      backgroundColor: "silver",
    },
    {
      label: "Sussex",
      data: [22, 19, 11, 1, 7, 3, 5],
      backgroundColor: "slateblue",
    },
    {
      label: "Union",
      data: [76, 83, 16, 3, 55, 19, 4],
      backgroundColor: "thistle",
    },
    {
      label: "Warren",
      data: [19, 21, 12, 0, 10, 5, 1],
      backgroundColor: "lavender",
    },
  ],
};

function ConfirmedDrugs2017BarChart() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <div>
      {isLoading ? (
        <div>
          <br />
          <p>Please wait, Reading Data...</p>
          <hr />
        </div>
      ) : (
        <Bar options={options} data={data} />
      )}
    </div>
  );
}

export default ConfirmedDrugs2017BarChart;

import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "left",
    },

    labels: {
      position: "top",
    },
    title: {
      display: false,
      text: "2018",
    },
  },
};

const labels = [
  "Ethanol",
  "Benzodiazepine",
  "Methamphetamine",
  "Cocaine",
  "Methadone",
  "Oxycodone",
  "Morphine",
  "Fentanyl Analog",
  "Fentanyl",
  "Heroin",
];

const data = {
  labels,
  datasets: [
    {
      label: "2018",
      data: [527, 499, 95, 1045, 131, 215, 17, 679, 2225, 1532],
      backgroundColor: "#E65100",
    },
  ],
};

function StateDrugTotal2018() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  // Display temporary loading prompt
  if (isLoading) {
    return (
      <div>
        <br />
        <p>Please wait, Reading Data...</p>
        <hr />
      </div>
    );
  }

  return (
    <div>
      <Bar options={options} data={data} />
    </div>
  );
}

export default StateDrugTotal2018;

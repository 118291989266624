import { Card, CardBody, CardHeader, CardText, Nav } from "reactstrap";

import { Box } from "@mui/material";
import { MdOutlinePictureAsPdf } from "react-icons/md";
import ReportIcon from "../components/ReportIcon";

export function PublicReports() {
  return (
    <Box
      sx={{
        mt: 3,
        mx: {
          xs: 2, // For mobile
          sm: 5, // For tablet
          md: 25, // For small desktop
          lg: 40, // For large desktop
        },
      }}
    >
      <h2 className="text-center">Reports & Publications</h2>

      <hr />

      <Card className="mx-auto" style={{ width: "100%" }}>
        <CardHeader className="faded-blue text-left">
          <strong>
            <MdOutlinePictureAsPdf /> NJ OCSME Annual Reports
          </strong>
        </CardHeader>
        <CardBody>
          <CardText className="text-left">
            <Nav>
              <ReportIcon
                title="2019 Annual Report"
                thumbnail="/Public_Reports/OCSMEReports/Thumbnails/NJOCSME-2019AnnualReportThumbnail.png"
                target="/Public_Reports/OCSMEReports/NJOCSME-2019AnnualReport.pdf"
              />
              <ReportIcon
                title="2018 Annual Report"
                thumbnail="/Public_Reports/OCSMEReports/Thumbnails/NJOCSME-2018AnnualReportThumbnail.png"
                target="/Public_Reports/OCSMEReports/NJOCSME-2018AnnualReport.pdf"
              />
              <ReportIcon
                title="2017 Annual Report"
                thumbnail="/Public_Reports/OCSMEReports/Thumbnails/NJOCSME-2017AnnualReportThumbnail.png"
                target="/Public_Reports/OCSMEReports/NJOCSME-2017AnnualReport.pdf"
              />
              <ReportIcon
                title="2016 Annual Report"
                thumbnail="/Public_Reports/OCSMEReports/Thumbnails/NJOCSME-2016AnnualReportThumbnail.png"
                target="/Public_Reports/OCSMEReports/NJOCSME-2016AnnualReport.pdf"
              />
              <ReportIcon
                title="2015 Annual Report"
                thumbnail="/Public_Reports/OCSMEReports/Thumbnails/NJOCSME-2015AnnualReportThumbnail.png"
                target="/Public_Reports/OCSMEReports/NJOCSME-2015AnnualReport.pdf"
              />
            </Nav>
          </CardText>
        </CardBody>
      </Card>

      <Card className="mx-auto" style={{ width: "100%" }}>
        <CardHeader className="faded-blue text-left">
          <strong>
            <MdOutlinePictureAsPdf /> NJ MAiD Annual Reports
          </strong>
        </CardHeader>
        <CardBody>
          <CardText className="text-left">
            <Nav>
            <ReportIcon
                title="2023 MAiD Report"
                thumbnail="/Public_Reports/MAidAnnualReports/Thumbnails/MAiD-2023AnnualReportThumbnail.png"
                target="/Public_Reports/MAidAnnualReports/2023.pdf"
              />
              <ReportIcon
                title="2022 MAiD Report"
                thumbnail="/Public_Reports/MAidAnnualReports/Thumbnails/MAiD-2022AnnualReportThumbnail.png"
                target="/Public_Reports/MAidAnnualReports/2022.pdf"
              />
              <ReportIcon
                title="2021 MAiD Report"
                thumbnail="/Public_Reports/MAidAnnualReports/Thumbnails/MAiD-2021AnnualReportThumbnail.png"
                target="/Public_Reports/MAidAnnualReports/2021.pdf"
              />
              <ReportIcon
                title="2020 MAiD Report"
                thumbnail="/Public_Reports/MAidAnnualReports/Thumbnails/MAiD-2020AnnualReportThumbnail.png"
                target="/Public_Reports/MAidAnnualReports/2020.pdf"
              />
              <ReportIcon
                title="2019 MAiD Report"
                thumbnail="/Public_Reports/MAidAnnualReports/Thumbnails/MAiD-2019AnnualReportThumbnail.png"
                target="/Public_Reports/MAidAnnualReports/2019.pdf"
              />
            </Nav>
          </CardText>
        </CardBody>
      </Card>

      <hr />

      <hr />
      <br />
    </Box>
  );
}

import React, { Component, Image } from 'react';
import PropTypes from 'prop-types';
import { NavItem, NavLink, Card, CardTitle, CardBody, CardFooter } from 'reactstrap';

class ReportIcon extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        thumbnail: PropTypes.func.isRequired,
        target: PropTypes.func.isRequired
    };

    render() {
        const {
            props: {
                title,
                thumbnail,
                target
            },
        } = this;

        let className = 'report-icon';

        return (
            <NavItem>
                <NavLink className={className} href={target} target="_blank">
                    <Card className="report-thumbnail">
                        <CardBody><img src={thumbnail} alt={`${title} report thumbnail`} /></CardBody>
                    </Card>
                    <h3 className="report-title">{title}</h3>
                </NavLink>
            </NavItem>
        );
    }
}

export default ReportIcon;
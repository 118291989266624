import React, { Component } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement, PointElement, LineElement } from 'chart.js';
import { Line } from "react-chartjs-2";

export var chartData = {
    labels,
    datasets: [{
        label: '# of Suspected Drug Deaths',
        data: [],
        backgroundColor: "#233d4d",
        borderColor: "#4f89ac"
    }],
};

export const options = {
    responsive: true,
    scales: {
        y: {
            type: 'linear',
            grace: '10%'
        }
    },
    plugins: {
        legend: {
            position: 'top',
        },

        labels: {
            position: 'top',
        },
        title: {
            display: true,
        },
    },
};

var labels = [];

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    PointElement,
    LineElement,
);

export class YearDrugDeathWeekLine extends Component {
    static displayName = YearDrugDeathWeekLine.name;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
        };
    }

    async componentDidMount() {
        const fetchURL = process.env.REACT_APP_API_URL + 'GetWeeklySuspectedDrugDeaths';   
        const response = await fetch(fetchURL, {
            method: "GET"
        });

        const responseData = await response.json();

        var ChartLabels = [];
        var ValuesArray = [];
        var total = 0;

        const title = responseData.startDate + " through " + responseData.endDate;
        options.plugins.title.text = title;

        responseData.data.forEach(dataPoint => {
            ChartLabels.push(dataPoint.weekStart);
            ValuesArray.push(dataPoint.weekTotal);
            total += dataPoint.weekTotal;
        });

        chartData.labels = ChartLabels;
        chartData.datasets[0].data = ValuesArray;

        await this.setState({
            HomicideData: responseData,
            isLoading: false,
            chartData: chartData,
            total: total
        });
    }

    render() {
        //Display temporary loading prompt
        if (this.state.isLoading) {
            return (
                <div className="text-center">
                    <br />
                    Please wait, Reading Data...<hr />
                </div>
            )
        }

        return (
            <div>
                <Line options={options} data={this.state.chartData} />
                <p className="text-center chart-total"><i>Total (YTD):</i> <b>{this.state.total}</b></p>
            </div>
        );
    }
}


import { Box } from "@mui/material";
import { Media } from "reactstrap";

export function UsefulLinks() {
  return (
    <Box
      sx={{
        mt: 3,
        mx: {
          xs: 2, // For mobile
          sm: 5, // For tablet
          md: 25, // For small desktop
          lg: 40, // For large desktop
        },
      }}
    >
      <h2 className="text-center">Useful Links</h2>

      <hr />

      <Media>
        <Media body>
          <Media heading>
            ABMDI{" "}
            <a href="https://abmdi.org/" target="_blank">
              {" "}
              <h6>https://abmdi.org</h6>{" "}
            </a>
          </Media>
          The American Board of Medicolegal Death Investigators (ABMDI) is a voluntary national, not-for-profit,
          independent professional certification board that has been established to promote the highest standards of
          practice for medicolegal death investigators. <br />
        </Media>
      </Media>
      <br />
      <Media>
        <Media body>
          <Media heading>
            AAFS{" "}
            <a href="https://www.aafs.org/about-us" target="_blank">
              {" "}
              <h6>https://www.aafs.org</h6>{" "}
            </a>
          </Media>
          The American Academy of Forensic Sciences (AAFS) is a multidisciplinary professional organization that
          provides leadership to advance science and its application to the legal system{" "}
        </Media>
      </Media>
      <br />
      <Media>
        <Media body>
          <Media heading>
            NAME{" "}
            <a href="https://thename.org/" target="_blank">
              {" "}
              <h6>https://thename.org</h6>{" "}
            </a>
          </Media>
          The National Association of Medical Examiners is the premier professional organization for medical examiners,
          forensic pathologists, and medicolegal affiliates and administrators.
          <br />
        </Media>
      </Media>
      <br />
      <Media>
        <Media body>
          <Media heading>
            NamUs{" "}
            <a href="https://namus.nij.ojp.gov/" target="_blank">
              {" "}
              <h6>https://namus.nij.ojp.gov</h6>
            </a>
          </Media>
          The National Missing and Unidentified Persons System (NamUs) provides technology, forensic services, and
          investigative support to resolve missing person and unidentified remains cases.
          <br />
        </Media>
      </Media>
    </Box>
  );
}

import { useRef, useState } from "react";
import { Carousel, CarouselCaption, CarouselControl, CarouselIndicators, CarouselItem } from "reactstrap";

const items = [
  {
    src: require("../assets/img/Home/Image1.jpg"),
    altText: "",
    caption: "",
  },
  {
    src: require("../assets/img/Home/Image2.jpg"),
    altText: "",
    caption: "",
  },
  {
    src: require("../assets/img/Home/Image3.jpg"),
    altText: "",
    caption: "",
  },
];

export const HomeCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const animating = useRef(false);

  const onExiting = () => {
    animating.current = true;
  };

  const onExited = () => {
    animating.current = false;
  };

  const next = () => {
    if (animating.current) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating.current) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex: number) => {
    if (animating.current) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item, index) => (
    <CarouselItem onExiting={onExiting} onExited={onExited} key={index}>
      <img width="100%" src={item.src} alt={item.src} />
      <CarouselCaption captionText={item.altText} captionHeader={item.caption} />
    </CarouselItem>
  ));

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      style={{ width: "100%", padding: 0, margin: 0 }}
    >
      <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={(newIndex) => goToIndex(newIndex)} />
      {slides}
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  );
};

import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },

    labels: {
      position: "top",
    },
    title: {
      display: false,
      text: "NJ Confirmed Drugs 2016",
    },
  },
};

const labels = [
  "Heroin",
  "Morphine",
  "Cocaine",
  "Fentanyl",
  "Analog",
  "Oxycodone",
  "Methadone",
];

const data = {
  labels,
  datasets: [
    {
      label: "Atlantic",
      data: [97, 4, 40, 64, 5, 25, 15],
      backgroundColor: "red",
    },
    {
      label: "Bergen",
      data: [54, 1, 22, 32, 9, 13, 4],
      backgroundColor: "pink",
    },
    {
      label: "Burlington",
      data: [43, 6, 15, 23, 3, 22, 5],
      backgroundColor: "blue",
    },
    {
      label: "Camden",
      data: [141, 4, 7, 82, 7, 38, 12],
      backgroundColor: "green",
    },
    {
      label: "Cape May",
      data: [15, 1, 9, 11, 2, 2, 1],
      backgroundColor: "purple",
    },
    {
      label: "Cumberland",
      data: [36, 0, 16, 24, 0, 4, 0],
      backgroundColor: "aqua",
    },
    {
      label: "Essex",
      data: [170, 4, 129, 82, 8, 27, 9],
      backgroundColor: "teal",
    },
    {
      label: "Gloucester",
      data: [66, 1, 27, 38, 0, 15, 5],
      backgroundColor: "lime",
    },
    {
      label: "Hudson",
      data: [77, 4, 42, 34, 2, 21, 8],
      backgroundColor: "yellow",
    },
    {
      label: "Hunterdon",
      data: [10, 1, 3, 2, 2, 4, 0],
      backgroundColor: "orange",
    },
    {
      label: "Mercer",
      data: [33, 2, 14, 21, 0, 7, 1],
      backgroundColor: "black",
    },
    {
      label: "Middlesex",
      data: [110, 3, 46, 79, 28, 35, 16],
      backgroundColor: "bluegray500",
    },
    {
      label: "Monmouth",
      data: [97, 4, 27, 57, 29, 33, 11],
      backgroundColor: "lightgreen",
    },
    {
      label: "Morris",
      data: [47, 0, 18, 25, 8, 8, 6],
      backgroundColor: "darkgoldenrod",
    },
    {
      label: "Ocean",
      data: [155, 5, 49, 123, 39, 46, 12],
      backgroundColor: "deeppink",
    },
    {
      label: "Passaic",
      data: [66, 1, 42, 28, 1, 15, 7],
      backgroundColor: "beige",
    },
    {
      label: "Salem",
      data: [12, 0, 8, 10, 0, 1, 2],
      backgroundColor: "mediumturquoise",
    },
    {
      label: "Somerset",
      data: [22, 0, 8, 16, 1, 7, 3],
      backgroundColor: "silver",
    },
    {
      label: "Sussex",
      data: [20, 0, 5, 9, 8, 7, 2],
      backgroundColor: "slateblue",
    },
    {
      label: "Union",
      data: [58, 2, 36, 48, 5, 12, 6],
      backgroundColor: "thistle",
    },
    {
      label: "Warren",
      data: [18, 2, 6, 10, 7, 7, 1],
      backgroundColor: "lavender",
    },
  ],
};

const ConfirmedDrugs2016BarChart = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return (
      <div>
        <br />
        <p>Please wait, Reading Data...</p>
        <hr />
      </div>
    );
  }

  return (
    <div>
      <Bar options={options} data={data} />
    </div>
  );
};

export default ConfirmedDrugs2016BarChart;

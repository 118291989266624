import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {
  Card,
  Button,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  CardDeck,
  CardSubtitle,
  CardBody,
  CardGroup,
} from "reactstrap";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },

    labels: {
      position: "top",
    },
    title: {
      display: false,
      text: "NJ Confirmed Drugs 2019",
    },
  },
};

const labels = [
  "Heroin",
  "Fentanyl",
  "Fentanyl Analog",
  "Morphine",
  "Oxycodone",
  "Methadone",
  "Cocaine",
  "Methamphetimine",
  "Benzopdiazepine",
  "Ethanol",
];

const data = {
  labels,
  datasets: [
    {
      label: "Atlantic",
      data: [48, 121, 10, 1, 16, 10, 35, 10, 26, 47],
      backgroundColor: "red",
    },
    {
      label: "Bergen",
      data: [57, 110, 24, 11, 11, 2, 36, 5, 41, 20],
      backgroundColor: "pink",
    },
    {
      label: "Burlington",
      data: [38, 127, 28, 5, 8, 4, 30, 17, 15, 19],
      backgroundColor: "blue",
    },
    {
      label: "Camden",
      data: [113, 285, 87, 1, 21, 16, 149, 24, 72, 54],
      backgroundColor: "green",
    },
    {
      label: "Cape May",
      data: [18, 36, 6, 2, 5, 2, 10, 6, 4, 12],
      backgroundColor: "purple",
    },
    {
      label: "Cumberland",
      data: [18, 63, 3, 0, 3, 7, 24, 13, 13, 16],
      backgroundColor: "aqua",
    },
    {
      label: "Essex",
      data: [182, 320, 11, 2, 13, 17, 190, 7, 31, 105],
      backgroundColor: "teal",
    },
    {
      label: "Gloucester",
      data: [34, 114, 32, 0, 10, 3, 54, 16, 27, 18],
      backgroundColor: "lime",
    },
    {
      label: "Hudson",
      data: [80, 122, 4, 2, 10, 6, 61, 5, 22, 43],
      backgroundColor: "yellow",
    },
    {
      label: "Hunterdon",
      data: [3, 6, 2, 1, 4, 0, 2, 0, 4, 4],
      backgroundColor: "orange",
    },
    {
      label: "Mercer",
      data: [39, 94, 36, 3, 7, 3, 48, 6, 14, 30],
      backgroundColor: "black",
    },
    {
      label: "Middlesex",
      data: [64, 168, 47, 7, 9, 9, 48, 10, 23, 46],
      backgroundColor: "bluegray500",
    },
    {
      label: "Monmouth",
      data: [78, 135, 37, 4, 19, 14, 56, 7, 29, 33],
      backgroundColor: "lightgreen",
    },
    {
      label: "Morris",
      data: [32, 73, 19, 3, 8, 3, 27, 1, 28, 25],
      backgroundColor: "darkgoldenrod",
    },
    {
      label: "Ocean",
      data: [87, 143, 34, 12, 22, 10, 32, 5, 34, 14],
      backgroundColor: "deeppink",
    },
    {
      label: "Passaic",
      data: [86, 130, 6, 0, 5, 5, 69, 1, 18, 38],
      backgroundColor: "beige",
    },
    {
      label: "Salem",
      data: [12, 33, 8, 0, 2, 0, 18, 7, 4, 8],
      backgroundColor: "mediumturquoise",
    },
    {
      label: "Somerset",
      data: [14, 30, 1, 0, 3, 1, 12, 2, 7, 9],
      backgroundColor: "silver",
    },
    {
      label: "Sussex",
      data: [22, 32, 7, 0, 3, 2, 8, 2, 16, 12],
      backgroundColor: "slateblue",
    },
    {
      label: "Union",
      data: [50, 109, 30, 3, 11, 2, 56, 2, 24, 46],
      backgroundColor: "thistle",
    },
    {
      label: "Warren",
      data: [7, 15, 5, 0, 2, 0, 4, 2, 5, 1],
      backgroundColor: "lavender",
    },
  ],
};

<hr />;

function ConfirmedDrugs2019BarChart() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  // Display temporary loading prompt
  if (isLoading) {
    return (
      <div>
        <br />
        <p>Please wait, Reading Data...</p>
        <hr />
      </div>
    );
  }

  return (
    <div>
      {/* Display Chart */}
      <Bar options={options} data={data} />
    </div>
  );
}

export default ConfirmedDrugs2019BarChart;

import { STLCarousal } from "../../components/STLCarousal";

import { Box } from "@mui/material";

export function StateToxLab() {
  return (
    <Box>
      <STLCarousal />
      <br />
      <Box
        sx={{
          mt: 3,
          mx: {
            xs: 2, // For mobile
            sm: 5, // For tablet
            md: 25, // For small desktop
            lg: 40, // For large desktop
          },
        }}
      >
        <b>
          <h3 className="text-center">New Jersey State Toxicology Lab </h3>
        </b>
        <hr />
        <div className="text-left">
          The NJ State Toxicology Laboratory (NJSTL) operates under the oversight of the NJ Office of the Chief State
          Medical Examiner. The NJSTL is a state-of-the-art facility that provides high quality scientific legal
          handling and analyses of biological tissues and fluids for alcohols, drugs and other toxic substances to
          assist the medical examiner in the determination of the cause and manner of death in medical examiner cases.
          <br />
          <br />
          In addition, the NJST is the exclusive provider of forensic urine drug testing services to the NJ Law
          enforcement community. In accordance with the NJ Attorney General's Law Enforcement Drug Testing Policy, the
          NJSTL oversees and provides quality scientific legal handling of urine specimens submitted by law enforcement
          agencies.
          <br /> <br />
          The NJSTL also provides forensic urine drug testing to other non-law enforcement agencies such as workplace,
          school, and sports.
        </div>
        <hr />
      </Box>
    </Box>
  );
}

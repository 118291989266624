import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },

    labels: {
      position: "top",
    },
    title: {
      display: false,
      text: "NJ Confirmed Drugs 2018",
    },
  },
};

const labels = [
  "Benzodiazepine",
  "Cocaine",
  "Fentanyl",
  "Fentanyl Analog",
  "Fentanyl Related",
  "Heroin",
  "Methadone",
  "Methamphetamine",
  "Morphine",
  "Oxycodone",
];

const data = {
  labels,
  datasets: [
    {
      label: "Atlantic",
      data: [17, 64, 146, 8, 152, 97, 11, 9, 2, 5],
      backgroundColor: "red",
    },
    {
      label: "Bergen",
      data: [51, 46, 87, 35, 91, 79, 7, 0, 2, 13],
      backgroundColor: "pink",
    },
    {
      label: "Burlington",
      data: [18, 37, 123, 29, 125, 64, 3, 12, 1, 16],
      backgroundColor: "blue",
    },
    {
      label: "Camden",
      data: [47, 160, 256, 126, 258, 166, 12, 12, 0, 25],
      backgroundColor: "green",
    },
    {
      label: "Cape May",
      data: [4, 8, 37, 1, 37, 22, 2, 4, 0, 5],
      backgroundColor: "purple",
    },
    {
      label: "Cumberland",
      data: [9, 39, 94, 3, 96, 42, 2, 9, 1, 5],
      backgroundColor: "aqua",
    },
    {
      label: "Essex",
      data: [23, 170, 262, 49, 282, 183, 14, 1, 2, 13],
      backgroundColor: "teal",
    },
    {
      label: "Gloucester",
      data: [35, 45, 119, 60, 119, 65, 5, 9, 0, 13],
      backgroundColor: "lime",
    },
    {
      label: "Hudson",
      data: [30, 64, 106, 13, 115, 96, 5, 4, 0, 17],
      backgroundColor: "yellow",
    },
    {
      label: "Hunterdon",
      data: [6, 3, 8, 4, 10, 10, 0, 2, 0, 3],
      backgroundColor: "orange",
    },
    {
      label: "Mercer",
      data: [23, 53, 102, 42, 104, 77, 4, 10, 0, 5],
      backgroundColor: "black",
    },
    {
      label: "Middlesex",
      data: [44, 43, 145, 75, 158, 88, 13, 8, 0, 20],
      backgroundColor: "bluegray500",
    },
    {
      label: "Monmouth",
      data: [53, 56, 166, 52, 172, 102, 17, 4, 2, 18],
      backgroundColor: "lightgreen",
    },
    {
      label: "Morris",
      data: [24, 21, 77, 34, 80, 56, 4, 0, 0, 6],
      backgroundColor: "darkgoldenrod",
    },
    {
      label: "Ocean",
      data: [34, 54, 152, 63, 155, 112, 18, 4, 4, 23],
      backgroundColor: "deeppink",
    },
    {
      label: "Passaic",
      data: [9, 88, 132, 15, 139, 120, 6, 1, 1, 3],
      backgroundColor: "beige",
    },
    {
      label: "Salem",
      data: [4, 14, 29, 8, 30, 19, 0, 2, 0, 3],
      backgroundColor: "mediumturquoise",
    },
    {
      label: "Somerset",
      data: [8, 11, 34, 1, 35, 24, 0, 0, 0, 6],
      backgroundColor: "silver",
    },
    {
      label: "Sussex",
      data: [17, 5, 26, 14, 27, 22, 1, 0, 1, 3],
      backgroundColor: "slateblue",
    },
    {
      label: "Union",
      data: [39, 53, 104, 37, 106, 77, 5, 2, 1, 11],
      backgroundColor: "thistle",
    },
    {
      label: "Warren",
      data: [4, 11, 20, 10, 22, 11, 2, 2, 0, 2],
      backgroundColor: "lavender",
    },
  ],
};

const ConfirmedDrugs2018BarChart = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  // Display temporary loading prompt
  if (isLoading) {
    return (
      <div>
        <br />
        <p>Please wait, Reading Data...</p>
        <hr />
      </div>
    );
  }

  return (
    <div>
      <Bar options={options} data={data} />
    </div>
  );
};

export default ConfirmedDrugs2018BarChart;

import { Box } from "@mui/material";
export function Training() {
  return (
    <Box
      sx={{
        mt: 3,
        mx: {
          xs: 2, // For mobile
          sm: 5, // For tablet
          md: 25, // For small desktop
          lg: 40, // For large desktop
        },
      }}
    >
      <h2 className="text-center">Medicolegal Training</h2>
      <hr />
      <br /> <br />
      <p>
        <i>Special note for Medicolegal Death Investigators regarding ABMDI:</i>
        <br />
        <br />
        “The ABMDI accepts continuing education that is approved by the American Medical Association (AMA), American
        Nursing Association (ANA), American Bar Association (ABA), American Academy of Physician Assistants (AAPA),
        American Society for Clinical Pathology (ASCP), College of American Pathologists (CAP), Emergency Medical
        Services (EMS), Federal Emergency Management Agency (FEMA), International Association for Continuing Education
        and Training (IACET), Pennsylvania Coroner's Education Board, Peace Officer Standards and Training (POST or
        equivalent), US Department of Homeland Security and post secondary institutions recognized by national
        educational accrediting agencies. <br /> <br />
        All courses must be relevant to the field of medicolegal death investigation. Training courses that are not
        preapproved by an agency listed above may be submitted to the ABMDI for review.”
      </p>{" "}
      <hr />
      <p>
        <b>Federal Emergency Management Association (FEMA) are available online:</b>
        <br />
        <i>All online training through FEMA requires a </i>
        <a href="https://cdp.dhs.gov/FEMASID/register" target="_blank">
          <i>Student Identification Number (SID)</i>
        </a>
        .
      </p>
      <p>
        <li>
          <a href="https://training.fema.gov/is/courseoverview.aspx?code=is-100.c" target="_blank">
            IS-100: Introduction to the Incident Command System, ICS 100 (2 hours)
          </a>
        </li>

        <li>
          <a href="https://training.fema.gov/is/courseoverview.aspx?code=IS-200.c" target="_blank">
            IS-200: Basic Incident Command System for Initial Response, ICS-200 (4 hours)
          </a>
        </li>

        <hr />
      </p>
      <b>Courses Offered through other Agencies:</b>
      <br /> <br />
      {/*<a href="https://cdn.ymaws.com/theiacme.com/resource/resmgr/conference/2022/2022_registration_book_4.27..pdf" target="_blank">International Association of Coroner and Medical Examiners </a>*/}
      {/*    <br /> July 16-22, 2022 <br />*/}
      {/*    Available Online and in person with scholarships available (including travel)<br />*/}
      {/*    <i>27 ABMDI and CME CEU’s available: </i>*/}
      {/*            <a href="https://cdn.ymaws.com/theiacme.com/resource/resmgr/conference/2022/2022_registration_book_4.27..pdf" target="_blank">Register Online*/}
      {/*    </a>*/}
      <p>
        Medicolegal Death Investigator Conference 2022-Death Investigations in the Real World: Expectations vs. Reality
        <br /> <br />
        <i>
          {" "}
          Franklin County Forensic Science Center <br />
          October 14th, 2022 <br />
          Virtual course offering{" "}
        </i>
        <br /> <br />
        Join us for the Medicolegal Death Investigators Conference at the Franklin County Forensic Science Center,
        Columbus Ohio. Taking place on Friday, October 14, 2022; registration for in-person will begin at 7:30am with
        the Conference Commencing at 8:00am. The theme for this year is “Death Investigations in the Real World:
        Expectations vs. Reality.” Some of the topics that will be covered are Next of Kin Search, Identification
        Practices, Suicide Case Study, Photography: Scene to Autopsy, Dealing with Difficult People, and MDI Tips and
        Questions from the Field. ABMDI CEU’s are pending.
      </p>
      <a
        href="https://www.eventbrite.com/e/medicolegal-death-investigator-conference-2022-tickets-360652500337"
        target="_blank"
      >
        Register Here
      </a>
      <hr />
      <a>Death Investigation Training Academy </a>
      <br />
      <a href="https://ditacademy.org/" target="_blank">
        https://ditacademy.org/
      </a>{" "}
      <br />
      <br />
      <a>New Jersey Office of Emergency Management </a>
      <br />
      <a href="https://nj.gov/njoem/training/schedule.shtml" target="_blank">
        https://nj.gov/njoem/training/schedule.shtml{" "}
      </a>{" "}
      <br />
      <br />
      <a>New Jersey Office of Homeland Security </a>
      <br />
      <a href="https://www.njhomelandsecurity.gov/programs" target="_blank">
        https://www.njhomelandsecurity.gov/programs
      </a>{" "}
      <br />
      <br />
      <a>Texas A&M Engineering Extension Service </a>
      <br />
      <a href="https://teex.org/" target="_blank">
        https://teex.org/
      </a>{" "}
      <br />
      <br />
      <a>Center for Domestic Preparedness </a>
      <br />
      <a href="https://cdp.dhs.gov/training" target="_blank">
        https://cdp.dhs.gov/training
      </a>
      <br />
      <br />
      <a>2022 IACME Identification Workshop (In person or virtual) </a>
      <br />
      <a href="https://theiacme.com/page/2022IACMEIdentificationWorkshop" target="_blank">
        https://theiacme.com/page/2022IACMEIdentificationWorkshop
      </a>
      <br />
    </Box>
  );
}

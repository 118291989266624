import { Box } from "@mui/material";
import MAiDPhoto from "../assets/img/MedicalAidinDying.png";

export function MAiD() {
  return (
    <Box
      sx={{
        mt: 3,
        mx: {
          xs: 2, // For mobile
          sm: 5, // For tablet
          md: 25, // For small desktop
          lg: 40, // For large desktop
        },
      }}
    >
      <div className="text-center">
        <h2>MAiD</h2>
        <hr />
        <p>
          <em>
            {" "}
            The Medical Aid in Dying for the Terminally Ill Act (sometimes referred to as "MAiD") is effective in New
            Jersey as of August 1, 2019.
          </em>
        </p>
      </div>
      <hr />
      <div className="row">
        <div className="column" style={{ width: "70%" }}>
          <div className="container">
            <p>
              <b>Medical Aid in Dying</b> <br />
              MAiD allows an adult New Jersey resident, who has the capacity to make health care decisions and who has
              been determined by that individual's attending and consulting physicians to be terminally ill, to obtain
              medication that the patient may self-administer to end the patient's life.
              <br /> <br />
              <li>
                <a
                  href="https://www.njconsumeraffairs.gov/bme/Documents/BME-Medical-Aid-in-Dying-for-the-Terminally-Ill-Act-Summary.pdf"
                  target="_blank"
                >
                  Summary of the MAiD Act
                </a>
              </li>
              <li>
                <a href="https://www.nj.gov/health/advancedirective/documents/maid/MAID_FAQ.pdf" target="_blank">
                  Frequently Asked Questions
                </a>
              </li>
            </p>
            <h5>
              <b>Guidance for Health Care Facilities</b>
            </h5>
            The responsibilities of health care facilties under MAID are outlined in the{" "}
            <a
              href="https://www.nj.gov/health/advancedirective/documents/maid/MAID_facilityguidance.pdf"
              target="_blank"
            >
              guidance document
            </a>
            <br /> <br />
            <h5>
              <b>Provider Forms</b>
            </h5>
            <li>
              {" "}
              <a
                href="https://medex.nj.gov/forms/MAID/attending_physician_compliance.pdf?_gl=1*8gd1gy*_ga*NDgzODY1NzMuMTYxMjc5MjA4MQ..*_ga_5PWJJG6642*MTY0NjQwNzUwOC4zLjEuMTY0NjQwNzYzNS4w"
                target="_blank"
              >
                Attending Physician Compliance Form
              </a>
            </li>
            <li>
              {" "}
              <a
                href="https://medex.nj.gov/forms/MAID/patient_request_medication.pdf?_gl=1*qqbzrm*_ga*NDgzODY1NzMuMTYxMjc5MjA4MQ..*_ga_5PWJJG6642*MTY0NjQwNzUwOC4zLjEuMTY0NjQwODAxOC4w"
                target="_blank"
              >
                Request for Medication to End My Life in a Humane and Dignified Manner
              </a>
            </li>
            <li>
              {" "}
              <a
                href="https://medex.nj.gov/forms/MAID/consulting_physician_compliance.pdf?_gl=1*8gd1gy*_ga*NDgzODY1NzMuMTYxMjc5MjA4MQ..*_ga_5PWJJG6642*MTY0NjQwNzUwOC4zLjEuMTY0NjQwNzYzNS4w"
                target="_blank"
              >
                Consulting Physician Compliance Form
              </a>
            </li>
            <li>
              {" "}
              <a
                href="https://medex.nj.gov/forms/MAID/mental_health_professional.pdf?_gl=1*1we6vu5*_ga*NDgzODY1NzMuMTYxMjc5MjA4MQ..*_ga_5PWJJG6642*MTY0NjQwNzUwOC4zLjEuMTY0NjQwODAxOC4w"
                target="_blank"
              >
                Mental Health Professional Compliance Form
              </a>
            </li>
            <li>
              {" "}
              <a
                href="https://medex.nj.gov/forms/MAID/medication_dispensing_record.pdf?_gl=1*u6py2v*_ga*NDgzODY1NzMuMTYxMjc5MjA4MQ..*_ga_5PWJJG6642*MTY0NjQwNzUwOC4zLjEuMTY0NjQwODAxOC4w"
                target="_blank"
              >
                Medication Dispensing Record
              </a>
            </li>
            <li>
              {" "}
              <a
                href="https://medex.nj.gov/forms/MAID/attending_physician_followup.pdf?_gl=1*qqbzrm*_ga*NDgzODY1NzMuMTYxMjc5MjA4MQ..*_ga_5PWJJG6642*MTY0NjQwNzUwOC4zLjEuMTY0NjQwODAxOC4w"
                target="_blank"
              >
                Attending Physician Follow Up Form
              </a>
            </li>
            <br />
            <h5>
              <b>Frequently Asked Questions</b>
            </h5>
            What if my provider or health care facility does not wish to participate? <br />
            What will be listed as the "cause of death" on the death certificate? <br />
            Find the answers to these and other questions in the
            <a href="https://www.nj.gov/health/advancedirective/documents/maid/MAID_FAQ.pdf" target="_blank">
              {" "}
              Frequently Asked Questions{" "}
            </a>
            document.
            <br />
            <br />
            <h5>
              <b>Submission Instructions</b>
            </h5>
            <em>
              Under P.L.2019, c.59, the compliance forms and the Medication Dispensing Record must be filed with the New
              Jersey Department of Health. Currently, the forms must be mailed to the{" "}
              <b>
                Office of the Chief State Medical Examiner, 120 South Stockton Street, 3rd floor, PO Box 182, Trenton,
                NJ 08625.
              </b>
              An electronic submission process is forthcoming. Any changes or additional submission processes will be
              posted here.
            </em>
          </div>
        </div>
        <div className="column" style={{ width: "30%" }}>
          <img src={MAiDPhoto}></img>
        </div>
      </div>
    </Box>
  );
}

import { Box } from "@mui/material";
import { FaMapMarkerAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import DrDicarloimg from "../FieldOffices/SRMEO/Generic_Avatar_Placeholder.jpg";
import SRMEOimg from "../FieldOffices/SRMEO/SRMEO_Site.jpg";

export function SRMEO() {
  return (
    <Box
      sx={{
        mt: 3,
        mx: {
          xs: 2, // For mobile
          sm: 5, // For tablet
          md: 25, // For small desktop
          lg: 40, // For large desktop
        },
      }}
    >
      <br />
      <div>
        <h1 className="text-center"> Southern Regional Medical Examiner's Office</h1>
        <hr />
      </div>
      <div className="text-center">
        <img style={{ zoom: "10%" }} src={SRMEOimg} alt="SRMEO Building" />
      </div>
      <br /> <br />
      <p>
        At the Southern Regional Medical Examiner's Offices, the men and women first on the scene from our staff are the
        members of the Medicolegal Death Investigations Section. The Medicolegal Death Investigators at the Southern
        RMEO work closely with local, county and state law enforcement officials. While at death scenes, the Medicolegal
        Death Investigator attempts to identify the recently deceased, examine their medical records and photograph both
        the scene and the deceased. The conclusions and findings of the Medicolegal Death Investigators are reviewed and
        shared with one of our Medical Examiners. It is then determined whether a case will be brought in for an
        external examination or complete autopsy.
        <br />
        <br />
        The Medicolegal Death Investigations Section of the Southern RMEO office responds to the counties of Atlantic,
        Cape May, & Cumberland. It operates 24 hours a day, 365 days per year. Our current staff of Medicolegal Death
        Investigators bring with them unique backgrounds and areas of expertise. Some our investigators' backgrounds
        include specialities in the areas of forensic anthropology, emergency medical services, mortuary services and
        nursing. Many of our investigators are also registered members with The American Board of Medicolegal Death
        Investigators (ABMDI).
      </p>
      <Row>
        <Col>
          <Card className="text-center" style={{ width: "80%", margin: "auto", height: "100%" }}>
            <CardHeader className="dark-blue">Medical Examiner</CardHeader>
            <CardBody className="faded-blue">
              <img src={DrDicarloimg} style={{ width: "80%", margin: "auto", height: "80%" }} alt="Dr DiCarlo" />

              <p>Medical Examiner of SRMEO: TBA</p>
            </CardBody>
          </Card>
        </Col>

        <Col>
          <Card className="text-center" style={{ width: "80%", margin: "auto", height: "100%" }}>
            <CardHeader className="dark-blue">Quick Links</CardHeader>
            <CardBody className="faded-blue">
              <a
                href="https://www.bing.com/maps?q=Woodbine+Developmental+Center+1175+DeHirsch+Avenue+Woodbine%2c+NJ+08270-2401&FORM=HDRSC4"
                target="_blank"
              >
                {" "}
                <FaMapMarkerAlt />
                Visit SRMEO
              </a>

              <br />

              <Link to="/AutopsyReportRequestForm">
                <FaMapMarkerAlt />
                Autopsy Request Form
              </Link>
            </CardBody>
            <CardHeader className="dark-blue">Contact Us</CardHeader>
            <CardBody className="faded-blue">
              <u>Office:</u> <br />
              609-861-3355 <br /> <br />
              <u>24-hour line:</u> <br />
              (609) 909-7200 (Atlantic)
              <br />
              (609) 465-3011 (Cape May)
              <br />
              (856) 455-6886 (Cumberland) <br />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Box>
  );
}

import { useRef, useState } from "react";
import { Carousel, CarouselCaption, CarouselControl, CarouselIndicators, CarouselItem } from "reactstrap";

const items = [
  {
    src: require("../assets/img/Jobs/JobsImage1.jpg"),
  },
  {
    src: require("../assets/img/Jobs/JobsImage2.jpg"),
  },
  {
    src: require("../assets/img/Jobs/JobsImage3.jpg"),
  },
];

export const CareersCarousal = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const animating = useRef(false);

  const onExiting = () => {
    animating.current = true;
  };

  const onExited = () => {
    animating.current = false;
  };

  const next = () => {
    if (animating.current) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating.current) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating.current) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem onExiting={onExiting} onExited={onExited} key={item.src}>
        <div style={{ width: "100%", maxHeight: "600px", overflow: "hidden" }}>
          <img style={{ width: "100%", height: "auto", objectFit: "cover" }} src={item.src} alt={item.altText} />
        </div>
        <CarouselCaption captionText={item.altText} captionHeader={item.caption} />
      </CarouselItem>
    );
  });

  return (
    <Carousel activeIndex={activeIndex} next={next} previous={previous}>
      <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {slides}
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  );
};

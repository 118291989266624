import React from "react";
import {
  Card,
  Button,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  CardDeck,
  CardSubtitle,
  CardBody,
  CardGroup,
} from "reactstrap";
import { Table } from "reactstrap";

function ConfirmedDrugs2018Table() {
  return (
    <div>
      <Table centered bordered>
        <thead style={{ backgroundColor: "black " }}>
          <tr style={{ color: "white" }}>
            <th className="text-center dark-black">County</th>
            <th className="text-center dark-black">Total</th>
            <th className="text-center dark-black">Benzodiazepine</th>
            <th className="text-center dark-black">Cocaine</th>
            <th className="text-center dark-black">Fentanyl</th>
            <th className="text-center dark-black">Fentanyl Analog</th>
            <th className="text-center dark-black">Fentanyl Related</th>
            <th className="text-center dark-black">Heroin</th>
            <th className="text-center dark-black">Methadone</th>
            <th className="text-center dark-black">Methamphetamine</th>
            <th className="text-center dark-black">Morphine</th>
            <th className="text-center dark-black">Oxycodone</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Atlantic</th>
            <td>190</td>
            <td>17</td>
            <td>64</td>
            <td>146</td>
            <td>8</td>
            <td>152</td>
            <td>97</td>
            <td>11</td>
            <td>9</td>
            <td>2</td>
            <td>5</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Bergen</th>
            <td>141</td>
            <td>51</td>
            <td>46</td>
            <td>87</td>
            <td>35</td>
            <td>91</td>
            <td>79</td>
            <td>7</td>
            <td>0</td>
            <td>2</td>
            <td>13</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Burlington</th>
            <td>159</td>
            <td>18</td>
            <td>37</td>
            <td>123</td>
            <td>29</td>
            <td>125</td>
            <td>64</td>
            <td>3</td>
            <td>12</td>
            <td>1</td>
            <td>16</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Camden</th>
            <td>327</td>
            <td>47</td>
            <td>160</td>
            <td>256</td>
            <td>126</td>
            <td>258</td>
            <td>166</td>
            <td>12</td>
            <td>12</td>
            <td>0</td>
            <td>25</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Cape May</th>
            <td>43</td>
            <td>4</td>
            <td>8</td>
            <td>37</td>
            <td>1</td>
            <td>37</td>
            <td>22</td>
            <td>2</td>
            <td>4</td>
            <td>0</td>
            <td>5</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Cumberland</th>
            <td>112</td>
            <td>9</td>
            <td>39</td>
            <td>94</td>
            <td>3</td>
            <td>96</td>
            <td>42</td>
            <td>2</td>
            <td>9</td>
            <td>1</td>
            <td>5</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Essex</th>
            <td>368</td>
            <td>23</td>
            <td>170</td>
            <td>262</td>
            <td>49</td>
            <td>282</td>
            <td>183</td>
            <td>14</td>
            <td>1</td>
            <td>2</td>
            <td>13</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Gloucester</th>
            <td>144</td>
            <td>35</td>
            <td>45</td>
            <td>119</td>
            <td>60</td>
            <td>119</td>
            <td>65</td>
            <td>5</td>
            <td>9</td>
            <td>0</td>
            <td>13</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Hudson</th>
            <td>174</td>
            <td>30</td>
            <td>64</td>
            <td>106</td>
            <td>13</td>
            <td>115</td>
            <td>96</td>
            <td>5</td>
            <td>4</td>
            <td>0</td>
            <td>17</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Hunterdon</th>
            <td>19</td>
            <td>6</td>
            <td>3</td>
            <td>8</td>
            <td>4</td>
            <td>10</td>
            <td>10</td>
            <td>0</td>
            <td>2</td>
            <td>0</td>
            <td>3</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Mercer</th>
            <td>138</td>
            <td>23</td>
            <td>53</td>
            <td>102</td>
            <td>42</td>
            <td>104</td>
            <td>77</td>
            <td>4</td>
            <td>10</td>
            <td>0</td>
            <td>5</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Middlesex</th>
            <td>204</td>
            <td>44</td>
            <td>43</td>
            <td>145</td>
            <td>75</td>
            <td>158</td>
            <td>88</td>
            <td>13</td>
            <td>8</td>
            <td>0</td>
            <td>20</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Morris</th>
            <td>88</td>
            <td>24</td>
            <td>21</td>
            <td>77</td>
            <td>34</td>
            <td>80</td>
            <td>56</td>
            <td>4</td>
            <td>0</td>
            <td>0</td>
            <td>6</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Ocean</th>
            <td>219</td>
            <td>34</td>
            <td>54</td>
            <td>152</td>
            <td>63</td>
            <td>155</td>
            <td>112</td>
            <td>18</td>
            <td>4</td>
            <td>4</td>
            <td>23</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Passaic</th>
            <td>182</td>
            <td>9</td>
            <td>88</td>
            <td>132</td>
            <td>15</td>
            <td>139</td>
            <td>120</td>
            <td>6</td>
            <td>1</td>
            <td>1</td>
            <td>3</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Salem</th>
            <td>31</td>
            <td>4</td>
            <td>14</td>
            <td>29</td>
            <td>8</td>
            <td>30</td>
            <td>19</td>
            <td>0</td>
            <td>2</td>
            <td>0</td>
            <td>3</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Somerset</th>
            <td>50</td>
            <td>8</td>
            <td>11</td>
            <td>34</td>
            <td>1</td>
            <td>35</td>
            <td>24</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>6</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Sussex</th>
            <td>34</td>
            <td>17</td>
            <td>5</td>
            <td>26</td>
            <td>14</td>
            <td>27</td>
            <td>22</td>
            <td>1</td>
            <td>0</td>
            <td>1</td>
            <td>3</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Union</th>
            <td>138</td>
            <td>39</td>
            <td>53</td>
            <td>104</td>
            <td>37</td>
            <td>106</td>
            <td>77</td>
            <td>5</td>
            <td>2</td>
            <td>1</td>
            <td>11</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Warren</th>
            <td>30</td>
            <td>4</td>
            <td>11</td>
            <td>20</td>
            <td>10</td>
            <td>22</td>
            <td>11</td>
            <td>2</td>
            <td>2</td>
            <td>0</td>
            <td>2</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">TOTAL</th>
            <td>3006</td>
            <td>499</td>
            <td>1045</td>
            <td>2225</td>
            <td>679</td>
            <td>2313</td>
            <td>1532</td>
            <td>131</td>
            <td>95</td>
            <td>17</td>
            <td>215</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default ConfirmedDrugs2018Table;

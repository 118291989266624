import { Box } from "@mui/material";
import { Component } from "react";

export class LEDTContactForm extends Component {
  static displayName = LEDTContactForm.name;

  constructor(props) {
    super(props);
    this.state = {
      retval: "",
    };
  }

  /*
    async onFormSubmit(e) {
        e.preventDefault()

        //change this to Confirm or Cancel box
        alert("Please Confirm, sending your request as follows:\n\n" + e.target.lastName.value + ", " + e.target.firstName.value);

        //This is the API call (modify this to where the API server lives)
        // const apiURL = "https://ocsme.nj.gov/Api/SendMedexSMTP"
        const apiURL = "/MedexMailer/Api/SendMedexSMTP";


        //build message request 
        let msgInfo = {
            id: 0,
            from: "ocsme.support@doh.nj.gov",
            to: "david.fenton@doh.nj.gov",
            cc: "ocsme.support@doh.nj.gov",
            subject: "CMS Account Request",
            body: "A CMS Account action has been requested, details are as follows: \n\n First Name: " + e.target.firstName.value + "\nLast Name: " +
                e.target.lastName.value +
                "\nWork Email: " + e.target.workMail.value +
                "\nRequest Type: " + e.target.requestType.value +
                "\nCounty: " + e.target.MEFacility.value +
                "\nRole: " + e.target.MERole.value
        };

        //Call the MedexMail API using fetch method
        {
            let myval = await fetch(apiURL, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(msgInfo)
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    return data;
                })
                .catch(error => {
                    return error;
                });

            //this.setState({ retval: JSON.stringify(myval) });
            alert(apiURL + " Result: " + JSON.stringify(myval));
        };
    };

*/

  render() {
    return (
      <Box
        sx={{
          mt: 3,
          mx: {
            xs: 2, // For mobile
            sm: 5, // For tablet
            md: 25, // For small desktop
            lg: 40, // For large desktop
          },
        }}
      >
        <br />

        <div className="text-center">
          <h1>New Jersey State Toxicology Lab</h1>
          <hr />
        </div>

        <iframe
          width="100%"
          height="1500px"
          src="https://forms.office.com/Pages/ResponsePage.aspx?id=0cN2UAI4n0uzauCkG9ZCp5s0PESLtgtOpO81jf2UKf1URVZEWE0yTkhZRllKMTBIMzBNMzZLT1haTy4u&embed=true"
        ></iframe>
      </Box>

      /*
                <div className="text-center">
                    <h1>LEDT Contact Form</h1>
                    <hr />
                    <p><em>Please complete the contact form below that will provide the best contact information of two individuals to be the point of contact within the Agency.</em></p>
                    <hr />
                </div>
                <Form onSubmit={this.onFormSubmit}>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="Agency Name">
                                    <b> Agency Name</b>
                                </Label>
                                <Input type={"text"} name={"AgencyName"} id={"AgencyName"} placeholder={"Enter Agency Name Here"} required />

                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="ORI">
                                    <b>ORI</b>
                                </Label>
                                <Input type={"text"} name={"ORI"} id={"ORI"} placeholder={"Enter ORI Here"} required />

                            </FormGroup>
                        </Col>
                    </Row>
                    <hr /> 
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="Billing Contact Name">
                                    <b> Primary Billing Contact Name</b>
                                </Label>
                                <Input type={"text"} name={"PrimaryBillingContactName"} id={"PrimaryBillingContactName"} placeholder={"Enter Name Here"} required />

                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="Primary Billing Contact Phone Number">
                                    <b>Primary Billing Contact Phone Number</b>
                                </Label>
                                <Input type={"text"} name={"PrimaryBillingContactPhoneNumber"} id={"PrimaryBillingContactPhoneNumber"} placeholder={"Format: 123-456-7890"} required />

                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="PrimaryBillingContactEmailAddress">
                                   <b>Primary Billing Contact Email Address</b>
                                </Label>

                                <Input type={"email"} name={"PrimaryBillingContactEmailAddress"} id={"PrimaryBillingContactEmailAddress"} placeholder={"Enter Email Here"} required />
                            </FormGroup>
                        </Col>
                    </Row>
                <hr /> 
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="Secondary Billing Contact Name">
                                    <b> Secondary Billing Contact Name</b>
                                </Label>
                                <Input type={"text"} name={"SecondaryBillingContactName"} id={"SecondaryBillingContactName"} placeholder={"Enter Name Here"} required />
                            </FormGroup>
                            <FormGroup>
                                <Label for="SecondaryBillingContactEmailAddress">
                                    <b>Secondary Billing Contact Email Address</b>
                                </Label>
                                <Input type={"email"} name={"SecondaryBillingContactEmailAddress"} id={"SecondaryBillingContactEmailAddress"} placeholder={"Enter Email Here"} required />
                            </FormGroup>
                           
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="Secondary Billing Contact Phone Number">
                                    <b>Secondary Billing Contact Phone Number</b>
                                </Label>
                                <Input type={"text"} name={"SecondaryBillingContactPhoneNumber"} id={"SecondaryBillingContactPhoneNumber"} placeholder={"Format: 123-456-7890"} required />
                            </FormGroup>
                       </Col>
                    </Row>

                    <hr />
                    <div className="text-center" >
                        <Button className="btn-primary" >
                            Submit
                        </Button>
                    </div>
                </Form>
           

            </div >
                */
    );
  }
}

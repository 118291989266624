import { Box } from "@mui/material";
import classnames from "classnames";
import { Component } from "react";
import {
  Alert,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import { ConfirmedDrugsStatistics } from "./DashboardTabs/ConfirmedDrugsStatistics";
import { GeneralCMStatistics } from "./DashboardTabs/GeneralCMSStatistics";
import { HomicideStatistics } from "./DashboardTabs/HomicideStatistics";
import { SuicideStatistics } from "./DashboardTabs/SuicideStatistics";
import { SuspectedDrugsStatistics } from "./DashboardTabs/SuspectedDrugsStatistics";

export class Dashboard extends Component {
  static displayName = Dashboard.name;

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    //loading state

    return (
      <Box
        sx={{
          mt: 3,
          mx: {
            xs: 2, // For mobile
            sm: 5, // For tablet
            md: 25, // For small desktop
            lg: 40, // For large desktop
          },
        }}
      >
        <h2 className="text-center">Dashboard</h2>
        <hr />
        <br />

        <Alert color="secondary">
          <Nav className="text-center  flex-nowrap border-0" tabs fill>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "1" })}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                <span> General CMS Statistics </span>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "2" })}
                onClick={() => {
                  this.toggle("2");
                }}
              >
                <span> Suspected Drug Death Statistics </span>
              </NavLink>
            </NavItem>

            {/* Commenting this out due to frequent misinterpretation of data in public reports */}
            {/* <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "3" })}
                onClick={() => {
                  this.toggle("3");
                }}
              >
                <span> Confirmed Drugs </span>
              </NavLink>
            </NavItem> */}

            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "4" })}
                onClick={() => {
                  this.toggle("4");
                }}
              >
                <span> Suicide Statistics </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "5" })}
                onClick={() => {
                  this.toggle("5");
                }}
              >
                <span> Homicide Statistics</span>
              </NavLink>
            </NavItem>
          </Nav>
        </Alert>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <GeneralCMStatistics />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                <SuspectedDrugsStatistics />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col sm="12">
                <ConfirmedDrugsStatistics />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="4">
            <Row>
              <Col sm="12">
                <SuicideStatistics />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="5">
            <Row>
              <Col sm="12">
                <HomicideStatistics />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </Box>
    );
  }
}

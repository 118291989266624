import React from "react";
import {
  Card,
  Button,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  CardDeck,
  CardSubtitle,
  CardBody,
  CardGroup,
} from "reactstrap";
import { Table } from "reactstrap";
import { Container, Row, Col } from "reactstrap";

import { ConfirmedDrugsByYearTabs } from "./ConfirmedDrugsByYearTabs";

import StateDrugTotal2015 from "../../components/Charts/ConfirmedDrugsCharts/2015-StateDrugTotal";
import StateDrugTotal2016 from "../../components/Charts/ConfirmedDrugsCharts/2016-StateDrugTotal";
import StateDrugTotal2017 from "../../components/Charts/ConfirmedDrugsCharts/2017-StateDrugTotal";
import StateDrugTotal2018 from "../../components/Charts/ConfirmedDrugsCharts/2018-StateDrugTotal";
import StateDrugTotal2019 from "../../components/Charts/ConfirmedDrugsCharts/2019-StateDrugTotal";
import StateDrugTotal2020 from "../../components/Charts/ConfirmedDrugsCharts/2020-StateDrugTotal";
import DrugSubstances2015_2019_MultiLine from "../../components/Charts/ConfirmedDrugsCharts/2015-2019DrugSubstancesMultiLine";

export class ConfirmedDrugsStatistics extends React.Component {
  static displayName = ConfirmedDrugsStatistics.name;

  render() {
    return (
      <div>
        <div>
          <br />
          <Container>
            <Row>
              <Col sm="12">
                <Card body>
                  {/*Change back to 2015-2020 after 2020 numbers are updated*/}
                  <CardTitle className="text-center">
                    <strong>
                      2015-2019 Drug Overdose Substance Comparison
                    </strong>
                  </CardTitle>
                  <DrugSubstances2015_2019_MultiLine />
                </Card>
              </Col>
            </Row>
            <br />
            <Row>
              <ConfirmedDrugsByYearTabs />
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

import React from 'react';
import { Card, Button, CardImg, CardImgOverlay, CardTitle, CardText, CardDeck, CardSubtitle, CardBody, CardGroup } from 'reactstrap';
import { Table } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';

import { AnnualHomicideCountyMultiBar } from '../../components/Charts/DashboardCharts/HomicideCharts/AnnualHomicidesByCountyMultiBar';

export class HomicideStatistics extends React.Component {
    static displayName = HomicideStatistics.name;

    render() {
        return (
            <div>
                <div>
                    <br />
                    <Container>
                        <Row>
                            <Col sm="12">
                            <Card body>
                                <CardTitle className="text-center"><strong>Annual Homicides By County</strong></CardTitle>
                                <AnnualHomicideCountyMultiBar />
                                </Card>
                                </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
};
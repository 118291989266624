import React from "react";
import ConfirmedDrugs2017Table from "../../../components/Charts/ConfirmedDrugsCharts/2017-ConfirmedDrugsTable";
import {
  Card,
  Button,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  CardDeck,
  CardSubtitle,
  CardBody,
  CardGroup,
} from "reactstrap";
import { Table } from "reactstrap";
import ConfirmedDrugs2017BarChart from "../../../components/Charts/ConfirmedDrugsCharts/2017-ConfirmedDrugsBarChart";
import { Container, Row, Col } from "reactstrap";
import StateDrugTotal2017 from "../../../components/Charts/ConfirmedDrugsCharts/2017-StateDrugTotal";

export class ConfirmedDrugs2017 extends React.Component {
  static displayName = ConfirmedDrugs2017.name;

  render() {
    return (
      <div className="text-center">
        <Card body>
          <i>
            * To prepare these charts, the OCSME conducted an analysis of the
            decedent’s history, death scene investigation, autopsy report and
            toxicology results on all cases categorized as drug related deaths.
            Any cases positive for morphine that were found to be likely due to
            heroin (e.g., illicit drug paraphernalia at the scene, presence of a
            small amount of codeine in the blood, etc.) were classified as
            heroin related deaths. All cases positive for multiple drugs were
            included in each relevant column.*
          </i>
        </Card>
        <br />
        <Card body>
          <CardTitle className="text-center">
            <strong>Confirmed Drug Overdose Substances by County</strong>
          </CardTitle>
          <ConfirmedDrugs2017BarChart />
        </Card>
        <br />
        <Card body>
          <CardTitle className="text-center">
            <strong>Confirmed Drug Overdose Substances by County</strong>
          </CardTitle>
          <ConfirmedDrugs2017Table />
        </Card>
        <br />
        <Card body>
          <CardTitle className="text-center">
            <strong>2017 Confirmed Drug Overdose Substance Totals</strong>
          </CardTitle>
          <StateDrugTotal2017 />
        </Card>
        <br />
        <div>
          <Container>
            <Row>
              <Col>
                <Card>
                  <CardImg
                    alt="Card image cap"
                    src={require("../../../assets/img/HEATMaps/NJOD2017.JPG")}
                    top
                    width="10%"
                  />
                  <CardBody>
                    <CardText>
                      This map illustrates the fatalities due to overdosing in
                      2017 through out the State of New Jersey.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card>
                  <CardImg
                    alt="Card image cap"
                    top
                    src={require("../../../assets/img/HEATMaps/NJPerCapita2017.JPG")}
                    width="10%"
                  />
                  <CardBody>
                    <CardText>
                      This map illustrates the fatalities due to overdosing per
                      capita in 2017 through out the State of New Jersey.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

import { Box } from "@mui/material";

export function CMSAccountRequest() {
  return (
    <Box
      sx={{
        mt: 3,
        mx: {
          xs: 2, // For mobile
          sm: 5, // For tablet
          md: 25, // For small desktop
          lg: 40, // For large desktop
        },
      }}
    >
      <div>
        <br />

        <div className="text-center">
          <h6>NJ Office of the Chief State Medical Examiner</h6>
          <hr />
        </div>

        <iframe
          width="100%"
          height="1500px"
          src="https://forms.office.com/Pages/ResponsePage.aspx?id=0cN2UAI4n0uzauCkG9ZCp2z2a1U6pqBDpz0BzJg06SdUQjFSVklVVTc4WjE1OVE3STc3ME9ZRDhPQi4u"
        ></iframe>
      </div>
    </Box>
  );
}

import React, { Component } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement, PointElement, LineElement } from 'chart.js';
import { Line } from "react-chartjs-2";

export var chartData = {
    labels,
    datasets: [{
        label: '# of Cases',
        data: [],
        backgroundColor: "darkred",
        borderColor: "red"
    }],
};

export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: 'top',
        },

        labels: {
            position: 'top',
        },
        title: {
            display: false,
        },
    },
    scales: {
        y: {
            grace: "10%",
            beginAtZero: true
        }
    }
};

var labels = [];

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    PointElement,
    LineElement,
);

export class AnnualCaseNumbersLine extends Component {
    static displayName = AnnualCaseNumbersLine.name;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
        };
    }

    async componentDidMount() {
        const fetchURL = process.env.REACT_APP_API_URL + 'GetAnnualCaseCount';  
        const response = await fetch(fetchURL, {
            method: "GET"
        });

        const responseData = await response.json();

        var ChartLabels = [];
        var ValuesArray = [];

        const title = responseData.startDate + " through " + responseData.endDate;
        options.plugins.title.text = title;

        responseData.data.forEach(dataPoint => {
            ChartLabels.push(dataPoint.year);
            ValuesArray.push(dataPoint.count);
        });

        chartData.labels = ChartLabels;
        chartData.datasets[0].data = ValuesArray;

        await this.setState({
            HomicideData: responseData,
            isLoading: false,
            chartData: chartData,
        });
    }

    render() {
        //Display temporary loading prompt
        if (this.state.isLoading) {
            return (
                <div className="text-center">
                    <br />
                    Please wait, Reading Data...<hr />
                </div>
            )
        }

        return (
            <Line options={options} data={this.state.chartData} />
        );
    }
}


import { Box, Typography } from "@mui/material";

export function SuicidesInfo() {
  return (
    <Box
      sx={{
        mt: 3,
        mx: {
          xs: 2, // For mobile
          sm: 5, // For tablet
          md: 25, // For small desktop
          lg: 40, // For large desktop
        },
      }}
    >
      <h2 className="text-center">Suicides</h2>

      <hr />
      <Typography variant="body2" gutterBottom>
        <p>
          Suicide is defined as the act or instance of taking one's own life voluntarily and intentionally. It is a
          serious public health problem that can have lasting harmful effects on individuals, families, and communities.
          According to the World Health Organization (WHO), more than 700,000 people die due to suicide every year.
          Suicide is also a leading cause of death in the United States, being responsible for nearly 46,000 deaths in
          2020. Unfortunately, this is only the tip of the iceberg, as during the same year an estimated 12.2 million
          adults seriously thought about suicide, 3.2 million made a plan, and 1.2 million attempted suicide. In 2016,
          the suicide rate in New Jersey stood at 9.7 per 100,000 among New Jersey residents ages 10 and older, making
          it the lowest suicide rate in the country.
        </p>
        <br />
        <p>
          Frequently, suicide occurs in the context of a major depressive episode but may also result from a substance
          use or other disorder. It may also occur in the absence of any psychiatric disorder, especially in untenable
          situations such as loss of a loved one due to separation or death, declining health, or events such as
          financial ruin.
        </p>
        <br />
        <p>
          The cause of death in most cases of suicide is often clearly recognized.The determination of the manner of
          death requires a thorough investigation to demonstrate that the injury was self-inflicted with the intention
          to take one’s own life.Such a ruling can have serious personal, religious, or financial implications, making
          suicide the most commonly disputed manner of death.A tool often used to assist in the determination of the
          manner of death is the psychological autopsy, which involves collecting all available information on the
          deceased via structured interviews of family members, relatives, or friends, as well as attending health care
          personnel.
        </p>
        <br />
        <p>
          Statistics show that some groups are at greater risk for suicide. These include veterans, people who live in
          rural areas, sexual and gender minorities, teenagers and middle-aged adults, and tribal populations.
          Additionally, people who have experienced violence, including adverse childhood experiences, bullying, or
          sexual violence, have a higher suicide risk. Easy access to lethal means also impacts suicide rate and the
          method used to complete suicide.
        </p>
        <br />
        <p>
          The goal of suicide prevention is to reduce factors that increase risk while promoting factors that enhance
          resilience. Many people still feel uncomfortable talking about suicide, however, it is important to increase
          awareness about suicide's pervasive threat if we are to provide timely help to prevent suicide. If you or
          someone you know is in an emergency, please reach out for assistance.
        </p>
        <p>
          <strong>Crisis Resources</strong>
          <ul>
            <li>
              If you or someone you know is in an emergency,call <b> 911 </b> immediately.{" "}
            </li>
            <li>
              If you are in crisis or are experiencing difficult or suicidal thoughts, call the Suicide and Crisis
              Lifeline at <b>988</b>
            </li>
            <li>
              For more Information, please refer to{" "}
              <b>
                <a href="https://988lifeline.org/">https://988lifeline.org/</a>
              </b>
            </li>
          </ul>
        </p>
      </Typography>
    </Box>
  );
}

import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "top",
    },

    labels: {
      position: "top",
    },
    title: {
      display: false,
      text: "Total of NJ Confirmed Drug Involved Overdose Deaths in 2016",
    },
  },
};

const labels = [
  "Cocaine",
  "Methadone",
  "Oxycodone",
  "Morphine",
  "Fentanyl Analog",
  "Fentanyl",
  "Heroin",
];

const data = {
  labels,
  datasets: [
    {
      label: "2016",
      data: [637, 126, 349, 45, 164, 818, 1347],
      backgroundColor: "#4CAF50",
    },
  ],
};

const StateDrugTotal2016 = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  // Display temporary loading prompt
  if (isLoading) {
    return (
      <div>
        <br />
        <p>Please wait, Reading Data...</p>
        <hr />
      </div>
    );
  }

  return (
    <div>
      <Bar options={options} data={data} />
    </div>
  );
};

export default StateDrugTotal2016;

import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "top",
    },

    labels: {
      position: "top",
    },
    title: {
      display: false,
      text: "Total of NJ Confirmed Drug Involved Overdose Deaths in 2019",
    },
  },
};

const labels = [
  "Ethanol",
  "Benzodiazepine",
  "Methamphetamine",
  "Cocaine",
  "Methadone",
  "Oxycodone",
  "Morphine",
  "Fentanyl Analog",
  "Fentanyl",
  "Heroin",
];

const data = {
  labels,
  datasets: [
    {
      label: "2019",
      data: [600, 457, 148, 970, 116, 192, 57, 437, 2266, 1082],
      backgroundColor: "#7986CB",
    },
  ],
};

function StateDrugTotal2019() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return (
      <div>
        <br />
        <p>Please wait, Reading Data...</p>
        <hr />
      </div>
    );
  }

  return (
    <div>
      <Bar options={options} data={data} />
    </div>
  );
}

export default StateDrugTotal2019;

import React from 'react';
import { Card, Button, CardImg, CardImgOverlay, CardTitle, CardText, CardDeck, CardSubtitle, CardBody, CardGroup } from 'reactstrap';
import { Table } from 'reactstrap';

var labels = [];
export var tableData = {
    labels,
    datasets: []
};




export class CMSCasesReportedByCounty2022Table extends React.Component {
    static displayName = CMSCasesReportedByCounty2022Table.name;


    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            tableRows: [],
        };
    }

    renderTableRows() {
        return this.state.tableRows.map(function (o) {
            return <tr key={"CountyName-" + o.countyName}>
                <th scope="row">
                    {o.countyName}
                </th>
                <td>{o.accepted}</td>
                <td>{o.caseRelease}</td>
                <td>{o.facilitatedRelease}</td>
                <td>{o.storageOnly}</td>
                <td>{o.transfer}</td>

            </tr>
        });
    }


    async componentDidMount() {

        const fetchURL = process.env.REACT_APP_API_URL + 'GetAcceptedCountyCasesByYear';    
        const response = await fetch(fetchURL, {
            method: "GET"
        });

        const responseData = await response.json();

        await this.setState({
            tableRows: responseData,
            isLoading: false,
        });

    }

    render() {

        //Display temporary loading prompt
        if (this.state.isLoading) {
            return (
                <div className="text-center">
                    <br />
                    Please wait, Reading Data...<hr />
                </div>
            )
        }

        return (
            <Table bordered striped>
                <thead style={{ backgroundColor: "black" }}>
                    <tr style={{ color: "white" }}>
                        <td><strong>County</strong></td>
                        <td><strong>Accepted<br />Cases</strong></td>
                        <td><strong>Cases<br />Released</strong></td>
                        <td><strong>Facilitated<br />Release</strong></td>
                        <td><strong>Storage<br />Only</strong></td>
                        <td><strong>Transfer<br />Cases</strong></td>
                    </tr>
                </thead>
                <tbody>
                    {this.renderTableRows()}
                </tbody>
            </Table>
        );
        
    }
};
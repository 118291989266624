import { Box } from "@mui/material";

import { Container, Row } from "reactstrap";
export function Glossary() {
  return (
    <Box
      sx={{
        mt: 3,
        mx: {
          xs: 2, // For mobile
          sm: 5, // For tablet
          md: 25, // For small desktop
          lg: 40, // For large desktop
        },
      }}
    >
      <h2 className="text-center">Glossary</h2>
      <hr />

      <Container>
        <Row>
          <div>
            <p>
              <b>Accident</b> <br />
              The manner of death used when there is an unintentional death by injury that may or may not involve
              negligence by a victim or any other individual or an unnatural, violent death resulting from an event
              occurring by chance or unintended cause. It is usually a sudden, unexpected, and unforeseen occurrence and
              it may refer to physical injuries, toxic effects, or environmental conditions.
              <br /> <br />
              <b>Autopsy</b> <br />
              A medical procedure which involves the external and internal examination (dissection) to include the head
              and cranial contents, the neck, thorax, abdomen, and any other focus on, or within, the body which will
              contribute to determining the cause and manner of death. The autopsy may or may not also involve
              toxicology and microscopic examination of the tissues.
              <br /> <br />
              <b>Case</b> <br />
              A human death that is reported and/or investigated by the Medical Examiner’s Office.
              <br /> <br />
              <b>Case File</b> <br />
              An internal file containing all pertinent information including the autopsy report, investigatory reports,
              toxicology reports, body diagram(s), inventory of effects, body release form(s), and any other case
              specific information.
              <br /> <br />
              <b>Cause of Death (COD)</b> <br />
              The underlying disease or injury that directly eventuates in death.
              <br /> <br />
              <b>Ethnicity</b> <br />
              A self-reported designation of racial or ethnic origin in the following categories: White, Black,
              Hispanic, or Other.
              <br /> <br />
              <b>Homicide</b> <br />
              The manner of death used when there is a violent death from an intentional act of another individual,
              whether or not the individual responsible is prosecuted.
              <br />
              <br />
              <b>Manner of Death (MOD)</b> <br />
              A classification of death based upon the cause of death and the circumstances surrounding the death.
              Categories include: Natural, Homicide, Suicide, Accident, Undetermined, and Pending.
              <br /> <br />
              <b>Medical Examiner</b> <br />
              A New Jersey licensed physician with specialized training in forensic pathology authorized by the State
              Medical Examiner to perform forensic examinations, autopsies, or views in the State of New Jersey and
              working under the auspices of a governmental medical examiner office.
              <br /> <br />
              <b>Medicolegal Death Investigator (MLDI)</b> <br />
              Under the direction of a medical examiner, investigates deaths reported to the office, collects
              information, medical specimens, documentation, and other data relevant to the matter of the decedent’s
              demise for the medical examiner to use in the determination of the cause and manner of death.
              <br /> <br />
              <b>Medical Records</b> <br />
              All charts, notes, files, or other data containing medical information obtained from physicians, clinics,
              hospitals, extended care facilities, or other sources who rendered care to the decedent (such records are
              confidential at all times).
              <br /> <br />
              <b>Natural</b> <br />
              The manner of death used when there is a nonviolent death resulting from disease.
              <br /> <br />
              <b>Office of the Chief State Medical Examiner</b> <br />
              The office and facilities utilized by the State Medical Examiner in the performance of his/her duties
              <br /> <br />
              <b>Pending</b> <br />
              A case where the death certificate has been issued with the cause and manner of death not specified and so
              indicated by the term “pending”.
              <br /> <br />
              <b>Police Investigative Report</b> <br />
              A report submitted by police to the medical examiner’s office that is in reference to a specific case
              giving pertinent background information to a determination of cause and manner of death.
              <br /> <br />
              <b>Regional Medical Examiner</b> <br />
              The chief medical examiner of a regional medical examiner facility who is responsible for medicolegal
              death investigation in multiple New Jersey counties.
              <br /> <br />
              <b>Release Case (Non-Medical Examiner Case)</b> <br />
              A death reported to the medical examiner that after review is found not to come under the jurisdiction of
              the medical examiner. These cases are not further investigated nor are decedent’s remains brought to the
              medical examiner facility.
              <br /> <br />
              <b>Report of Investigation by Medical Examiner (RIME)</b>
              A form completed for the purpose of reporting medical examiner findings to the State Medical Examiner and
              to the County Prosecutor as required by statutes and regulations.
              <br /> <br />
              <b>Scene</b> <br />
              The location of a fatality or injury. A case may have more than one scene (i.e. place of injury and place
              of death).
              <br /> <br />
              <b>State Medical Examiner</b> <br />
              A medical examiner appointed by the Governor and confirmed by the Senate that heads the Office of the
              State Medical Examiner as designated by New Jersey law and has general oversight of medicolegal death
              investigation in the State.
              <br /> <br />
              <b>Storage Case</b> <br />
              A case that is reported to and released by the Medical Examiner’s office but at the time of the
              investigation, there is no family or family has not been able to be reached in order to claim the body.
              <br /> <br />
              <b>Suicide</b> <br />
              The manner of death used when a person takes their own life by any means, including chemical or physical,
              with the intent to cause self-harm or with reckless and wanton endangerment (i.e. Russian roulette).
              <br /> <br />
              <b>Toxicology Report</b> <br />
              The findings of toxicological or other chemical tests performed upon tissue or body fluid(s) from a
              decedent. Substances tested may include toxins, alcohol, drugs of abuse, prescription drugs, their
              metabolites, or clinical chemistries.
              <br /> <br />
              <b>Undetermined</b> <br />
              A case where there is insufficient information or evidence to express a cause and/or manner of death.
              <br /> <br />
              <b>View</b> <br />
              The external examination of the unclothed external surfaces of the decedent’s body with appropriate
              specimens acquired for toxicological screening and other ancillary testing as appropriate for the case
              that is performed by a medical examiner in accepted cases not requiring an autopsy
              <br /> <br />
            </p>
          </div>
        </Row>
      </Container>
    </Box>
  );
}

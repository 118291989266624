
import React, { Component } from 'react';
import { Container, Navbar, NavbarBrand, NavbarText, NavItem, NavLink, Nav } from 'reactstrap';
import { Link } from 'react-router-dom';
import OCSMELogo from "../assets/img/OCSME-Logo.png";

export class Header extends Component {
    static displayName = Header.name;

    render() {
        return (

            <div>
                <Navbar color="light" light>
                    <NavbarBrand className="text-dark text-nowrap" tag={Link} to="/"><img height="75" src={OCSMELogo} /> <strong>Office of the Chief State Medical Examiner</strong></NavbarBrand>
               
                </Navbar>
            </div>
        );
    }
}

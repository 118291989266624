import React, { Component } from "react";
import HTabs from "../../components/HTabs";
import {
  Alert,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";

import { ConfirmedDrugs2020 } from "../AnnualReports/2020-Reports/2020-ConfirmedDrugs.js";
import { ConfirmedDrugs2019 } from "../AnnualReports/2019-Reports/2019-ConfirmedDrugs.js";
import { ConfirmedDrugs2018 } from "../AnnualReports/2018-Reports/2018-ConfirmedDrugs.js";
import { ConfirmedDrugs2017 } from "../AnnualReports/2017-Reports/2017-ConfirmedDrugs.js";
import { ConfirmedDrugs2016 } from "../AnnualReports/2016-Reports/2016-ConfirmedDrugs.js";
import ConfirmedDrugs2015 from "../AnnualReports/2015-Reports/2015-ConfirmedDrugs";
import "./ConfirmedDrug.css";

export class ConfirmedDrugsByYearTabs extends Component {
  static displayName = ConfirmedDrugsByYearTabs.name;

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "2", // Change this back to 1 once 2020 numbers are updated
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    //loading state

    return (
      <div>
        <br />
        <Alert color="secondary">
          <Nav className="text-center  flex-nowrap border-0" tabs fill>
            {/*Removing temporarily while 2020 numbers are updated*/}
            {/*<NavItem>*/}
            {/*    <NavLink*/}
            {/*        className={classnames({ active: this.state.activeTab === '1' })}*/}
            {/*        onClick={() => { this.toggle('1'); }}*/}
            {/*    >*/}
            {/*        2020*/}
            {/*    </NavLink>*/}
            {/*</NavItem>*/}
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "2" })}
                onClick={() => {
                  this.toggle("2");
                }}
              >
                2019
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "3" })}
                onClick={() => {
                  this.toggle("3");
                }}
              >
                2018
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "4" })}
                onClick={() => {
                  this.toggle("4");
                }}
              >
                2017
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "5" })}
                onClick={() => {
                  this.toggle("5");
                }}
              >
                2016
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "6" })}
                onClick={() => {
                  this.toggle("6");
                }}
              >
                2015
              </NavLink>
            </NavItem>
          </Nav>
        </Alert>
        <br />
        <TabContent activeTab={this.state.activeTab}>
          {/*Removing temporarily while 2020 numbers are updated*/}
          {/*<TabPane tabId="1">*/}
          {/*    <Row>*/}
          {/*        <Col sm="12">*/}
          {/*            <ConfirmedDrugs2020 />*/}
          {/*        </Col>*/}
          {/*    </Row>*/}
          {/*</TabPane>*/}
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                <ConfirmedDrugs2019 />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col sm="12">
                <ConfirmedDrugs2018 />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="4">
            <Row>
              <Col sm="12">
                <ConfirmedDrugs2017 />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="5">
            <Row>
              <Col sm="12">
                <ConfirmedDrugs2016 />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="6">
            <Row>
              <Col sm="12">
                <ConfirmedDrugs2015 />
              </Col>
            </Row>
          </TabPane>
          {/* 
                    <TabPane tabId="6">
                        <Row>
                            <Col sm="6">
                                <Card body>
                                    <CardTitle>Special Title Treatment</CardTitle>
                                    <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
                                    <Button>Go somewhere</Button>
                                </Card>
                            </Col>
                            <Col sm="6">
                                <Card body>
                                    <CardTitle>Special Title Treatment</CardTitle>
                                    <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
                                    <Button>Go somewhere</Button>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                    */}
        </TabContent>
      </div>
    );
  }
}

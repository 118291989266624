import React from "react";
import ConfirmedDrugs2015BarChart from "../../../components/Charts/ConfirmedDrugsCharts/2015-ConfirmedDrugsBarChart";
import ConfirmedDrugs2015Table from "../../../components/Charts/ConfirmedDrugsCharts/2015-ConfirmedDrugsTable";
import {
  Card,
  Button,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  CardDeck,
  CardSubtitle,
  CardBody,
  CardGroup,
  Row,
} from "reactstrap";
import { Container, Col } from "reactstrap";
import { Table } from "reactstrap";
import StateDrugTotal2015 from "../../../components/Charts/ConfirmedDrugsCharts/2015-StateDrugTotal";

function ConfirmedDrugs2015() {
  return (
    <div className="text-center">
      <Card body>
        <i>
          * To prepare these charts, the OCSME conducted an analysis of the
          decedent’s history, death scene investigation, autopsy report, and
          toxicology results on all cases categorized as drug related deaths.
          Any cases positive for morphine that were found to be likely due to
          heroin (e.g., illicit drug paraphernalia at the scene, presence of a
          small amount of codeine in the blood, etc.) were classified as
          heroin-related deaths. All cases positive for multiple drugs were
          included in each relevant column.*
        </i>
      </Card>
      <br />
      <Card body>
        <CardTitle className="text-center">
          <strong>Confirmed Drug Overdose Substances by County</strong>
        </CardTitle>
        <ConfirmedDrugs2015BarChart />
      </Card>
      <br />
      <Card body>
        <CardTitle className="text-center">
          <strong>Confirmed Drug Overdose Substances by County</strong>
        </CardTitle>
        <ConfirmedDrugs2015Table />
      </Card>
      <br />
      <div>
        <Container>
          <Row>
            <Col>
              <Card>
                <img
                  alt="Card image cap"
                  src={require("../../../assets/img/HEATMaps/NJOD2015.JPG")}
                  width="10%"
                />
                <CardBody>
                  <CardText>
                    This map illustrates the fatalities due to overdosing in
                    2015 throughout the State of New Jersey.
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card>
                <img
                  alt="Card image cap"
                  src={require("../../../assets/img/HEATMaps/NJPerCapita2015.JPG")}
                  width="10%"
                />
                <CardBody>
                  <CardText>
                    This map illustrates the fatalities due to overdosing per
                    capita in 2015 throughout the State of New Jersey.
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default ConfirmedDrugs2015;

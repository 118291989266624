import React from "react";
import {
  Card,
  Button,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  CardDeck,
  CardSubtitle,
  CardBody,
  CardGroup,
} from "reactstrap";
import { Table } from "reactstrap";
import { Container, Row, Col } from "reactstrap";

function ConfirmedDrugs2020Table() {
  return (
    <div>
      <Table centered bordered>
        <thead style={{ backgroundColor: "black " }}>
          <tr style={{ color: "white" }}>
            <th className="text-center dark-black">County</th>
            <th className="text-center dark-black">Total</th>
            <th className="text-center dark-black">Heroin</th>
            <th className="text-center dark-black">Fentanyl</th>
            <th className="text-center dark-black">Fentanyl Analog</th>
            <th className="text-center dark-black">Morphine</th>
            <th className="text-center dark-black">Oxycodone</th>
            <th className="text-center dark-black">Methadone</th>
            <th className="text-center dark-black">Cocaine</th>
            <th className="text-center dark-black">Methamphetamine</th>
            <th className="text-center dark-black">Benzodiazepine</th>
            <th className="text-center dark-black">Ethanol</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Atlantic</th>
            <td>189</td>
            <td>48</td>
            <td>152</td>
            <td>17</td>
            <td>4</td>
            <td>9</td>
            <td>14</td>
            <td>64</td>
            <td>19</td>
            <td>12</td>
            <td>50</td>
          </tr>
          <tr>
            <th scope="row">Bergen</th>
            <td>152</td>
            <td>44</td>
            <td>122</td>
            <td>11</td>
            <td>8</td>
            <td>14</td>
            <td>4</td>
            <td>48</td>
            <td>9</td>
            <td>29</td>
            <td>37</td>
          </tr>
          <tr>
            <th scope="row">Burlington</th>
            <td>137</td>
            <td>14</td>
            <td>109</td>
            <td>5</td>
            <td>5</td>
            <td>14</td>
            <td>4</td>
            <td>29</td>
            <td>19</td>
            <td>7</td>
            <td>16</td>
          </tr>
          <tr>
            <th scope="row">Camden</th>
            <td>292</td>
            <td>59</td>
            <td>250</td>
            <td>33</td>
            <td>0</td>
            <td>24</td>
            <td>11</td>
            <td>130</td>
            <td>27</td>
            <td>33</td>
            <td>52</td>
          </tr>
          <tr>
            <th scope="row">Cape May</th>
            <td>50</td>
            <td>8</td>
            <td>38</td>
            <td>5</td>
            <td>0</td>
            <td>0</td>
            <td>4</td>
            <td>7</td>
            <td>10</td>
            <td>5</td>
            <td>9</td>
          </tr>
          <tr>
            <th scope="row">Cumberland</th>
            <td>71</td>
            <td>7</td>
            <td>55</td>
            <td>4</td>
            <td>0</td>
            <td>2</td>
            <td>3</td>
            <td>30</td>
            <td>10</td>
            <td>5</td>
            <td>14</td>
          </tr>
          <tr>
            <th scope="row">Essex</th>
            <td>347</td>
            <td>106</td>
            <td>297</td>
            <td>21</td>
            <td>0</td>
            <td>14</td>
            <td>13</td>
            <td>146</td>
            <td>7</td>
            <td>23</td>
            <td>85</td>
          </tr>
          <tr>
            <th scope="row">Gloucester</th>
            <td>129</td>
            <td>22</td>
            <td>114</td>
            <td>11</td>
            <td>0</td>
            <td>13</td>
            <td>9</td>
            <td>44</td>
            <td>25</td>
            <td>26</td>
            <td>20</td>
          </tr>
          <tr>
            <th scope="row">Hudson</th>
            <td>164</td>
            <td>57</td>
            <td>133</td>
            <td>11</td>
            <td>0</td>
            <td>6</td>
            <td>8</td>
            <td>51</td>
            <td>6</td>
            <td>11</td>
            <td>42</td>
          </tr>
          <tr>
            <th scope="row">Hunterdon</th>
            <td>12</td>
            <td>4</td>
            <td>8</td>
            <td>0</td>
            <td>0</td>
            <td>2</td>
            <td>1</td>
            <td>1</td>
            <td>1</td>
            <td>4</td>
            <td>2</td>
          </tr>
          <tr>
            <th scope="row">Mercer</th>
            <td>117</td>
            <td>24</td>
            <td>97</td>
            <td>17</td>
            <td>2</td>
            <td>8</td>
            <td>6</td>
            <td>44</td>
            <td>6</td>
            <td>17</td>
            <td>27</td>
          </tr>
          <tr>
            <th scope="row">Middlesex</th>
            <td>198</td>
            <td>68</td>
            <td>173</td>
            <td>32</td>
            <td>4</td>
            <td>11</td>
            <td>8</td>
            <td>53</td>
            <td>8</td>
            <td>42</td>
            <td>42</td>
          </tr>
          <tr>
            <th scope="row">Monmouth</th>
            <td>172</td>
            <td>72</td>
            <td>140</td>
            <td>16</td>
            <td>2</td>
            <td>18</td>
            <td>11</td>
            <td>55</td>
            <td>3</td>
            <td>52</td>
            <td>32</td>
          </tr>
          <tr>
            <th scope="row">Morris</th>
            <td>96</td>
            <td>41</td>
            <td>77</td>
            <td>12</td>
            <td>0</td>
            <td>9</td>
            <td>6</td>
            <td>26</td>
            <td>3</td>
            <td>38</td>
            <td>26</td>
          </tr>
          <tr>
            <th scope="row">Ocean</th>
            <td>219</td>
            <td>82</td>
            <td>158</td>
            <td>16</td>
            <td>5</td>
            <td>26</td>
            <td>10</td>
            <td>52</td>
            <td>12</td>
            <td>36</td>
            <td>21</td>
          </tr>
          <tr>
            <th scope="row">Passaic</th>
            <td>184</td>
            <td>56</td>
            <td>146</td>
            <td>14</td>
            <td>0</td>
            <td>6</td>
            <td>10</td>
            <td>76</td>
            <td>0</td>
            <td>12</td>
            <td>38</td>
          </tr>
          <tr>
            <th scope="row">Salem</th>
            <td>39</td>
            <td>3</td>
            <td>34</td>
            <td>1</td>
            <td>0</td>
            <td>0</td>
            <td>2</td>
            <td>14</td>
            <td>7</td>
            <td>3</td>
            <td>6</td>
          </tr>
          <tr>
            <th scope="row">Somerset</th>
            <td>58</td>
            <td>20</td>
            <td>48</td>
            <td>6</td>
            <td>0</td>
            <td>5</td>
            <td>4</td>
            <td>15</td>
            <td>1</td>
            <td>4</td>
            <td>14</td>
          </tr>
          <tr>
            <th scope="row">Sussex</th>
            <td>44</td>
            <td>19</td>
            <td>37</td>
            <td>4</td>
            <td>0</td>
            <td>2</td>
            <td>1</td>
            <td>16</td>
            <td>1</td>
            <td>13</td>
            <td>11</td>
          </tr>
          <tr>
            <th scope="row">Union</th>
            <td>142</td>
            <td>52</td>
            <td>116</td>
            <td>24</td>
            <td>1</td>
            <td>11</td>
            <td>14</td>
            <td>55</td>
            <td>2</td>
            <td>27</td>
            <td>46</td>
          </tr>
          <tr>
            <th scope="row">Warren</th>
            <td>37</td>
            <td>16</td>
            <td>32</td>
            <td>4</td>
            <td>0</td>
            <td>4</td>
            <td>3</td>
            <td>8</td>
            <td>6</td>
            <td>8</td>
            <td>6</td>
          </tr>
          <tr>
            <th>TOTAL</th>
            <th>2849</th>
            <th>822</th>
            <th>2336</th>
            <th>264</th>
            <th>31</th>
            <th>198</th>
            <th>146</th>
            <th>964</th>
            <th>182</th>
            <th>407</th>
            <th>596</th>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default ConfirmedDrugs2020Table;

import React, { Component } from 'react';
import { TabContent, TabPane, Nav, Table } from 'reactstrap';
import Button from "@mui/material/Button";

export class AnnualDrugDeathMonthCountyMultiLine extends Component {
    static displayName = AnnualDrugDeathMonthCountyMultiLine.name;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            activeTab: 0,
            tableRows: []
        };

        this.toggle = this.toggle.bind(this);
    }

    async componentDidMount() {
        const fetchURL = process.env.REACT_APP_API_URL + 'GetAnnualSuspectedDrugDeathsByCountyByMonth';   
        const response = await fetch(fetchURL, {
            method: "GET"
        });

        const responseData = await response.json();

        this.setState({ tableRows: responseData, isLoading: false });
    }

    toggle(tab) {
        if (this.state.activeTab != tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        //Display temporary loading prompt
        if (this.state.isLoading) {
            return (
                <div className="text-center">
                    <br />
                    Please wait, Reading Data...<hr />
                </div>
            )
        }

        var navButtons = [];
        var navContent = [];

        for (let i = 0; i < this.state.tableRows.length; i++) {
            navButtons.push(
                <Button variant="contained" onClick={() => { this.toggle(i); }} style={{ width: "100%", margin: 5 }}>
                    {this.state.tableRows[i].year}
                </Button>
            );
            navContent.push(
                <TabPane tabId={i}>
                    <p style={{ textAlign: 'center', width: '100%', marginLeft: 'auto', marginRight: 'auto', marginTop: 10, fontSize: 'smaller', fontWeight: 700, color: 'gray' }}>{this.state.tableRows[i].startDate} through {this.state.tableRows[i].endDate}</p>

                    <Table bordered striped>
                        <thead style={{ backgroundColor: "black" }}>
                            <tr style={{ color: "white" }}>
                                <td><strong>County</strong></td>
                                <td><strong>January</strong></td>
                                <td><strong>February</strong></td>
                                <td><strong>March</strong></td>
                                <td><strong>April</strong></td>
                                <td><strong>May</strong></td>
                                <td><strong>June</strong></td>
                                <td><strong>July</strong></td>
                                <td><strong>August</strong></td>
                                <td><strong>September</strong></td>
                                <td><strong>October</strong></td>
                                <td><strong>November</strong></td>
                                <td><strong>December</strong></td>
                                <td><strong>Total</strong></td>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.tableRows[i].data.map(function (o, index) {
                                var ytd = 0;
                                var bg = (index % 2 == 0) ? '#fff' : '#ddd';

                                return <tr key={o.county} style={{ backgroundColor: bg }}>
                                    <th scope="row">
                                        {o.county}
                                    </th>
                                    {o.data.map(function (r) {
                                        ytd += r.suspectedDrugCount;
                                        return <td>{r.suspectedDrugCount}</td>
                                    })}
                                    <td>{ytd}</td>
                                </tr>
                            })}
                        </tbody>
                    </Table>
                </TabPane>
            );
        }

        return (
            <div>
                <Nav className="text-center  flex-nowrap border-0" tabs fill >
                    {navButtons}
                </Nav>

                <TabContent activeTab={this.state.activeTab}>
                    {navContent}
                </TabContent>
            </div>
        );
    }
}


import React from "react";
import { YearDrugDeathAgeBar } from "../../components/Charts/DashboardCharts/SuspectedDrugDeathCharts/YearSuspectedDrugDeathsByAgeBar";
import { YearDrugDeathGenderPie } from "../../components/Charts/DashboardCharts/SuspectedDrugDeathCharts/YearSuspectedDrugDeathsByGenderPie";
import { YearDrugDeathRacePie } from "../../components/Charts/DashboardCharts/SuspectedDrugDeathCharts/YearSuspectedDrugDeathsByRacePie";
import { YearDrugDeathWeekLine } from "../../components/Charts/DashboardCharts/SuspectedDrugDeathCharts/YearSuspectedDrugDeathsByWeekLine";
import { AnnualDrugDeathMonthMultiLine } from "../../components/Charts/DashboardCharts/SuspectedDrugDeathCharts/AnnualSuspectedDrugDeathsByMonthMultiLine";
import { AnnualDrugDeathMonthCountyMultiLine } from "../../components/Charts/DashboardCharts/SuspectedDrugDeathCharts/AnnualSuspectedDrugDeathsByMonthByCountyMultiLine";
import { WeekDrugDeathAgeBar } from "../../components/Charts/DashboardCharts/SuspectedDrugDeathCharts/LastWeekSuspectedDrugDeathsByAgeBar";
import { WeekDrugDeathGenderPie } from "../../components/Charts/DashboardCharts/SuspectedDrugDeathCharts/LastWeekSuspectedDrugDeathsByGenderPie";
import { WeekDrugDeathRacePie } from "../../components/Charts/DashboardCharts/SuspectedDrugDeathCharts/LastWeekSuspectedDrugDeathsByRacePie";
import { WeekDrugDeathCountyBar } from "../../components/Charts/DashboardCharts/SuspectedDrugDeathCharts/LastWeekSuspectedDrugDeathsByCountyBar";

import {
  Card,
  Button,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  CardDeck,
  CardSubtitle,
  CardBody,
  CardGroup,
} from "reactstrap";
import { Table } from "reactstrap";
import { Container, Row, Col } from "reactstrap";

import { SuspectedVsConfirmed2019Line } from "../../components/Charts/DashboardCharts/GeneralCharts/SuspectedVsConfirmed2019Line";

export class SuspectedDrugsStatistics extends React.Component {
  static displayName = SuspectedDrugsStatistics.name;

  constructor(props) {
    super(props);

    this.state = {
      year: 0,
    };
  }

  async componentDidMount() {
    const fetchURL = "./public_data/SuspectedDrugDeaths/WeeklyDrugDeaths.json";
    const response = await fetch(fetchURL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const responseData = await response.json();

    var yearString = responseData.StartDate;
    var year = yearString.substring(yearString.length - 4, yearString.length);

    await this.setState({
      year: year,
    });
  }

  render() {
    return (
      <div>
        <div>
          <br />
          <Container>
            <div className="text-center">
              <strong>
                <i>
                  Please keep in mind that these numbers are “Suspected” and
                  will change as more information about the cases become
                  available. Finalized numbers and statistics will be available
                  in the annual report.
                  {/* *** */}
                </i>
              </strong>
            </div>
            <br />
            <Row>
              <Col sm="12">
                <Card body>
                  <CardTitle className="text-center">
                    <strong>Last Week's Suspected Drug Deaths by County</strong>
                  </CardTitle>
                  <WeekDrugDeathCountyBar />
                </Card>
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm="6">
                <Card body>
                  <CardTitle className="text-center">
                    <strong>Last Week's Suspected Drug Deaths by Race</strong>
                  </CardTitle>
                  <WeekDrugDeathRacePie />
                </Card>
              </Col>
              <Col sm="6">
                <Card body>
                  <CardTitle className="text-center">
                    <strong>Last Week's Suspected Drug Deaths by Gender</strong>
                  </CardTitle>
                  <WeekDrugDeathGenderPie />
                </Card>
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm="12">
                <Card body>
                  <CardTitle className="text-center">
                    <strong>Last Week's Suspected Drug Deaths by Age</strong>
                  </CardTitle>
                  <WeekDrugDeathAgeBar />
                </Card>
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm="12">
                <Card body>
                  <CardTitle className="text-center">
                    <strong>
                      {this.state.year} Suspected Drug Deaths by Week
                    </strong>
                  </CardTitle>
                  <YearDrugDeathWeekLine />
                </Card>
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm="12">
                <Card body>
                  <CardTitle className="text-center">
                    <strong>Annual Suspected Drug Deaths by Month</strong>
                  </CardTitle>
                  <AnnualDrugDeathMonthMultiLine />
                </Card>
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm="12">
                <Card body>
                  <CardTitle className="text-center">
                    <strong>
                      Annual Suspected Drug Deaths by Month by County
                    </strong>
                  </CardTitle>
                  <AnnualDrugDeathMonthCountyMultiLine />
                </Card>
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm="6">
                <Card body>
                  <CardTitle className="text-center">
                    <strong>
                      {this.state.year} Suspected Drug Deaths by Race
                    </strong>
                  </CardTitle>
                  <YearDrugDeathRacePie />
                </Card>
              </Col>
              <Col sm="6">
                <Card body>
                  <CardTitle className="text-center">
                    <strong>
                      {this.state.year} Suspected Drug Deaths by Gender
                    </strong>
                  </CardTitle>
                  <YearDrugDeathGenderPie />
                </Card>
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm="12">
                <Card body>
                  <CardTitle className="text-center">
                    <strong>
                      {this.state.year} Suspected Drug Deaths by Age
                    </strong>
                  </CardTitle>
                  <YearDrugDeathAgeBar />
                </Card>
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm="12">
                <Card body>
                  <CardTitle className="text-center">
                    <strong>2019 Suspected vs Confirmed Drug Overdoses</strong>
                  </CardTitle>
                  <SuspectedVsConfirmed2019Line />
                </Card>
              </Col>
            </Row>
          </Container>
          {/* <br />

          <i>
            *** Cases are classified as 'Suspected' drgu deaths at the scene
            according to medical-legal investigator discretion. These
            'Suspected' cases can help identify outbreaks in real-time. They are
            not a result of drug testing, and therefore are not confirmed 'drug
            death' cases. This data should be used with discretion, and only to
            approximate short term trends in drug-related cases.
          </i> */}
        </div>
      </div>
    );
  }
}

﻿import { Component } from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavItem,
  NavLink,
  Navbar,
  NavbarToggler,
  UncontrolledDropdown,
} from "reactstrap";

import { AiFillHome } from "react-icons/ai";
import { BsBookmarksFill, BsFillTelephoneFill } from "react-icons/bs";
import { HiDocumentReport } from "react-icons/hi";
import { MdOutlineWork, MdSpaceDashboard } from "react-icons/md";
import { RiGovernmentFill } from "react-icons/ri";
import "./NavMenu.css";

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
      dropdownOpen: false,
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  render() {
    return (
      <header>
        <Navbar className="dark-blue m-auto navbar-expand-sm navbar-toggleable-sm  " dark>
          {/*  <NavbarBrand className="text-light" tag={Link} to="/"> OCSME </NavbarBrand> */}
          <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
          <Collapse className="me-auto d-sm-inline-flex justify-content-between" isOpen={!this.state.collapsed} navbar>
            <ul className="navbar-nav flex-grow" style={{ visibility: "hidden" }}>
              {/*<NavItem>*/}
              {/*    <NavLink className="text-light"><AiOutlineLogin />Login</NavLink>*/}
              {/*</NavItem>*/}
            </ul>
            <ul className="navbar-nav flex-grow">
              <NavItem>
                <NavLink tag={Link} className="text-light" to="/">
                  <AiFillHome />
                  Home
                </NavLink>
              </NavItem>

              <NavItem>
                <UncontrolledDropdown>
                  <DropdownToggle className="text-light" nav caret>
                    <RiGovernmentFill /> About
                  </DropdownToggle>
                  <DropdownMenu end>
                    {/* Temporarily removing this tab
                                        <DropdownItem>
                                            <NavLink tag={Link} className="text-dark" to="/andrewfalzon"><strong>Dr. Andrew Falzon</strong><br /> (Chief State Medical Examiner) </NavLink>

                                        </DropdownItem>
                                        
                                        <DropdownItem divider />
                                        */}
                    <DropdownItem>
                      <NavLink tag={Link} className="text-dark" to="/ContactUs">
                        Offices & Facilities{" "}
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem>
                      <NavLink tag={Link} className="text-dark" to="/StateToxLab">
                        NJ State Tox Lab
                      </NavLink>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-light" to="/Dashboard">
                  <MdSpaceDashboard />
                  Dashboard
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-light" to="/Reports">
                  <HiDocumentReport />
                  Reports
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-light" to="/Careers">
                  <MdOutlineWork />
                  Careers
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-light" to="/ContactUs">
                  <BsFillTelephoneFill />
                  Contact Us
                </NavLink>
              </NavItem>
              <NavItem>
                <UncontrolledDropdown>
                  <DropdownToggle className="text-light" nav caret>
                    <BsBookmarksFill />
                    Resources
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem>
                      <NavLink tag={Link} className="text-dark" to="/Forms">
                        Forms
                      </NavLink>
                    </DropdownItem>

                    <DropdownItem divider />
                    <DropdownItem>
                      <NavLink tag={Link} className="text-dark" to="/StateToxLab">
                        State Tox Lab
                      </NavLink>
                    </DropdownItem>

                    <DropdownItem>
                      <NavLink tag={Link} className="text-dark" to="/MAiD">
                        MAiD
                      </NavLink>
                    </DropdownItem>

                    <DropdownItem>
                      <NavLink tag={Link} className="text-dark" to="/Training">
                        Medicolegal Training
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem divider />

                    <DropdownItem>
                      <NavLink tag={Link} className="text-dark" to="/Glossary">
                        Glossary
                      </NavLink>
                    </DropdownItem>

                    <DropdownItem>
                      <NavLink tag={Link} className="text-dark" to="/UsefulLinks">
                        Useful Links
                      </NavLink>
                    </DropdownItem>

                    <DropdownItem>
                      <NavLink tag={Link} className="text-dark" to="/FAQ">
                        FAQ
                      </NavLink>
                    </DropdownItem>

                    {/*<DropdownItem>
                                            <NavLink tag={Link} className="text-dark" to="/CentralAdmin">Admin (Authorized Users Only)</NavLink>
                                        </DropdownItem>*/}

                    {/*<DropdownItem>
                                            <NavLink tag={Link} className="text-dark" to="/Dashboard">Dashboard (REACT) </NavLink>
                                        </DropdownItem>*/}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </NavItem>
            </ul>
            <ul className="navbar-nav flex-grow">
              {/*<NavItem>*/}
              {/*    <UserBlock/>*/}
              {/*</NavItem>*/}
            </ul>
          </Collapse>
        </Navbar>
      </header>
    );
  }
}

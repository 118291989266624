import React from "react";
import {
  Card,
  Button,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  CardDeck,
  CardSubtitle,
  CardBody,
  CardGroup,
} from "reactstrap";
import { Table } from "reactstrap";

function ConfirmedDrugs2016Table() {
  return (
    <div>
      <Table centered bordered>
        <thead style={{ backgroundColor: "black " }}>
          <tr style={{ color: "white" }}>
            <th className="text-center dark-black">County</th>
            <th className="text-center dark-black">Total</th>
            <th className="text-center dark-black">Heroin</th>
            <th className="text-center dark-black">Morphine</th>
            <th className="text-center dark-black">Cocaine</th>
            <th className="text-center dark-black">Fentanyl</th>
            <th className="text-center dark-black">Analog</th>
            <th>Oxycodone</th>
            <th className="text-center dark-black">Methadone</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Atlantic</th>
            <td>171</td>
            <td>97</td>
            <td>4</td>
            <td>40</td>
            <td>64</td>
            <td>5</td>
            <td>25</td>
            <td>15</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Bergen</th>
            <td>99</td>
            <td>54</td>
            <td>1</td>
            <td>22</td>
            <td>32</td>
            <td>9</td>
            <td>13</td>
            <td>4</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Burlington</th>
            <td>96</td>
            <td>43</td>
            <td>6</td>
            <td>15</td>
            <td>23</td>
            <td>3</td>
            <td>22</td>
            <td>5</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Camden</th>
            <td>200</td>
            <td>141</td>
            <td>4</td>
            <td>75</td>
            <td>82</td>
            <td>7</td>
            <td>38</td>
            <td>12</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Cape May</th>
            <td>32</td>
            <td>15</td>
            <td>1</td>
            <td>9</td>
            <td>11</td>
            <td>2</td>
            <td>2</td>
            <td>1</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Cumberland</th>
            <td>53</td>
            <td>36</td>
            <td>0</td>
            <td>16</td>
            <td>24</td>
            <td>0</td>
            <td>4</td>
            <td>0</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Essex</th>
            <td>271</td>
            <td>170</td>
            <td>4</td>
            <td>129</td>
            <td>82</td>
            <td>8</td>
            <td>27</td>
            <td>9</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Gloucester</th>
            <td>88</td>
            <td>66</td>
            <td>1</td>
            <td>27</td>
            <td>38</td>
            <td>0</td>
            <td>15</td>
            <td>5</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Hudson</th>
            <td>127</td>
            <td>77</td>
            <td>4</td>
            <td>42</td>
            <td>34</td>
            <td>2</td>
            <td>21</td>
            <td>8</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Hunterdon</th>
            <td>20</td>
            <td>10</td>
            <td>1</td>
            <td>3</td>
            <td>2</td>
            <td>2</td>
            <td>4</td>
            <td>0</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Mercer</th>
            <td>59</td>
            <td>33</td>
            <td>2</td>
            <td>14</td>
            <td>21</td>
            <td>0</td>
            <td>7</td>
            <td>1</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Middlesex</th>
            <td>182</td>
            <td>110</td>
            <td>3</td>
            <td>46</td>
            <td>79</td>
            <td>28</td>
            <td>35</td>
            <td>16</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Morris</th>
            <td>71</td>
            <td>47</td>
            <td>0</td>
            <td>18</td>
            <td>25</td>
            <td>8</td>
            <td>8</td>
            <td>6</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Ocean</th>
            <td>253</td>
            <td>155</td>
            <td>5</td>
            <td>49</td>
            <td>123</td>
            <td>39</td>
            <td>46</td>
            <td>12</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Passaic</th>
            <td>108</td>
            <td>66</td>
            <td>1</td>
            <td>42</td>
            <td>28</td>
            <td>1</td>
            <td>15</td>
            <td>7</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Salem</th>
            <td>18</td>
            <td>12</td>
            <td>0</td>
            <td>8</td>
            <td>10</td>
            <td>0</td>
            <td>1</td>
            <td>2</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Somerset</th>
            <td>44</td>
            <td>22</td>
            <td>0</td>
            <td>8</td>
            <td>16</td>
            <td>1</td>
            <td>7</td>
            <td>3</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Sussex</th>
            <td>36</td>
            <td>20</td>
            <td>0</td>
            <td>5</td>
            <td>9</td>
            <td>8</td>
            <td>7</td>
            <td>2</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Union</th>
            <td>98</td>
            <td>58</td>
            <td>2</td>
            <td>36</td>
            <td>48</td>
            <td>5</td>
            <td>12</td>
            <td>6</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">Warren</th>
            <td>31</td>
            <td>18</td>
            <td>2</td>
            <td>6</td>
            <td>10</td>
            <td>7</td>
            <td>7</td>
            <td>1</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th scope="row">TOTAL</th>
            <td>2221</td>
            <td>1347</td>
            <td>45</td>
            <td>637</td>
            <td>818</td>
            <td>164</td>
            <td>349</td>
            <td>126</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default ConfirmedDrugs2016Table;

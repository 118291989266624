import React, { Component } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement, PointElement, LineElement } from 'chart.js';
import { Bar } from 'react-chartjs-2';

const colorOptions = ["Purple", "Indigo", "Pink", "LightBlue", "Teal"];

export var chartData = {
    labels,
    datasets: [],
};

export const options = {
    responsive: true,
    scales: {
        y: {
            type: 'linear',
            grace: '10%'
        }
    },
    plugins: {
        legend: {
            position: 'top',
        },

        labels: {
            position: 'top',
        },
        title: {
            display: true,
        },
    },
};

var labels = [];

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
);

export class AnnualHomicideCountyMultiBar extends Component {
    static displayName = AnnualHomicideCountyMultiBar.name;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
        };
    }

    async componentDidMount() {
        const fetchURL = "./public_data/HomicideDeaths/HomicideDeaths.json";
        const response = await fetch(fetchURL, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }

        });

        const responseData = await response.json();

        chartData.labels = null;
        chartData.datasets = [];

        var ChartLabels = [];
        var ValuesArray = [];
        var totals = [];
        var length = 0;

        const title = responseData.StartDate + " through " + responseData.EndDate;
        options.plugins.title.text = title;

        Object.keys(responseData.Data).forEach(key => {
            ValuesArray = [];

            totals.push({
                year: responseData.Data[key].Year,
                total: 0
            });

            length++;

            responseData.Data[key].Data.forEach(dataPoint => {
                if (key == 0) {
                    ChartLabels.push(dataPoint.CountyName);
                }
                ValuesArray.push(dataPoint.HomicideDeaths);

                totals[key].total += dataPoint.HomicideDeaths;
            });

            var newDataset = {
                label: responseData.Data[key].Year,
                data: ValuesArray,
                backgroundColor: colorOptions[key]
            };


            if (chartData.labels == null) {
                chartData.labels = ChartLabels;
            }
            chartData.datasets.push(newDataset);
        })

        await this.setState({
            HomicideData: responseData,
            isLoading: false,
            chartData: chartData,
            totals: totals,
            length: length
        });
    }

    render() {
        var totalsrow = [];

        for (var i = 0; i < this.state.length; i++) {
            totalsrow.push(<p key={i} className="multi-totals"><i>{this.state.totals[i].year} Total: </i><b>{this.state.totals[i].total}</b></p>);
        }

        //Display temporary loading prompt
        if (this.state.isLoading) {
            return (
                <div className="text-center">
                    <br />
                    Please wait, Reading Data...<hr />
                </div>
            )
        }

        return (
            <div>
                <Bar options={options} data={this.state.chartData} />
                <span className="totals-center">{totalsrow}</span>
            </div>
        );
    }
}


import "bootstrap/dist/css/bootstrap.css";

import { BrowserRouter } from "react-router-dom";

import { createRoot } from "react-dom/client";
import { App } from "./App";
// import registerServiceWorker from "./registerServiceWorker";

const root = createRoot(document.getElementById("root")!);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// registerServiceWorker();

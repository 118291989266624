import React from "react";
import {
  Card,
  Button,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  CardDeck,
  CardSubtitle,
  CardBody,
  CardGroup,
} from "reactstrap";
import { Table } from "reactstrap";
import { Container, Row, Col } from "reactstrap";

function ConfirmedDrugs2019Table() {
  return (
    <div>
      <Table centered bordered>
        <thead style={{ backgroundColor: "black " }}>
          <tr style={{ color: "white" }}>
            <th className="text-center dark-black">County</th>
            <th className="text-center dark-black">Total</th>
            <th className="text-center dark-black">Heroin</th>
            <th className="text-center dark-black">Fentanyl</th>
            <th className="text-center dark-black">Fentanyl Analog</th>
            <th className="text-center dark-black">Morphine</th>
            <th className="text-center dark-black">Oxycodone</th>
            <th className="text-center dark-black">Methadone</th>
            <th className="text-center dark-black">Cocaine</th>
            <th className="text-center dark-black">Methamphetamine</th>
            <th className="text-center dark-black">Benzodiazepine</th>
            <th className="text-center dark-black">Ethanol</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Atlantic</th>
            <td>171</td>
            <td>48</td>
            <td>121</td>
            <td>10</td>
            <td>1</td>
            <td>16</td>
            <td>10</td>
            <td>35</td>
            <td>10</td>
            <td>26</td>
            <td>47</td>
          </tr>
          <tr>
            <th scope="row">Bergen</th>
            <td>137</td>
            <td>57</td>
            <td>110</td>
            <td>24</td>
            <td>11</td>
            <td>11</td>
            <td>2</td>
            <td>36</td>
            <td>5</td>
            <td>41</td>
            <td>20</td>
          </tr>
          <tr>
            <th scope="row">Burlington</th>
            <td>154</td>
            <td>38</td>
            <td>127</td>
            <td>28</td>
            <td>5</td>
            <td>8</td>
            <td>4</td>
            <td>30</td>
            <td>17</td>
            <td>15</td>
            <td>19</td>
          </tr>
          <tr>
            <th scope="row">Camden</th>
            <td>352</td>
            <td>113</td>
            <td>285</td>
            <td>87</td>
            <td>1</td>
            <td>21</td>
            <td>16</td>
            <td>149</td>
            <td>24</td>
            <td>72</td>
            <td>54</td>
          </tr>
          <tr>
            <th scope="row">Cape May</th>
            <td>52</td>
            <td>18</td>
            <td>36</td>
            <td>6</td>
            <td>2</td>
            <td>5</td>
            <td>2</td>
            <td>10</td>
            <td>6</td>
            <td>4</td>
            <td>12</td>
          </tr>
          <tr>
            <th scope="row">Cumberland</th>
            <td>83</td>
            <td>18</td>
            <td>63</td>
            <td>3</td>
            <td>0</td>
            <td>3</td>
            <td>7</td>
            <td>24</td>
            <td>13</td>
            <td>13</td>
            <td>16</td>
          </tr>
          <tr>
            <th scope="row">Essex</th>
            <td>414</td>
            <td>182</td>
            <td>320</td>
            <td>11</td>
            <td>2</td>
            <td>13</td>
            <td>17</td>
            <td>190</td>
            <td>7</td>
            <td>31</td>
            <td>105</td>
          </tr>
          <tr>
            <th scope="row">Gloucester</th>
            <td>136</td>
            <td>34</td>
            <td>114</td>
            <td>32</td>
            <td>0</td>
            <td>10</td>
            <td>3</td>
            <td>54</td>
            <td>16</td>
            <td>27</td>
            <td>18</td>
          </tr>
          <tr>
            <th scope="row">Hudson</th>
            <td>174</td>
            <td>80</td>
            <td>122</td>
            <td>4</td>
            <td>2</td>
            <td>10</td>
            <td>6</td>
            <td>61</td>
            <td>5</td>
            <td>22</td>
            <td>43</td>
          </tr>
          <tr>
            <th scope="row">Hunterdon</th>
            <td>11</td>
            <td>3</td>
            <td>6</td>
            <td>2</td>
            <td>1</td>
            <td>4</td>
            <td>0</td>
            <td>2</td>
            <td>0</td>
            <td>4</td>
            <td>4</td>
          </tr>
          <tr>
            <th scope="row">Mercer</th>
            <td>119</td>
            <td>39</td>
            <td>94</td>
            <td>36</td>
            <td>3</td>
            <td>7</td>
            <td>3</td>
            <td>49</td>
            <td>6</td>
            <td>14</td>
            <td>30</td>
          </tr>
          <tr>
            <th scope="row">Middlesex</th>
            <td>208</td>
            <td>64</td>
            <td>168</td>
            <td>47</td>
            <td>7</td>
            <td>9</td>
            <td>9</td>
            <td>48</td>
            <td>10</td>
            <td>23</td>
            <td>46</td>
          </tr>
          <tr>
            <th scope="row">Monmouth</th>
            <td>185</td>
            <td>78</td>
            <td>135</td>
            <td>37</td>
            <td>4</td>
            <td>19</td>
            <td>14</td>
            <td>56</td>
            <td>7</td>
            <td>29</td>
            <td>33</td>
          </tr>
          <tr>
            <th scope="row">Morris</th>
            <td>91</td>
            <td>32</td>
            <td>73</td>
            <td>19</td>
            <td>3</td>
            <td>8</td>
            <td>3</td>
            <td>27</td>
            <td>1</td>
            <td>28</td>
            <td>25</td>
          </tr>
          <tr>
            <th scope="row">Ocean</th>
            <td>191</td>
            <td>87</td>
            <td>143</td>
            <td>34</td>
            <td>12</td>
            <td>22</td>
            <td>10</td>
            <td>32</td>
            <td>5</td>
            <td>34</td>
            <td>14</td>
          </tr>
          <tr>
            <th scope="row">Passaic</th>
            <td>165</td>
            <td>86</td>
            <td>130</td>
            <td>6</td>
            <td>0</td>
            <td>5</td>
            <td>5</td>
            <td>69</td>
            <td>1</td>
            <td>18</td>
            <td>38</td>
          </tr>
          <tr>
            <th scope="row">Salem</th>
            <td>41</td>
            <td>12</td>
            <td>33</td>
            <td>8</td>
            <td>0</td>
            <td>2</td>
            <td>0</td>
            <td>18</td>
            <td>7</td>
            <td>4</td>
            <td>8</td>
          </tr>
          <tr>
            <th scope="row">Somerset</th>
            <td>41</td>
            <td>14</td>
            <td>30</td>
            <td>1</td>
            <td>0</td>
            <td>3</td>
            <td>1</td>
            <td>12</td>
            <td>2</td>
            <td>7</td>
            <td>9</td>
          </tr>
          <tr>
            <th scope="row">Sussex</th>
            <td>39</td>
            <td>22</td>
            <td>32</td>
            <td>7</td>
            <td>0</td>
            <td>3</td>
            <td>2</td>
            <td>8</td>
            <td>2</td>
            <td>16</td>
            <td>12</td>
          </tr>
          <tr>
            <th scope="row">Union</th>
            <td>132</td>
            <td>50</td>
            <td>109</td>
            <td>30</td>
            <td>3</td>
            <td>11</td>
            <td>2</td>
            <td>56</td>
            <td>2</td>
            <td>24</td>
            <td>46</td>
          </tr>
          <tr>
            <th scope="row">Warren</th>
            <td>18</td>
            <td>7</td>
            <td>15</td>
            <td>5</td>
            <td>0</td>
            <td>2</td>
            <td>0</td>
            <td>4</td>
            <td>2</td>
            <td>5</td>
            <td>1</td>
          </tr>
          <tr>
            <th>TOTAL</th>
            <th>2914</th>
            <th>1082</th>
            <th>2266</th>
            <th>437</th>
            <th>57</th>
            <th>192</th>
            <th>116</th>
            <th>970</th>
            <th>148</th>
            <th>457</th>
            <th>600</th>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default ConfirmedDrugs2019Table;

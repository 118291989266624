import { Box } from "@mui/material";
import { AiOutlinePhone } from "react-icons/ai";
import { FaBuilding } from "react-icons/fa";
import { TiLocation } from "react-icons/ti";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, CardText, Table } from "reactstrap";

export function ContactUs() {
  return (
    <Box
      sx={{
        mt: 3,
        mx: {
          xs: 2, // For mobile
          sm: 5, // For tablet
          md: 25, // For small desktop
          lg: 40, // For large desktop
        },
      }}
    >
      <h2 className="text-center">Offices & Facilities</h2>
      <hr />
      <div className="align-center">
        <Card className="mx-auto" style={{ width: "28rem" }}>
          <CardHeader className="dark-blue text-center">Office of the Chief State Medical Examiner</CardHeader>
          <CardBody className="faded-blue">
            <CardText className="text-center faded-blue">
              P.O. Box 182
              <br />
              Trenton, NJ 08625-0360
              <br />
              <b>Phone:</b> (609)-376-0557
              <br />
              <b>Fax:</b> (609)-633-8312
              <br />
            </CardText>
          </CardBody>
        </Card>
      </div>
      <br />
      <div className="text-center">
        <b>
          In New Jersey, each county has a County Medical Examiner Office. <br />
          For information please contact the office where the person was pronounced deceased.
        </b>
      </div>
      <hr />
      <Table centered bordered>
        <thead style={{ backgroundColor: "black" }}>
          <tr style={{ color: "white" }}>
            <th className="text-center dark-black">
              <FaBuilding /> Office
            </th>
            <th className="text-center dark-black">
              <AiOutlinePhone /> Phone Number(s)
            </th>
            <th className="text-center dark-black">
              <TiLocation /> Address{" "}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-left faded-blue">
              <b>Northern Regional MEO</b>
              <br />
              Essex/Hudson/Passaic/Somerset Counties <br />
              <Link to="/NRMEO">NRMEO Website</Link>
              <br />
            </td>
            <td className="text-left faded-blue">
              (862)-350-5000 <br />
              <u>24-hour line:</u> <br />
              (973) 648-4500 <br />
            </td>
            <td className="text-left faded-blue">
              325 Norfolk Street <br />
              Newark, NJ 07103
              <br />
            </td>
          </tr>

          <tr>
            <td className="text-left faded-blue">
              <b>Southern Regional MEO</b>
              <br />
              Atlantic/Cape May/Cumberland Counties <br />
              <Link to="/SRMEO">SRMEO Web Site</Link>
              <br />
            </td>
            <td className="text-left faded-blue">
              (609)-861-3355 <br />
              <u>24-hour line:</u> <br />
              (609) 909-7200 (Atlantic)
              <br />
              (609) 465-3011 (Cape May)
              <br />
              (856) 455-6886 (Cumberland) <br />
            </td>
            <td className="text-left faded-blue">
              <b>Woodbine Developmental Center </b>
              <br />
              1175 DeHirsch Avenue <br />
              Woodbine, NJ 08270-2401 <br />
            </td>
          </tr>

          <tr>
            <td className="text-left faded-blue">
              <b>Bergen County</b>
              <br />
              <a href="https://www.co.bergen.nj.us/medical-examiner" target="_blank">
                Website
              </a>
              <br />
            </td>
            <td className="text-left faded-blue">
              (201)-634-2940 <br />
            </td>
            <td className="text-left faded-blue">
              351 Ridgewood Avenue <br />
              Paramus, NJ 07652
              <br />
            </td>
          </tr>

          <tr>
            <td className="text-left faded-blue">
              <b>Burlington County</b>
              <br />
              <a href="https://co.burlington.nj.us/291/Medical-Examiner" target="_blank">
                Website{" "}
              </a>
              <br />
            </td>
            <td className="text-left faded-blue">
              (609)-702-7030 <br />
              <u>24-hour line:</u> <br />
              (609) 265-7105 <br />
            </td>
            <td className="text-left faded-blue">
              4 Academy Drive <br />
              Westampton, NJ 08060 <br />
            </td>
          </tr>

          <tr>
            <td className="text-left faded-blue">
              <b>Camden/Gloucester/Salem</b> Counties <br />
              <a href="https://www.gloucestercountynj.gov/647/Medical-Examiner" target="_blank">
                Website{" "}
              </a>
              <br />
            </td>
            <td className="text-left faded-blue">
              (856)-218-4190 <br />
              <u>24-hour line:</u> <br />
              (856) 863-5810 <br />
            </td>
            <td className="text-left faded-blue">
              204 East Holly Avenue <br />
              Sewell, NJ 08080 <br />
            </td>
          </tr>

          <tr>
            <td className="text-left faded-blue">
              <b>Hunterdon County</b>
              <br />
            </td>
            <td className="text-left faded-blue">
              (908)-237-6078 <br />
              <u>24-hour line:</u> <br />
              (908) 788-6100 <br />
            </td>
            <td className="text-left faded-blue">
              2100 Wescott Drive <br />
              Flemington, NJ 08822
              <br />
            </td>
          </tr>

          <tr>
            <td className="text-left faded-blue">
              <b>Mercer/Middlesex/Monmouth</b> Counties <br />
              <a
                href="https://www.middlesexcountynj.gov/government/departments/department-of-public-safety-and-health/office-of-the-county-medical-examiner"
                target="_blank"
              >
                Website{" "}
              </a>
              <br />
            </td>
            <td className="text-left faded-blue">
              (732)-745-3190 <br />{" "}
            </td>

            <td className="text-left faded-blue">
              1490 Livingston Avenue <br />
              North Brunswick, NJ 08902
              <br />
            </td>
          </tr>

          <tr>
            <td className="text-left faded-blue">
              <b>Morris/Sussex/Warren</b> Counties <br />
              <a href="https://www.morriscountynj.gov/Departments/Medical-Examiner" target="_blank">
                Website
              </a>
              <br />
            </td>
            <td className="text-left faded-blue">
              (973) 829-8270 <br />
              <u>24-hour line:</u> <br />
              (973) 285-2900 <br />
            </td>
            <td className="text-left faded-blue">
              PO Box 900 <br />
              Morristown, NJ 07963-0900
              <br />
            </td>
          </tr>

          <tr>
            <td className="text-left faded-blue">
              <b>Ocean County</b>
              <br />
            </td>
            <td className="text-left faded-blue">
              (732)-341-3424 <br />
            </td>
            <td className="text-left faded-blue">
              PO Box 2191 <br />
              Toms River, NJ 08754-2191
              <br />
            </td>
          </tr>

          <tr>
            <td className="text-left faded-blue">
              <b>Union County</b> <br />
              <a href="https://ucnj.org/public-safety/division-of-the-medical-examiner/" target="_blank">
                Website{" "}
              </a>
              <br />
            </td>
            <td className="text-left faded-blue">
              (908)-654-9893 <br />
            </td>
            <td className="text-left faded-blue">
              300 North Ave. East <br />
              Westfield, NJ 07090-1426
              <br />
            </td>
          </tr>
        </tbody>
      </Table>
      <br /> <hr />
      <div className="text-center">
        Do you have any questions about our process, or need any help? We would would like to hear from you. You may
        email us at <br />{" "}
        <b>
          <a href="mailto:ocsme.inquiries@doh.nj.gov">ocsme.inquiries@doh.nj.gov</a>
        </b>
        .
      </div>
      <hr />
    </Box>
  );
}

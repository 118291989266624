﻿import { Box } from "@mui/material";
import { Component } from "react";

export class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openIndex: null,
    };
  }

  state = {
    openIndex: null,
  };

  handleQuestionClick = (index) => {
    this.setState((prevState) => ({
      openIndex: prevState.openIndex === index ? null : index,
    }));
  };

  render() {
    const questionsAndAnswers = [
      {
        question: "What do I do when a family member dies?",
        answer: (
          <div>
            Call the local emergency number; the police and emergency personnel will respond...
            <br />
            If there is a medical history for chronic disease and there is nothing to suggest any other cause of death,
            the doctor who was treating the deceased will be contacted. The treating doctor is obligated to issue an
            appropriate death certificate, and the family can have the body moved to the funeral home of their choice.
            If a Medical Examiner investigation is warranted, then the body will be taken by the Medical Examiner's
            Office. Upon conclusion of the Medical Examiner's investigation, the body may be released to the funeral
            home of the family's choice. The family must notify the funeral home to contact the appropriate Medical
            Examiner's Office.
            <br />
            More information can be found <a href="/emergency-procedures">here</a>.
          </div>
        ),
      },
      {
        question: "Which Medical Examiner's Office should I Contact?",
        answer: (
          <div>
            In New Jersey, each County has a County Medical Examiner's Office...
            <br />
            For information, please contact the Medical Examiner's Office in the county where the person was pronounced
            deceased. Click{" "}
            <a href="/contact-us" target="_blank">
              here
            </a>{" "}
            for our list of Facilities' contact information.
          </div>
        ),
      },
      {
        question: "What kind of deaths are Reportable vs Non-Reportable?",
        answer: (
          <div>
            <i>
              <b>The following deaths are considered Reportable:</b>
            </i>
            <br />
            <ul>
              <li>All Deaths occurring outside of a hospital or medical facility.</li>
              <li>
                All Deaths occurring in a hospital or nursing home within 24 hours of entry to the facility and/or if
                the death falls under any of the other reportable death categories.
              </li>
              <li>
                Deaths by violent means, whether apparently homicidal, suicidal or accidental including thermal,
                chemical, electrical and radiological, even if the deceased was hospitalized for more than 24 hours.
              </li>
              <li>Deaths determined to be suspicious or under unusual circumstances.</li>
              <li>Patients who sustain a fall prior to or during hospitalization, subsequently resulting in death.</li>
              <li>Deaths that occur within 24 hours of a surgical or diagnostic procedure.</li>
              <li>Deaths of prison inmates/residents of a State or County institution.</li>
              <li>Deaths in police custody.</li>
              <li>Deaths that might constitute a threat to public health.</li>
              <li>Deaths related to disease resulting from employment or accidents at work.</li>
              <li>Deaths of infants and children that are sudden or unexpected.</li>
              <li>Fetal deaths occurring without medical attendance.</li>
              <li>All children under the age of 3.</li>
            </ul>
            <br />
            <i>
              <b>The following deaths are considered Non-Reportable:</b>
            </i>
            <br />
            <ul>
              <li>
                Deaths of patients that are terminally ill and are under the care of a hospice program - Unless they
                also meet any of the above requirements for reportable deaths.
              </li>
            </ul>
          </div>
        ),
      },
      {
        question: "What happens during an investigation?",
        answer: (
          <div>
            The Medicolegal Death Investigator gathers information from:
            <ul>
              <br />
              <li>Family Members</li>
              <li>Police</li>
              <li>Witnesses</li>
              <li>Any others that may be associated with or have pertinent information about the deceased</li>
            </ul>
            <br />
            The Medical Investigator works with police in analyzing the death scene and obtains pertinent medical
            records. The facts may allow the Medical Examiner to close the case and refer it to the family physician to
            sign the death certificate. The circumstances may require that the body be moved to allow a more detailed
            examination (viewing) or may involve a complete autopsy.
          </div>
        ),
      },
      {
        question: "Why are investigations necessary?",
        answer: (
          <div>
            Whenever a death occurs under circumstances that raise a public interest, it needs to be explained, and the
            cause and manner of death determined.
            <br /> <br />
            <i>
              <b>Autopsies are performed for the following reasons:</b>
            </i>
            <ul>
              <br />
              <li>Public health</li>
              <li>Public safety</li>
              <li>Administration of justice</li>
            </ul>
            <br />
            <i>
              <b>Autopsies identify:</b>
            </i>
            <ul>
              <br />
              <li>Evidence of crime</li>
              <li>Environment dangers</li>
              <li>Workplace and other safety violations</li>
              <li>Consumer product hazards</li>
              <li>Public health interests</li>
            </ul>
            <br />
            Unnatural deaths are identified and investigated, leading to proper classification for accidental death
            benefits. In criminal cases, the investigation provides for proper evidence identification and collection,
            leading to successful apprehension and criminal prosecution.
          </div>
        ),
      },
      {
        question: "What is an autopsy?",
        answer: (
          <div>
            An autopsy is an external and internal examination of a body. Licensed physicians, specifically forensic
            pathologists acting as medical examiners, will perform an autopsy to determine the cause and manner of
            death. After examination, the body is closed and specimens of body fluids and tissues are retained for
            diagnostic testing. When necessary, organs such as the brain or heart, may also be retained for further
            testing. None of these procedures will prevent the body from being released to the family for the funeral or
            final disposition. If organs were held for further testing and should you desire the return of organs after
            testing, you should advise the office that performed the autopsy of this request. Otherwise, within a
            reasonable period, the specimens and/or organs will be handled consistent with standard practice.
          </div>
        ),
      },
      {
        question: "Is an autopsy always necessary?",
        answer: (
          <div>
            No. However, in some circumstances, an autopsy is mandated by law. In other circumstances, the medical
            examiner may determine an autopsy is necessary to identify the cause and manner of death. The law requires
            an autopsy in deaths:
            <li> Involving a homicide</li>
            <li>Occurring under unusual circumstances</li>
            <li> Posing a threat to public health</li>
            <li> When children die unexpectedly</li>
            <li> Involving inmates in prison (without prior medical documentation of history)</li>
            An autopsy enables a Medical Examiner to obtain important evidence about the cause and manner of the
            person's death that could not be otherwise obtained.
          </div>
        ),
      },
      {
        question: "How long does an exam take?",
        answer: (
          <div>
            A standard forensic autopsy will take about 2 hours; however, the circumstances of the death may lengthen or
            shorten that timeframe.
          </div>
        ),
      },
      {
        question: "How long does it take to complete an autopsy? Will it delay our funeral arrangements?",
        answer: (
          <div>
            All exams are typically scheduled within 24-48 hours of arriving at the Medical Examiner's Office. A
            standard forensic autopsy will take about two to three hours. Complicated cases may take longer, but in most
            cases should not delay usual funeral arrangements. After the autopsy, the body is released to the funeral
            home. The funeral home prepares the body for viewing.
          </div>
        ),
      },
      {
        question: "Will an autopsy interfere with our desire for a viewing or open casket?",
        answer: (
          <div>
            No. An autopsy does not necessary preclude a viewing. The funeral home can prepare the body for viewing. The
            surgical incisions which are closed are appropriately covered. However, it may not be possible to restore
            any post-mortem changes which occur naturally when a person is not found until hours or days after death.
            Injuries sustained prior to death may make the body non-viewable and may require a closed casket funeral.
          </div>
        ),
      },
      {
        question: "What if the family does not want an autopsy?",
        answer: (
          <div>
            The Medical Examiner autopsy, unlike a hospital autopsy, does not require permission from the Next-of-kin,
            as the autopsy is performed under statutory authority. If the family has a religious objection to the
            autopsy, the family can sign an objection form and the Medical Examiner will make every effort to limit the
            procedure as much as possible. If the Medical Examiner does not determine that a full autopsy is necessary
            to fulfill public responsibility, the family may present their objection to a court of law for consideration
            before the autopsy is undertaken.
          </div>
        ),
      },
      {
        question: "Does the family pay for any of the Medical Examiner's services?",
        answer: (
          <div>
            No. Families pay nothing for Medical Examiner services. The family only pays the funeral home the cost of
            its services, including transportation of the body from the Medical Examiner's Office to the funeral home.
          </div>
        ),
      },
      {
        question: "Can a Medical Examiner case be an organ or tissue donor?",
        answer: (
          <div>
            Yes. The Medical Examiner offices work closely with organ procurement agencies. If the family wishes to
            donate organs or tissues, they need to give permission to the procurement agency. This can be done by
            calling 800-<b>SHARE-NJ</b> (800-742-7365). The Medical Examiner will consider the family's desire and the
            need to preserve vital evidence in criminal cases. The Medical Examiner will consider the family's wishes,
            the needs of the procurement agency, and the need to preserve vital evidence in criminal cases.
          </div>
        ),
      },
      {
        question: "What happens to clothing and personal items?",
        answer: (
          <div>
            Unless identified as evidence by the investigating law enforcement agency, all clothing and personal items
            that are brought in to the Medical Examiner's office on the body are released with the body to the funeral
            home. Items identified as evidence are retained by the investigating law enforcement agency and the family
            should contact the appropriate agency for the release.
          </div>
        ),
      },
      {
        question: "Where can I obtain a medical examiner report and death certificate?",
        answer: (
          <div>
            When a case is investigated by the Medical Examiner's office, a Report of Investigation by Medical Examiner
            (RIME) is generated and if an exam was performed, an autopsy report or external examination report shall be
            generated. Reports are not automatically sent out, as many families do not want to receive copies of the
            reports. If permitted by law, reports can be obtained by writing to the County Medical Examiner office and
            requesting a copy of the report. A small fee for copies may be charged. Death certificates are issued by the
            local Municipality, County or State Registrar. Certified copies of the death certificate can be obtained by
            the family through their funeral director or by directly contacting the registrar.
          </div>
        ),
      },
      {
        question: "Do I make complaints to your office about a treating/attending physician I am having issues with?",
        answer: (
          <div>
            No. Matters concerning physicians are under the jurisdiction of the Board of Medical Examiners. Contact
            information for the Board may be found online at{" "}
            <a href="https://www.njconsumeraffairs.gov/bme/Pages/contactus.aspx">
              https://www.njconsumeraffairs.gov/bme/Pages/contactus.aspx
            </a>{" "}
            or by calling (609) 826-7100.
          </div>
        ),
      },
      {
        question: "Must I do anything differently if a baby dies unexpectedly?",
        answer: (
          <div>
            No. You will be provided with additional services following this tragic event. In these cases, the SIDS
            Center of New Jersey shall be notified of the death. The center provides counseling and bereavement services
            to the family. You may contact the SIDS Center of NJ at their hotline 800-<b>545-7437</b>.
          </div>
        ),
      },
    ];

    const boxStyle = {
      backgroundColor: "rgba(21, 59, 106)", // Dark Blue
      border: "1px solid white",
      color: "white",
      padding: "10px", // Add some padding for spacing
      marginBottom: "5px", // Adjust the margin between questions
    };

    const questionStyle = {
      fontWeight: "bold",
      cursor: "pointer",
    };

    const answerStyle = {
      backgroundColor: "rgb(194, 206, 221)", // Light Blue
      color: "black",
      borderTop: "1px solid rgb(194, 206, 221)", // Light Blue border at the top
      padding: "10px", // Add padding to the answer
      marginTop: "5px", // Adjust the margin between question and answer
    };

    return (
      <Box
        sx={{
          mt: 3,
          mx: {
            xs: 2, // For mobile
            sm: 5, // For tablet
            md: 25, // For small desktop
            lg: 55, // For large desktop
          },
        }}
      >
        <h2 className="text-center">Frequently Asked Questions (FAQ)</h2>
        <hr />
        <br />

        <ul>
          {questionsAndAnswers.map((qa, index) => (
            <li key={index} style={boxStyle}>
              <div onClick={() => this.handleQuestionClick(index)} style={questionStyle}>
                {qa.question}
              </div>
              {this.state.openIndex === index && <div style={answerStyle}>{qa.answer}</div>}
            </li>
          ))}
        </ul>
      </Box>
    );
  }
}

// Keeping this section just-in-case for when we move to MUI styling

// import React, { Component, useState } from 'react';
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import Typography from '@mui/material/Typography';
// //import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// export class FAQ extends Component {

//     static displayName = FAQ.name;
//     constructor(props) {
//         super(props);
//         this.state = {
//             expanded: false,
//         };
//     }

//     handleAccordionClick = (panel) => (event, isExpanded) => {
//         this.setState({
//             expanded: isExpanded ? panel : false,
//         });
//     }

//     render() {

//         const { expanded } = this.state;

//         const boxStyle = {
//             backgroundColor: 'rgba(21, 59, 106)', // Dark Blue (throughout the site)
//             border: '1px solid white',
//             color: 'white',
//             padding: '10px', // Add some padding for spacing
//         };

//         const questionStyle = {
//             fontWeight: 'bold',
//         };

//         const answerStyle = {
//             backgroundColor: 'rgb(194, 206, 221)', // Light Blue (throughout the site)
//             color: 'black',
//         };

//         return (
//             <div>
//                 <h2 className="text-center">
//                     Frequently Asked Questions (FAQ)
//                 </h2>
//                 <hr />
//                 <br />

//                 <Accordion expanded={this.state.expanded === 'panel1'} onChange={this.handleAccordionClick('panel1')}>
//                     <AccordionSummary style={boxStyle}
//                        // expandIcon={<ExpandMoreIcon />}
//                         aria-controls="panel1a-content"
//                         id="panel1a-header"
//                     >
//                         <Typography style={questionStyle}>&nbsp; &nbsp; What do I do when a family member dies?</Typography>
//                     </AccordionSummary>
//                     {expanded && (
//                         <AccordionDetails style={answerStyle}>
//                         <Typography>
//                             Call the local emergency number; the police and emergency personnel will respond.
//                             If there is a medical history for chronic disease and there is nothing to suggest any other cause of death, the doctor who was treating the deceased will be contacted.
//                             The treating doctor is obligated to issue an appropriate death certificate, and the family can have the body moved to the funeral home of their choice.
//                             If a Medical Examiner investigation is warranted, then the body will be taken by the Medical Examiner's Office.
//                             Upon conclusion of the Medical Examiner's investigation, the body may be released to the funeral home of the family's choice.
//                             The family must notify the funeral home to contact the appropriate Medical Examiner's Office.
//                         </Typography>
//                     </AccordionDetails>
//                     )}
//                 </Accordion>
//                 <Accordion expanded={this.state.expanded === 'panel2'} onChange={this.handleAccordionClick('panel2')}>
//                     <AccordionSummary style={boxStyle}
//                      //   expandIcon={<ExpandMoreIcon />}
//                         aria-controls="panel2a-content"
//                         id="panel2a-header"
//                     >
//                         <Typography style={questionStyle}>&nbsp; &nbsp; Which Medical Examiner's Office should I Contact?</Typography>
//                     </AccordionSummary>
//                     <AccordionDetails style={answerStyle}>
//                         <Typography>
//                             In New Jersey, each County has a County Medical Examiner's Office however some offices have combined services and
//                             operate jointly out of one office. For information, please contact the Medical Examiner's Office in the county where the
//                             person was pronounced deceased. Click <a href="/ContactUs" target="_blank" >here</a> for our list of Facilities' contact information.
//                         </Typography>
//                     </AccordionDetails>
//                 </Accordion>
//                 <Accordion expanded={this.state.expanded === 'panel3'} onChange={this.handleAccordionClick('panel3')}>
//                     <AccordionSummary style={boxStyle}
//                      //   expandIcon={<ExpandMoreIcon />}
//                         aria-controls="panel2a-content"
//                         id="panel2a-header"
//                     >
//                         <Typography style={questionStyle}>&nbsp; &nbsp; What kind of deaths are Reportable vs Non-Reportable?</Typography>
//                     </AccordionSummary>
//                     <AccordionDetails style={answerStyle}>
//                         <Typography>
//                            <i><b>The following deaths are considered Reportable:</b></i>
//                            <br />
//                            <ul>
//                                 <li>All Deaths occurring outside of a hospital or medical facility.</li>
//                                 <li>All Deaths occurring in a hospital or nursing home within 24 hours of entry to the facility and/or if the death falls under any of the other reportable death categories.</li>
//                                 <li>Deaths by violent means, whether apparently homicidal, suicidal or accidental including thermal, chemical, electrical and radiological, even if the deceased was hospitalized for more than 24 hours.</li>
//                                 <li>Deaths determined to be suspicious or under unusual circumstances.</li>
//                                 <li>Patients who sustain a fall prior to or during hospitalization, subsequently resulting in death.</li>
//                                 <li>Deaths that occur within 24 hours of a surgical or diagnostic procedure.</li>
//                                 <li>Deaths of prison inmates/residents of a State or County institution.</li>
//                                 <li>Deaths in police custody.</li>
//                                 <li>Deaths that might constitute a threat to public health.</li>
//                                 <li>Deaths related to disease resulting from employment or accidents at work.</li>
//                                 <li>Deaths of infants and children that are sudden or unexpected.</li>
//                                 <li>Fetal deaths occurring without medical attendance.</li>
//                                 <li>All children under the age of 3.</li>
//                             </ul>
//                             <br />
//                             <i><b>The following deaths are considered Non-Reportable:</b></i>
//                             <br />
//                             <ul>
//                                 <li>Deaths of patients that are terminally ill and are under the care of a hospice program - Unless they also meet any of the above requirements for reportable deaths.</li>
//                             </ul>

//                         </Typography>
//                     </AccordionDetails>
//                 </Accordion>
//                 <Accordion expanded={this.state.expanded === 'panel4'} onChange={this.handleAccordionClick('panel4')}>
//                     <AccordionSummary style={boxStyle}
//                         //   expandIcon={<ExpandMoreIcon />}
//                         aria-controls="panel2a-content"
//                         id="panel2a-header"
//                     >
//                         <Typography style={questionStyle}>&nbsp; &nbsp; What happens during an investigation?</Typography>
//                     </AccordionSummary>
//                     <AccordionDetails style={answerStyle}>
//                         <Typography>
//                             The Medicolegal Death Investigator gathers information from:

//                             <ul>
//                             <br/>
//                                 <li>Family Members</li>
//                                 <li>Police</li>
//                                 <li>Witnesses</li>
//                                 <li>Any others that may be associated with or have pertinent information about the deceased</li>
//                             </ul>
//                             <br/>
//                             The Medical Investigator works with police in analyzing the death scene and obtains pertinent medical records. The facts may allow the Medical Examiner
//                             to close the case and refer it to the family physician to sign the death certificate. The circumstances may require that the body be moved to allow a more
//                             detailed examination (viewing) or may involve a complete autopsy.
//                         </Typography>
//                     </AccordionDetails>
//                 </Accordion>

//                 <Accordion expanded={this.state.expanded === 'panel5'} onChange={this.handleAccordionClick('panel5')}>
//                     <AccordionSummary style={boxStyle}
//                         //   expandIcon={<ExpandMoreIcon />}
//                         aria-controls="panel2a-content"
//                         id="panel2a-header"
//                     >
//                         <Typography style={questionStyle}>&nbsp; &nbsp; Why are investigations necessary?</Typography>
//                     </AccordionSummary>
//                     <AccordionDetails style={answerStyle}>
//                         <Typography>
//                             Whenever a death occurs under circumstances that raise a public interest, it needs to be explained, and the cause and manner of death determined.
//                             <br/> <br/>
//                             <i><b>Autopsies are performed for the following reasons:</b></i>
//                             <ul>
//                             <br/>
//                                 <li>Public health</li>
//                                 <li>Public safety</li>
//                                 <li>Administration of justice</li>
//                             </ul>
//                             <br/>

//                             <i><b>Autopsies identify:</b></i>
//                             <ul>
//                             <br/>
//                                 <li>Evidence of crime</li>
//                                 <li>Environment dangers</li>
//                                 <li>Workplace and other safety violations</li>
//                                 <li>Consumer product hazards</li>
//                                 <li>Public health interests</li>
//                             </ul>
//                             <br/>
//                             Unnatural deaths are identified and investigated, leading to proper classification for accidental death benefits. In criminal cases, the investigation
//                             provides for proper evidence identification and collection, leading to successful apprehension and criminal prosecution.
//                         </Typography>
//                     </AccordionDetails>
//                 </Accordion>
//                 <Accordion expanded={this.state.expanded === 'panel6'} onChange={this.handleAccordionClick('panel6')}>
//                     <AccordionSummary style={boxStyle}
//                         //   expandIcon={<ExpandMoreIcon />}
//                         aria-controls="panel2a-content"
//                         id="panel2a-header"
//                     >
//                         <Typography style={questionStyle}>&nbsp; &nbsp; What is an autopsy?</Typography>
//                     </AccordionSummary>
//                     <AccordionDetails style={answerStyle}>
//                         <Typography>
//                             An autopsy is an external and internal examination of a body. Licensed physicians, specifically forensic pathologists acting as medical examiners, will perform an autopsy to determine the cause and manner of death. After examination, the body is closed and specimens of body fluids and tissues are retained for diagnostic testing. When necessary, organs such as the brain or heart, may also be retained for further testing.

//                             None of these procedures will prevent the body from being released to the family for the funeral or final disposition. If organs were held for further testing and should you desire the return of organs after testing, you should advise the office that performed the autopsy of this request. Otherwise, within a reasonable period, the specimens and/or organs will be handled consistent with standard practice.

//                         </Typography>
//                     </AccordionDetails>
//                 </Accordion>
//                 <Accordion expanded={this.state.expanded === 'panel7'} onChange={this.handleAccordionClick('panel7')}>
//                     <AccordionSummary style={boxStyle}
//                         //   expandIcon={<ExpandMoreIcon />}
//                         aria-controls="panel2a-content"
//                         id="panel2a-header"
//                     >
//                         <Typography style={questionStyle}>&nbsp; &nbsp; Is an autopsy always necessary?</Typography>
//                     </AccordionSummary>
//                     <AccordionDetails style={answerStyle}>
//                         <Typography>
//                             No. However, in some circumstances, an autopsy is mandated by law. In other circumstances, the medical examiner may determine an autopsy is necessary to identify the cause and manner of death. The law
//                             requires an autopsy in deaths:
//                             <li> Involving a homicide</li>
//                             <li>Occurring under unusual circumstances</li>
//                             <li> Posing a threat to public health</li>
//                             <li> When children die unexpectedly</li>
//                             <li> Involving inmates in prison (without prior medical documentation of history)</li>
//                             An autopsy enables a Medical Examiner to obtain important evidence about the cause and manner of the person's death that could not be otherwise obtained.

//                         </Typography>
//                     </AccordionDetails>
//                 </Accordion>
//                 <Accordion expanded={this.state.expanded === 'panel8'} onChange={this.handleAccordionClick('panel8')}>
//                     <AccordionSummary style={boxStyle}
//                         //   expandIcon={<ExpandMoreIcon />}
//                         aria-controls="panel2a-content"
//                         id="panel2a-header"
//                     >
//                         <Typography style={questionStyle}>&nbsp; &nbsp; How long does an exam take?</Typography>
//                     </AccordionSummary>
//                     <AccordionDetails style={answerStyle}>
//                         <Typography>
//                             A standard forensic autopsy will take about 2 hours; however, the circumstances of the death may lengthen or shorten that
//                             timeframe.
//                         </Typography>
//                     </AccordionDetails>
//                 </Accordion>
//                 <Accordion expanded={this.state.expanded === 'panel9'} onChange={this.handleAccordionClick('panel9')}>
//                     <AccordionSummary style={boxStyle}
//                         //   expandIcon={<ExpandMoreIcon />}
//                         aria-controls="panel2a-content"
//                         id="panel2a-header"
//                     >
//                         <Typography style={questionStyle}>&nbsp; &nbsp; How long does it take to complete an autopsy? Will it delay our funeral arrangements?</Typography>
//                     </AccordionSummary>
//                     <AccordionDetails style={answerStyle}>
//                         <Typography>
//                         All exams are typically scheduled within 24-48 hours of arriving at the Medical Examiner's Office. A standard forensic autopsy will take
//                         about two to three hours. Complicated cases may take longer, but in most cases should not delay usual funeral arrangements. After the
//                         autopsy, the body is released to the funeral home. The funeral home prepares the body for viewing.
//                         </Typography>
//                     </AccordionDetails>
//                 </Accordion>
//                 <Accordion expanded={this.state.expanded === 'panel10'} onChange={this.handleAccordionClick('panel10')}>
//                     <AccordionSummary style={boxStyle}
//                         //   expandIcon={<ExpandMoreIcon />}
//                         aria-controls="panel2a-content"
//                         id="panel2a-header"
//                     >
//                         <Typography style={questionStyle}>&nbsp; &nbsp; Will an autopsy interfere with our desire for a viewing or open casket?</Typography>
//                     </AccordionSummary>
//                     <AccordionDetails style={answerStyle}>
//                         <Typography>
//                         No. An autopsy does not necessary preclude a viewing. The funeral home can prepare the body for viewing. The surgical incisions which
//                         are closed are appropriately covered. However, it may not be possible to restore any post-mortem changes which occur naturally when a
//                         person is not found until hours or days after death. Injuries sustained prior to death may make the body non-viewable and may require
//                         a closed casket funeral.
//                         </Typography>
//                     </AccordionDetails>
//                 </Accordion>
//                 <Accordion expanded={this.state.expanded === 'panel11'} onChange={this.handleAccordionClick('panel11')}>
//                     <AccordionSummary style={boxStyle}
//                         //   expandIcon={<ExpandMoreIcon />}
//                         aria-controls="panel2a-content"
//                         id="panel2a-header"
//                     >
//                         <Typography style={questionStyle}>&nbsp; &nbsp; What if the family does not want an autopsy?</Typography>
//                     </AccordionSummary>
//                     <AccordionDetails style={answerStyle}>
//                         <Typography>
//                             The Medical Examiner autopsy, unlike a hospital autopsy, does not require permission from the Next-of-kin, as the
//                             autopsy is performed under statutory authority. If the family has a religious objection to the autopsy, the family can sign
//                             an objection form and the Medical Examiner will make every effort to limit the procedure as much as possible. If the Medical
//                             Examiner does not determine that a full autopsy is necessary to fulfill public responsibility, the family may present their
//                             objection to a court of law for consideration before the autopsy is undertaken.
//                         </Typography>
//                     </AccordionDetails>
//                 </Accordion>
//                 <Accordion expanded={this.state.expanded === 'panel12'} onChange={this.handleAccordionClick('panel12')}>
//                     <AccordionSummary style={boxStyle}
//                         //   expandIcon={<ExpandMoreIcon />}
//                         aria-controls="panel2a-content"
//                         id="panel2a-header"
//                     >
//                         <Typography style={questionStyle}>&nbsp; &nbsp; Does the family pay for any of the Medical Examiner's services?</Typography>
//                     </AccordionSummary>
//                     <AccordionDetails style={answerStyle}>
//                         <Typography>
//                             No. Families pay nothing for Medical Examiner services. The family only pays the funeral home the cost of its services, including
//                             transportation of the body from the Medical Examiner's Office to the funeral home.
//                         </Typography>
//                     </AccordionDetails>
//                 </Accordion>
//                 <Accordion expanded={this.state.expanded === 'panel13'} onChange={this.handleAccordionClick('panel13')}>
//                     <AccordionSummary style={boxStyle}
//                         //   expandIcon={<ExpandMoreIcon />}
//                         aria-controls="panel2a-content"
//                         id="panel2a-header"
//                     >
//                         <Typography style={questionStyle}>&nbsp; &nbsp; Can a Medical Examiner case be an organ or tissue donor?</Typography>
//                     </AccordionSummary>
//                     <AccordionDetails style={answerStyle}>
//                         <Typography>
//                             Yes. The Medical Examiner offices work closely with organ procurement agencies. If the family wishes to donate organs
//                             or tissues, they need to give permission to the procurement agency. This can be done by calling 800-<b>SHARE-NJ</b> (800-742-7365). The Medical Examiner will consider the family's desire and the need to preserve vital evidence in criminal cases.
//                             The Medical Examiner will consider the family's wishes, the needs of the procurement agency, and the need to preserve vital evidence in criminal cases.
//                         </Typography>
//                     </AccordionDetails>
//                 </Accordion>
//                 <Accordion expanded={this.state.expanded === 'panel14'} onChange={this.handleAccordionClick('panel14')}>
//                     <AccordionSummary style={boxStyle}
//                         //   expandIcon={<ExpandMoreIcon />}
//                         aria-controls="panel2a-content"
//                         id="panel2a-header"
//                     >
//                         <Typography style={questionStyle}>&nbsp; &nbsp; What happens to clothing and personal items?</Typography>
//                     </AccordionSummary>
//                     <AccordionDetails style={answerStyle}>
//                         <Typography>
//                             Unless identified as evidence by the investigating law enforcement agency, all clothing and personal items that are
//                             brought in to the Medical Examiner's office on the body are released with the body to the funeral home. Items identified
//                             as evidence are retained by the investigating law enforcement agency and the family should contact the appropriate
//                             agency for the release.
//                         </Typography>
//                     </AccordionDetails>
//                 </Accordion>
//                 <Accordion expanded={this.state.expanded === 'panel15'} onChange={this.handleAccordionClick('panel15')}>
//                     <AccordionSummary style={boxStyle}
//                         //   expandIcon={<ExpandMoreIcon />}
//                         aria-controls="panel2a-content"
//                         id="panel2a-header"
//                     >
//                         <Typography style={questionStyle}>&nbsp; &nbsp; Where can I obtain a medical examiner report and death certificate?</Typography>
//                     </AccordionSummary>
//                     <AccordionDetails style={answerStyle}>
//                         <Typography>
//                             When a case is investigated by the Medical Examiner's office, a Report of Investigation by Medical Examiner (RIME) is
//                             generated and if an exam was performed, an autopsy report or external examination report shall be generated. Reports are
//                             not automatically sent out, as many families do not want to receive copies of the reports. If permitted by law, reports can
//                             be obtained by writing to the County Medical Examiner office and requesting a copy of the report. A small fee for copies
//                             may be charged.
//                             Death certificates are issued by the local Municipality, County or State Registrar. Certified copies of the death certificate
//                             can be obtained by the family through their funeral director or by directly contacting the registrar.
//                         </Typography>
//                     </AccordionDetails>
//                 </Accordion>
//                 <Accordion expanded={this.state.expanded === 'panel16'} onChange={this.handleAccordionClick('panel16')}>
//                     <AccordionSummary style={boxStyle}
//                         //   expandIcon={<ExpandMoreIcon />}
//                         aria-controls="panel2a-content"
//                         id="panel2a-header"
//                     >
//                         <Typography style={questionStyle}>&nbsp; &nbsp; Do I make complaints to your office about a treating/attending physician I am having issues with?</Typography>
//                     </AccordionSummary>
//                     <AccordionDetails style={answerStyle}>
//                         <Typography>
//                             No. Matters concerning physicians are under the jurisdiction of the Board of Medical Examiners. Contact information for
//                             the Board may be found online at <a href="https://www.njconsumeraffairs.gov/bme/Pages/contactus.aspx">https://www.njconsumeraffairs.gov/bme/Pages/contactus.aspx</a> or by calling (609) 826-
//                             7100.
//                         </Typography>
//                     </AccordionDetails>
//                 </Accordion>
//                 <Accordion expanded={this.state.expanded === 'panel17'} onChange={this.handleAccordionClick('panel17')}>
//                     <AccordionSummary style={boxStyle}
//                         //   expandIcon={<ExpandMoreIcon />}
//                         aria-controls="panel2a-content"
//                         id="panel2a-header"
//                     >
//                         <Typography style={questionStyle}>&nbsp; &nbsp; Must I do anything differently if a baby dies unexpectedly?</Typography>
//                     </AccordionSummary>
//                     <AccordionDetails style={answerStyle}>
//                         <Typography>
//                         No. You will be provided with additional services following this tragic event. In these cases, the SIDS Center of New Jersey shall be notified of the death. The center provides counseling and bereavement services to the family. You may contact the SIDS Center of NJ at their hotline 800-<b>545-7437</b>.
//                         </Typography>
//                     </AccordionDetails>
//                 </Accordion>
//             </div >

//         );
//     }
// }

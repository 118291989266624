import { Component } from "react";
//import '../custom.css'
import { Box } from "@mui/material";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { NJStateTopBar } from "./NJStateTopBar";
import { NavMenu } from "./NavMenu";

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <Box>
        <NJStateTopBar />
        <Header />
        <NavMenu />
        {this.props.children}
        <br />
        <Footer />
      </Box>
    );
  }
}

import React, { Component } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement, PointElement, LineElement } from 'chart.js';
import { Line } from "react-chartjs-2";

const colorOptions = ["#233d4d", "#a9714b", "#95a3b3", "#a1c181", "#619b8a"];
const borderColorOptions = ["#4f89ac", "#caa287", "#bac3cd", "#c2d7ad", "#97beb2"];


const chartData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
        {
            label: "Suspected",
            data: [203, 239, 215, 209, 248, 246, 273, 302, 243, 261, 294, 288],
            fill: true,
            backgroundColor: "rgba(75,192,192,1)",
            borderColor: "rgba(75,192,192,1)"
        },
        {
            label: "Confirmed",
            data: [191, 235, 204, 202, 243, 234, 264, 291, 233, 256, 282, 279],
            fill: false,
            backgroundColor: "#742774",
            borderColor: "#742774"
        }
    ]
}

export const options = {
    responsive: true,
    scales: {
        y: {
            type: 'linear',
            grace: '10%'
        }
    },
    plugins: {
        legend: {
            position: 'top',
        },

        labels: {
            position: 'top',
        },
        title: {
            display: true,
        },
    },
};

var labels = [];

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    PointElement,
    LineElement,
);

export class SuspectedVsConfirmed2019Line extends Component {
    static displayName = SuspectedVsConfirmed2019Line.name;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }


    async componentDidMount() {
        
        const fetchURL = "./public_data/AnnualCases/SuspectedVsConfirmed-2019.json";
        const response = await fetch(fetchURL, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }

        });

        await this.setState({
            labels: ChartLabels,
            isLoading: false,
            chartData: chartData
        });

        const responseData = await response.json();

        // chartData.labels = null;

        var ChartLabels = [];
        var SuspectedValuesArray = [];
        var ConfirmedValuesArray = [];

        const title = responseData.startDate + " through " + responseData.endDate;
        options.plugins.title.text = title;

        responseData.Data.forEach(dataPoint => {
                ChartLabels.push(dataPoint.MonthName);
                SuspectedValuesArray.push(dataPoint.Suspected);
                ConfirmedValuesArray.push(dataPoint.Confirmed);
        })

        // fill the chart data with the data gathered from the foreach loop
        chartData.Suspected.push(SuspectedValuesArray);
        chartData.Confirmed.push(ConfirmedValuesArray);
        
    }

    render() {

        //Display temporary loading prompt
        if (this.state.isLoading) {
            return (
                <div className="text-center">
                    <br />
                    Please wait, Reading Data...<hr />
                </div>
            )
        }

        return (
            <div>
                {<Line data={this.state.chartData} />}
                <span className="totals-center"></span>
            </div>
        );
    }
}


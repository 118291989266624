import React from 'react';
import { Card, Button, CardImg, CardImgOverlay, CardTitle, CardText, CardDeck, CardSubtitle, CardBody, CardGroup } from 'reactstrap';
import { Table } from 'reactstrap';
import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import { AnnualSuicideCountyMultiBar } from '../../components/Charts/DashboardCharts/SuicideCharts/AnnualSuicidesByCountyMultiBar'
import { AnnualSuicideMonthMultiLine } from '../../components/Charts/DashboardCharts/SuicideCharts/AnnualSuicidesByMonthMultiLine'
import { YearSuicideAgeBar } from '../../components/Charts/DashboardCharts/SuicideCharts/YearSuicidesByAgeBar'
import { YearSuicideGenderPie } from '../../components/Charts/DashboardCharts/SuicideCharts/YearSuicidesByGenderPie'
import { YearSuicideRacePie } from '../../components/Charts/DashboardCharts/SuicideCharts/YearSuicidesByRacePie'

export class SuicideStatistics extends React.Component {
    static displayName = SuicideStatistics.name;

    constructor(props) {
        super(props);

        this.state = {
            year: 0
        }
    }

    async componentDidMount() {
        const fetchURL = "./public_data/SuspectedDrugDeaths/WeeklyDrugDeaths.json";
        const response = await fetch(fetchURL, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
        });

        const responseData = await response.json();

        var yearString = responseData.StartDate;
        var year = yearString.substring(yearString.length - 4, yearString.length);

        await this.setState({
            year: year
        })
    }

    render() {
        return (
            <div>
                <div>
                    <br />
                    <Container>
                        <div className="text-center">
                            <strong><i>Because many deaths by suicide require investigation follow-up and toxicology testing, there will be a delay in finalizing the cause and manner of death. The number of deaths for {this.state.year} would therefore be considered incomplete at this time. Finalized numbers and statistics will be available in the annual report.</i></strong>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Nav>
                                <NavItem>
                                    <NavLink href="/SuicidesInfo">For more information about suicides, click here.</NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                        <br />

                        <Row>
                            <Col sm="12">
                                <Card body>
                                    <CardTitle className="text-center"><strong>Annual Suicides by County</strong></CardTitle>
                                    <AnnualSuicideCountyMultiBar />
                                </Card>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col sm="6">
                                <Card body>
                                    <CardTitle className="text-center"><strong>{this.state.year} Suicides by Race</strong></CardTitle>
                                    <YearSuicideRacePie />
                                </Card>
                            </Col>
                            <Col sm="6">
                                <Card body>
                                    <CardTitle className="text-center"><strong>{this.state.year} Suicides by Gender</strong></CardTitle>
                                    <YearSuicideGenderPie />
                                </Card>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col sm="6">
                                <Card body>
                                    <CardTitle className="text-center"><strong>{this.state.year} Suicides by Age</strong></CardTitle>
                                    <YearSuicideAgeBar />
                                </Card>
                            </Col>
                            <Col sm="6">
                                <Card body>
                                    <CardTitle className="text-center"><strong>Annual Suicides by Month</strong></CardTitle>
                                    <AnnualSuicideMonthMultiLine />
                                </Card>
                            </Col>
                        </Row>
                        <br />

                        
                    </Container>
                </div>
            </div>
        );
    }
};
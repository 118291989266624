import { AppBar, Box, Link, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";

export const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      <AppBar position="sticky" sx={{ backgroundColor: "#363636" }}>
        <Toolbar sx={{ pt: 4, pb: 2 }}>
          <Box display="flex" flexDirection="column" flexGrow={1} alignItems="flex-end" pr={isMobile ? 2 : 10}>
            <Typography variant="body1">New Jersey Office of the Chief State Medical Examiner</Typography>
            <Typography variant="body1">P.O. Box 182, Trenton, NJ 08625</Typography>
            <Typography variant="body1">
              <Link href="mailto:ocsme.inquiries@doh.nj.gov" target="_blank" rel="noopener" color="inherit">
                ocsme.inquiries@doh.nj.gov
              </Link>
            </Typography>
            <Typography variant="body1">
              <Link href="tel:609-376-0557" target="_blank" rel="noopener" color="inherit">
                609-376-0557
              </Link>
            </Typography>
            {/* <Typography variant="body2" sx={{ color: "rgba(255, 255, 255, 0.2)", mt: 1 }}>
              Version {REACT_APP_VERSION}
            </Typography> */}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

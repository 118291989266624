import "react-loader-spinner";
import "./custom.css";

import { Route, Routes } from "react-router-dom";
import { Layout } from "./components/Layout";

import { ContactUs } from "./pages/ContactUs";
import { Dashboard } from "./pages/Dashboard";
import { FAQ } from "./pages/FAQ";
import { NRMEO } from "./pages/FieldOffices/NRMEO";
import { SRMEO } from "./pages/FieldOffices/SRMEO";
import { StateToxLab } from "./pages/FieldOffices/StateToxLab";
import { Glossary } from "./pages/Glossary";
import { Home } from "./pages/Home";
import { MAiD } from "./pages/MAiD";
import { PublicReports } from "./pages/PublicReports";
import { UsefulLinks } from "./pages/UsefulLinks";
import { Careers } from "./pages/Careers";

import { SuicidesInfo } from "./pages/SuicidesInfo";

import { AutopsyReportRequestForm } from "./pages/WebRequestForms/AutopsyReportRequestForm";
import { CMSAccountRequest } from "./pages/WebRequestForms/CMSAccountRequest";
import { LEDTContactForm } from "./pages/WebRequestForms/LEDTContactForm";

import { Forms } from "./pages/Forms";

import { Training } from "./pages/Training";

// Test update

export function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/reports" element={<PublicReports />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/forms" element={<Forms />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/glossary" element={<Glossary />} />
        <Route path="/maid" element={<MAiD />} />
        <Route path="/usefullinks" element={<UsefulLinks />} />
        <Route path="/statetoxlab" element={<StateToxLab />} />
        <Route path="/nrmeo" element={<NRMEO />} />
        <Route path="/srmeo" element={<SRMEO />} />
        <Route path="/ledtcontactform" element={<LEDTContactForm />} />
        <Route path="/suicidesinfo" element={<SuicidesInfo />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/autopsyreportrequestform" element={<AutopsyReportRequestForm />} />
        <Route path="/cmsaccountrequest" element={<CMSAccountRequest />} />
        <Route path="/training" element={<Training />} />
      </Routes>
    </Layout>
  );
}

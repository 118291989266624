import React, { Component } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, LogarithmicScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },

        labels: {
            position: 'top',
        },
        title: {
            display: true,
        },
    },
};

var labels = [];

export var chartData = {
    labels,
    datasets: [
        {
            label: '# of Suspected Drug Deaths',
            data: [],
            backgroundColor: 'lightblue',
        },
    ],
};

export class YearDrugDeathAgeBar extends Component {
    static displayName = YearDrugDeathAgeBar.name;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
        };
    }

    async componentDidMount() {
        const fetchURL = process.env.REACT_APP_API_URL + 'GetTotalSuspectedDrugDeathsByAge';   
        const response = await fetch(fetchURL, {
            method: "GET"
        });

        const responseData = await response.json();

        var ChartLabels = [];
        var ValuesArray = [];
        var total = 0;

        const title = responseData.startDate + " through " + responseData.endDate;
        options.plugins.title.text = title;

        responseData.data.forEach(dataPoint => {
            ChartLabels.push(dataPoint.ageRange);
            ValuesArray.push(dataPoint.amount);
            total += dataPoint.amount;
        });

        chartData.labels = ChartLabels;
        chartData.datasets[0].data = ValuesArray;


        await this.setState({
            HomicideData: responseData,
            isLoading: false,
            chartData: chartData,
            total: total
        });
    }

    render() {
        //Display temporary loading prompt
        if (this.state.isLoading) {
            return (
                <div className="text-center">
                    <br />
                    Please wait, Reading Data...<hr />
                </div>
            )
        }

        return (
            <div>
                <Bar options={options} data={this.state.chartData} />
                <p className="text-center chart-total"><i>Total (YTD):</i> <b>{this.state.total}</b></p>
            </div>
        );
    }
}


import React from 'react';
import { Card, Button, CardImg, CardImgOverlay, CardTitle, CardText, CardDeck, CardSubtitle, CardBody, CardGroup } from 'reactstrap';
import { Table } from 'reactstrap';

export class CMSGeneralData extends React.Component {
    static displayName = CMSGeneralData.name;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            tableRows: [],
        };
    }

    renderTableRows() {
        return this.state.tableRows.map(function (o) {
            return <tr key={"CountyName-" + o.totalCases}>
                <th scope="row">
                    {o.totalCases}
                </th>
                <td>{o.daysPassed }</td>
                <td>{o.hoursPassed}</td>


            </tr>
        });
    }


    async componentDidMount() {

        const fetchURL = process.env.REACT_APP_API_URL + 'GetCMSStatistics';  
        const response = await fetch(fetchURL, {
            method: "GET"
        });

        const responseData = await response.json();

        //console.log(responseData)


        await this.setState({
            tableRows: responseData,
            isLoading: false,

        });

    }

    render() {

        //Display temporary loading prompt
        if (this.state.isLoading) {
            return (
                <div className="text-center">
                    <br />
                    Please wait, Reading Data...<hr />
                </div>
            )
        }



        return (


            <div className="text-center">
                <i>Statistics taken as of {this.state.tableRows[0].endDate}</i>
                <br /><br />
                CMS Cases (YTD):  <b>{this.state.tableRows[0].totalCases}</b>
                <br /><br />
                Daily Case Rate (YTD) : {(this.state.tableRows[0].totalCases / this.state.tableRows[0].daysPassed).toPrecision(4)}
                <br /><br />
                Hourly Case Rate (YTD) :  {(this.state.tableRows[0].totalCases / this.state.tableRows[0].hoursPassed).toPrecision(4)}
                <br /><br />
                {/*
                <Table striped>
                    <thead>
                        <tr>
                            <td><strong>Total Cases</strong></td>
                            <td><strong>Days Passed</strong></td>
                            <td><strong>Hours Passed</strong></td>


                        </tr>
                    </thead>
                    <tbody>
                        {/*this.renderTableRows()}
                    </tbody>
                </Table>
                */}


            

        
                
            </div>
        )
    };




}
import { Box } from "@mui/material";
import { Button, Card, CardBody, CardHeader, CardText } from "reactstrap";
import { CareersCarousal } from "../components/CareersCarousal";

export const Careers = () => {
  return (
    <Box>
      <CareersCarousal /> <br />
      <Box
        sx={{
          mx: {
            xs: 2, // For mobile
            sm: 5, // For tablet
            md: 25, // For small desktop
            lg: 40, // For large desktop
          },
        }}
      >
        <div className="careers-page">
          <div className="careers-page">
            <h6 className="text-center">
              {" "}
              <em>
                {" "}
                The Office of the Chief State Medical Examiner/NJ Dept of Health has the following employment
                opportunities:{" "}
              </em>
            </h6>
            <p className="text-center">
              <em>
                {" "}
                Interested applicants may electronically submit a cover letter, resume and completed application of
                dpf-663
                <a href="Jobs/dpf-663.pdf" target="_blank">
                  {" "}
                  here
                </a>{" "}
                to <strong>PSTSME@doh.nj.gov</strong>
              </em>
            </p>
            <hr />
            <Card>
              <CardHeader className="faded-blue text-left">
                Chief State Medical Examiner -- <b>Trenton, NJ</b>
              </CardHeader>
              <CardBody>
                <CardText>
                  The NJ Office of the Chief State Medical Examiner (OCSME) is seeking an innovative and proven leader
                  as its Chief State Medical Examiner at its Central Office located in Trenton, NJ.
                </CardText>
                <Button outline color="primary" href="/Jobs/njocsmechiefTrenton.pdf" target="_blank">
                  Learn More
                </Button>
              </CardBody>
            </Card>
            <Card>
              <CardHeader className="faded-blue text-left">
                Assistant/Associate Medical Examiner -- <b>Woodbine, NJ</b>
              </CardHeader>
              <CardBody>
                <CardText>
                  The NJ Office of the Chief State Medical Examiner is seeking two (2) Forensic Pathologists to serve as
                  Assistant/Associate Medical Examiners at its Southern Regional Medical Examiner Office (SRMEO) located
                  in Woodbine, NJ.
                </CardText>
                <Button outline color="primary" href="/Jobs/SRMEO_AME.pdf" target="_blank">
                  Learn More
                </Button>
              </CardBody>
            </Card>
          </div>
        </div>
      </Box>
    </Box>
  );
};

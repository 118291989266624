import { Component } from "react";
import NJStateLogo from "../assets/img/state_seal_white.png";

export class NJStateTopBar extends Component {
  static displayName = NJStateTopBar.name;

  render() {
    return (
      <div className="nj-header">
        <div className="nj-header-content">
          <div id="logo" className="col-sm-8 col-lg-5 col-xl-5">
            <h1 className="my-2">
              <img src={NJStateLogo} alt="State of New Jersey Seal" title="State of New Jersey Seal" />
              &nbsp; Official Site of The State of New Jersey
            </h1>
          </div>
          <div className="col-sm-8 col-lg-5 col-xl-5">
            <div className="gov-bar">
              <a href="https://nj.gov/governor" target="_blank">
                Governor Phil Murphy • Lt. Governor Tahesha Way
              </a>
            </div>
            <ul className="my-sm-2 my-lg-0 social statewide text-right">
              <li className="d-none d-lg-inline">
                <a href="https://nj.gov">NJ.gov</a>
              </li>
              <li className="d-none d-lg-inline">
                <a href="https://nj.gov/nj/gov/njgov/alphaserv.html">Services</a>
              </li>
              <li className="d-none d-lg-inline">
                <a href="https://nj.gov/nj/gov/deptserv/">Agencies</a>
              </li>
              <li className="d-none d-lg-inline">
                <a href="https://nj.gov/faqs/">FAQs</a>
              </li>
              <li className="sonj-updates">
                <a href="https://nj.gov/subscribe/" id="updates-label" role="button">
                  Get Updates&nbsp;
                  <svg
                    className="svg-inline--fa fa-envelope fa-w-16"
                    aria-hidden="true"
                    data-prefix="fas"
                    data-icon="envelope"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
                    ></path>
                  </svg>
                </a>
              </li>
              <li className="sonj-search">
                <a href="https://nj.gov/search/" id="search-label" role="button">
                  Search&nbsp;
                  <svg
                    className="svg-inline--fa fa-search fa-w-16"
                    aria-hidden="true"
                    data-prefix="fas"
                    data-icon="search"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                    ></path>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

import React from 'react';
import { CMSGeneralData } from '../../components/Charts/DashboardCharts/GeneralCharts/CMSGeneralData';
import { AnnualCaseNumbersLine } from '../../components/Charts/DashboardCharts/GeneralCharts/AnnualCaseNumbersLine';
import { CMSCasesReportedByCounty2022Table } from '../../components/Charts/DashboardCharts/GeneralCharts/2022-CasesReportedByCountyTable';
import { Card, Button, CardImg, CardImgOverlay, CardTitle, CardText, CardDeck, CardSubtitle, CardBody, CardGroup } from 'reactstrap';
import { Table } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';
import { Tooltip } from 'reactstrap';
import { AiFillQuestionCircle } from "react-icons/ai";

export class GeneralCMStatistics extends React.Component {
    static displayName = GeneralCMStatistics.name;

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            tooltipOpen: false
        };
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }

    render() {
        return (
            <div>
                
                <div>
                    <br />
                    
                    <Container>
                        <Row>
                            <Col sm="6">
                                <Card body>
                                    <CardTitle className="text-center"><strong>CMS General Data</strong></CardTitle>
                                    <CMSGeneralData />
                                </Card>
                            </Col>
                            <Col sm="6">
                                <Card body>
                                    <CardTitle className="text-center"><strong>Annual Case Numbers</strong></CardTitle>
                                    <AnnualCaseNumbersLine />
                                </Card>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col sm="12">
                            <Card body>
                                <CardTitle className="text-center"><strong>2023 Cases Reported by County</strong>

                                    <span style={{ color: "black" }} href="#" id="casesreported"> <AiFillQuestionCircle /></span>
                                    <Tooltip placement="top" isOpen={this.state.tooltipOpen} target="casesreported" toggle={this.toggle}>
                                        When a case is reported to the Medical Examiners Office, a Medicolegal death investigator will gather information
                                        regarding the circumstances of a death in order to determine whether a case falls under the jurisdiction
                                        of the Medical Examiners Office. Depending on the information received, a case can be Accepted,
                                        transferred to another jurisdiction, or released.
                                    </Tooltip>
                                <br /><br />
                                </CardTitle>
                                <CMSCasesReportedByCounty2022Table />
                               
                                </Card>
                                </Col>
                        </Row>
                       
                        </Container>
                        
                    
                </div>
            </div>
        );
    }
};
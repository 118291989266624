import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },

    labels: {
      position: "top",
    },
    title: {
      display: false,
      text: "NJ Confirmed Drug Related Deaths in 2015",
    },
  },
};

const labels = [
  "Heroin",
  "Morphine",
  "Cocaine",
  "Fentanyl",
  "Oxycodone",
  "Methadone",
];

const data = {
  labels,
  datasets: [
    {
      label: "Atlantic",
      data: [54, 2, 23, 23, 15, 6],
      backgroundColor: "red",
    },
    {
      label: "Bergen",
      data: [51, 2, 19, 24, 17, 4],
      backgroundColor: "pink",
    },
    {
      label: "Burlington",
      data: [41, 2, 22, 38, 16, 5],
      backgroundColor: "blue",
    },
    {
      label: "Camden",
      data: [115, 5, 66, 53, 41, 11],
      backgroundColor: "green",
    },
    {
      label: "Cape May",
      data: [18, 1, 3, 10, 4, 2],
      backgroundColor: "purple",
    },
    {
      label: "Cumberland",
      data: [16, 4, 12, 10, 8, 2],
      backgroundColor: "aqua",
    },
    {
      label: "Essex",
      data: [91, 2, 55, 29, 14, 9],
      backgroundColor: "teal",
    },
    {
      label: "Gloucester",
      data: [37, 3, 22, 17, 17, 6],
      backgroundColor: "lime",
    },
    {
      label: "Hudson",
      data: [64, 2, 36, 18, 18, 9],
      backgroundColor: "yellow",
    },
    {
      label: "Hunterdon",
      data: [8, 1, 3, 2, 1, 0],
      backgroundColor: "orange",
    },
    {
      label: "Mercer",
      data: [39, 0, 16, 15, 13, 8],
      backgroundColor: "black",
    },
    {
      label: "Middlesex",
      data: [60, 3, 18, 30, 21, 5],
      backgroundColor: "bluegray500",
    },
    {
      label: "Monmouth",
      data: [68, 0, 25, 29, 30, 10],
      backgroundColor: "lightgreen",
    },
    {
      label: "Morris",
      data: [33, 1, 10, 17, 8, 2],
      backgroundColor: "darkgoldenrod",
    },
    {
      label: "Ocean",
      data: [74, 7, 25, 51, 36, 10],
      backgroundColor: "deeppink",
    },
    {
      label: "Passaic",
      data: [55, 0, 31, 16, 12, 3],
      backgroundColor: "beige",
    },
    {
      label: "Salem",
      data: [11, 0, 5, 3, 4, 2],
      backgroundColor: "mediumturquoise",
    },
    {
      label: "Somerset",
      data: [18, 4, 2, 5, 8, 0],
      backgroundColor: "silver",
    },
    {
      label: "Sussex",
      data: [12, 1, 1, 5, 2, 3],
      backgroundColor: "slateblue",
    },
    {
      label: "Union",
      data: [44, 3, 16, 13, 13, 6],
      backgroundColor: "thistle",
    },
    {
      label: "Warren",
      data: [9, 0, 4, 9, 4, 0],
      backgroundColor: "lavender",
    },
  ],
};

function ConfirmedDrugs2015BarChart() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <div>
      {/* Display temporary loading prompt while isLoading is true */}
      {isLoading ? (
        <div>
          <br />
          <p>Please wait, Reading Data...</p>
          <hr />
        </div>
      ) : (
        <Bar options={options} data={data} />
      )}
    </div>
  );
}

export default ConfirmedDrugs2015BarChart;

import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {
  Card,
  Button,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  CardDeck,
  CardSubtitle,
  CardBody,
  CardGroup,
} from "reactstrap";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },

    labels: {
      position: "top",
    },
    title: {
      display: false,
      text: "NJ Confirmed Drugs 2019",
    },
  },
};

const labels = [
  "Heroin",
  "Fentanyl",
  "Fentanyl Analog",
  "Morphine",
  "Oxycodone",
  "Methadone",
  "Cocaine",
  "Methamphetimine",
  "Benzopdiazepine",
  "Ethanol",
];

const data = {
  labels,
  datasets: [
    {
      label: "Atlantic",
      data: [48, 152, 17, 4, 9, 14, 64, 19, 12, 50],
      backgroundColor: "red",
    },
    {
      label: "Bergen",
      data: [44, 122, 11, 8, 14, 4, 48, 9, 29, 37],
      backgroundColor: "pink",
    },
    {
      label: "Burlington",
      data: [14, 109, 5, 5, 14, 4, 29, 19, 7, 16],
      backgroundColor: "blue",
    },
    {
      label: "Camden",
      data: [59, 250, 33, 0, 24, 11, 130, 27, 33, 52],
      backgroundColor: "green",
    },
    {
      label: "Cape May",
      data: [8, 38, 5, 0, 0, 4, 7, 10, 5, 9],
      backgroundColor: "purple",
    },
    {
      label: "Cumberland",
      data: [7, 55, 4, 0, 2, 3, 30, 10, 5, 14],
      backgroundColor: "aqua",
    },
    {
      label: "Essex",
      data: [106, 297, 21, 0, 14, 13, 146, 7, 23, 85],
      backgroundColor: "teal",
    },
    {
      label: "Gloucester",
      data: [22, 114, 11, 0, 13, 9, 44, 25, 26, 20],
      backgroundColor: "lime",
    },
    {
      label: "Hudson",
      data: [57, 133, 11, 0, 6, 8, 51, 6, 11, 42],
      backgroundColor: "yellow",
    },
    {
      label: "Hunterdon",
      data: [4, 8, 0, 0, 2, 1, 1, 1, 4, 2],
      backgroundColor: "orange",
    },
    {
      label: "Mercer",
      data: [24, 97, 17, 2, 8, 6, 44, 6, 17, 27],
      backgroundColor: "black",
    },
    {
      label: "Middlesex",
      data: [68, 173, 32, 4, 11, 8, 53, 8, 42, 42],
      backgroundColor: "bluegray500",
    },
    {
      label: "Monmouth",
      data: [72, 140, 16, 2, 18, 11, 55, 3, 52, 32],
      backgroundColor: "lightgreen",
    },
    {
      label: "Morris",
      data: [41, 77, 12, 0, 9, 6, 26, 3, 38, 26],
      backgroundColor: "darkgoldenrod",
    },
    {
      label: "Ocean",
      data: [82, 158, 16, 5, 26, 10, 52, 12, 36, 21],
      backgroundColor: "deeppink",
    },
    {
      label: "Passaic",
      data: [56, 146, 14, 0, 6, 10, 76, 0, 12, 38],
      backgroundColor: "beige",
    },
    {
      label: "Salem",
      data: [3, 34, 1, 0, 0, 2, 14, 7, 3, 6],
      backgroundColor: "mediumturquoise",
    },
    {
      label: "Somerset",
      data: [20, 48, 6, 0, 5, 4, 15, 1, 4, 14],
      backgroundColor: "silver",
    },
    {
      label: "Sussex",
      data: [19, 37, 4, 0, 2, 1, 16, 1, 13, 11],
      backgroundColor: "slateblue",
    },
    {
      label: "Union",
      data: [52, 116, 24, 1, 11, 14, 55, 2, 27, 46],
      backgroundColor: "thistle",
    },
    {
      label: "Warren",
      data: [16, 32, 4, 0, 4, 3, 8, 6, 8, 6],
      backgroundColor: "lavender",
    },
  ],
};

<hr />;

function ConfirmedDrugs2020BarChart() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return (
      <div>
        <br />
        <p>Please wait, Reading Data...</p>
        <hr />
      </div>
    );
  }

  return (
    <div>
      {/* Display Chart */}
      <Bar options={options} data={data} />
    </div>
  );
}

export default ConfirmedDrugs2020BarChart;
